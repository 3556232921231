import React, { useEffect, useRef } from "react";
import {
  CustomAmountInput,
  CustomCalander,
  // CustomCheckbox,
  CustomDropDown,
  CustomForm,
  CustomInput,
  // CustomNumberInput2,
  CustomRadioInput,
} from "../../shared/AllInputs";
// import { Messages } from "primereact/messages";
import AddExtraCost from "../../shared/Components/AddExtraCost";
import { IntervalType, RentType } from "../../shared/AllDropDownOptions";
import { Toast } from "primereact/toast";

const RentAndCost = ({
  title,
  // type,
  data,
  handleChange,
  msgToShow,
  allExtraCost,
  setAllExtraCost,
  // costErrors,
  // setCostErrors,
}) => {
  const msgs = useRef(null);

  useEffect(() => {
    if (msgToShow?.message) { 
        msgs?.current?.show({
        severity: msgToShow?.type,
        detail: msgToShow?.message,
      });
    }
  }, [msgToShow]);

  return (
    <>
      <CustomForm title={title || "Contractual Rent"}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <CustomAmountInput
          data={data}
          onChange={handleChange}
          name="amount"
          required
        />
        <CustomAmountInput
          data={data}
          onChange={handleChange}
          name="amountFirstMonth"
          required
        />
      </CustomForm>
      <CustomForm title="Automatic Rent Increment">
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="rentRiseType"
          options={RentType}
          required
        />
      </CustomForm>
      {data?.rentRiseType === "None" || data?.rentRiseType == "" ? null : (
        <CustomForm>
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="intervalType"
            options={IntervalType}
            required
            label={"Rent Rise Interval Type"}
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="riseInterval"
            label={"Rent Rise Interval"}
          />
          <CustomCalander
            minDate={new Date()}
            data={data}
            onChange={handleChange}
            name="startDate"
            label="Date of First Rise"
          />

          <CustomInput
            data={data}
            onChange={handleChange}
            name="riseAmount"
            label="Rent Rise Amount"
          />
          <CustomCalander
            minDate={new Date()}
            data={data}
            onChange={handleChange}
            name="endDate"
            label="Date of Last Rise"
          />
        </CustomForm>
      )}
      <AddExtraCost
        // type={type}
        allExtraCost={allExtraCost}
        setAllExtraCost={setAllExtraCost}
        // errors={costErrors}
        // setErrors={setCostErrors}
      />
      {data?.propertyRent && <CustomForm title={"Property Rent"}>
        <CustomRadioInput
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Size"
          label={<div className="inline-block">By Size</div>}
          onChange={handleChange}
          checked={data?.propertyRent === "Size"}
        />
        <CustomRadioInput
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Room"
          label={<div className="inline-block">By rooms</div>}
          onChange={handleChange}
          checked={data?.propertyRent === "Room"}
        />
        <CustomRadioInput
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Manually"
          label={<div className="inline-block">Manually Enter</div>}
          onChange={handleChange}
          checked={data?.propertyRent === "Manually"}
        />
      </CustomForm>}
      {/* <CustomInput
        col={6}
        data={data}
        onChange={handleChange}
        name="calculatedRent"
        required
      /> */}
    </>
  );
};

export default React.memo(RentAndCost);
