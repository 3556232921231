import React from "react";
import {
  CustomAutoComplete,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  CustomPhoneInput,
} from "../../shared/AllInputs";
import GooglePlacesInput from "../../shared/GooglePlacesInput";

const OfficeInfofmation = ({
  handleChange,
  data,
  handleOfficeIdChange,
  officeIdOptions,
  setAddress,
  debouncedOnChange,
}) => {
  const officePurposeOptions = [
    { name: "Social Worker", value: "social-worker" },
    { name: "Property Management", value: "property-management" },
  ];

  return (
    <>
      <CustomForm title="Enter Office Details">
        <CustomAutoComplete
          data={data}
          onChange={handleOfficeIdChange}
          name="officeId"
          suggestions={officeIdOptions}
          required
          editable
          label={"Office ID"}
          placeholder="Office ID"
          search={(e) => debouncedOnChange.current(e.query)}
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="officeName"
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="purpose"
          label={"Office Purpose"}
          options={officePurposeOptions}
          required
        />
        <GooglePlacesInput
          id="office_address"
          data={data}
          onChange={handleChange}
          name="address"
          required
          setAddress={setAddress}
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="street"
          required
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="zipCode"
          required
        />
        <CustomInput data={data} onChange={handleChange} name="city" required />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="cityDivision"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="country"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="email"
          required
        />
        <CustomPhoneInput
          data={data}
          onChange={handleChange}
          name="phoneNumber"
          required
        />
      </CustomForm>
    </>
  );
};

export default OfficeInfofmation;
