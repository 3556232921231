import React, { useRef, useState } from "react";
import { CustomDropDown, CustomForm, CustomInput } from "../AllInputs";
import CustomButton from "../CustomButton";
import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
import { BankPurposeOpt } from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import { useDispatch } from "react-redux";
import CustomModal from "../Modals/CustomModal";
// import { deleteEnergyMeterAction } from "../../store/actions/buildingUnitActions";
import { Toast } from "primereact/toast";
import {
  deleteBankAccountAction,
  getBankAccountInfoAction,
} from "../../store/actions/landlordActions";
// import { checkIban } from "../../services/api";
// import { debounce } from "lodash";
// import { KEYS } from "../../utils/keys";

const AddBankAccounts = ({
  // type,
  title,
  allBankAccounts,
  setAllBankAccounts,
  // errors,
  // setErrors,
}) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [isSearch, setIsSearch] = useState(false);
  const InputObj = {
    purpose: "All Combined",
    owner_name: "",
    iban: "",
    bic: "",
    status: "",
  };

  // const errorObj = {
  //   purpose: null,
  //   owner_name: null,
  //   iban: null,
  //   bic: null,
  // };

  const ignoreInputs = ["status", "id"];

  // useEffect(() => {
  //   if (allBankAccounts?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allBankAccounts?.map((bank, bankIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === bankIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allBankAccounts]);

  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() =>
          HandleAddNew(InputObj, allBankAccounts, setAllBankAccounts)
        }
      />
    </div>
  );

  // const handleIban = async (name, value, index, allAccounts) => {
  //   if (value) {
  //     const updatedArray = [...allAccounts];
  //     const payload = {
  //       api_key: KEYS.iBan,
  //       iban: value,
  //     };

  //     const res = await checkIban(payload);
  //     let error = "";

  //     if (res?.data?.is_valid) {
  //       error = "";
  //     } else if (!res?.data?.is_valid) {
  //       error = "Please enter a valid Iban!";
  //     }

  //     if (index > -1 && index < updatedArray.length) {
  //       const updatedAccount = {
  //         ...updatedArray[index],
  //         [name]: value,
  //         formErrors: { ...updatedArray[index].formErrors, [name]: error },
  //       };
  //       updatedArray[index] = updatedAccount;
  //     }
  //     setAllBankAccounts(updatedArray);
  //     setIsSearch(false);
  //   }
  // };

  // const ibanChange = useRef(debounce(handleIban, 1000));

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected bank acount from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteBankAcc = () => {
    if (idToDelete?.id) {
      setLoading(true);
      dispatch(
        deleteBankAccountAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(
            allBankAccounts,
            setAllBankAccounts,
            idToDelete?.index
          );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allBankAccounts, setAllBankAccounts, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getAccountInfo = (id, index) => {
    dispatch(
      getBankAccountInfoAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allBankAccounts, setAllBankAccounts);
        }
      })
    );
  };

  return (
    <CustomForm title={title} header={header}>
      <Toast ref={msgs} />
      <table className="financial-table w-full mt-3">
        <thead>
          <tr>
            <th>Purpose</th>
            <th>Owner Name</th>
            <th>IBAN</th>
            <th>BIC</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="financial-table-form">
          {allBankAccounts?.map((bankAcc, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <CustomDropDown
                      // value={bankAcc?.purpose}
                      data={bankAcc}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allBankAccounts,
                          setAllBankAccounts,
                          ignoreInputs
                        );
                        // handleObjChange(
                        //   allBankAccounts,
                        //   setAllBankAccounts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                      name="purpose"
                      col={12}
                      extraClassName="w-full"
                      options={BankPurposeOpt}
                      disabled={bankAcc?.status === "save"}
                      ignoreLabel
                    />
                  </td>
                  <td>
                    <CustomInput
                      ignoreLabel
                      data={bankAcc}
                      disabled={bankAcc?.status === "save"}
                      type="text"
                      name="owner_name"
                      extraClassName="w-full"
                      // value={bankAcc?.owner_name}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allBankAccounts,
                          setAllBankAccounts,
                          ignoreInputs
                        );
                        // handleObjChange(
                        //   allBankAccounts,
                        //   setAllBankAccounts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                    />
                  </td>
                  <td>
                    <CustomInput
                      ignoreLabel
                      disabled={bankAcc?.status === "save"}
                      type="text"
                      name="iban"
                      extraClassName="w-full"
                      // value={bankAcc?.iban}
                      data={bankAcc}
                      onChange={(e) => {
                        {
                          const value = e?.target?.value;
                          handleChangeNew(
                            e?.target?.name,
                            value,
                            index,
                            allBankAccounts,
                            setAllBankAccounts,
                            ignoreInputs
                          );
                          // ibanChange.current(
                          //   e?.target?.name,
                          //   value,
                          //   index,
                          //   allBankAccounts
                          // );
                          // value? setIsSearch(true) : setIsSearch(false)
                        }
                      }}
                      isSearch={isSearch}
                    />
                  </td>
                  <td>
                    <CustomInput
                      ignoreLabel
                      disabled={bankAcc?.status === "save"}
                      type="text"
                      name="bic"
                      extraClassName="w-full"
                      // value={bankAcc?.bic}
                      data={bankAcc}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allBankAccounts,
                          setAllBankAccounts,
                          ignoreInputs
                        );
                        // handleObjChange(
                        //   allBankAccounts,
                        //   setAllBankAccounts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                    />
                  </td>
                  {bankAcc?.status === "save" ? (
                    <td>
                      <CustomOverlay ref={openAction}>
                        <ul className="no-style p-0">
                          {/* {type == "Edit" && ( */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() =>
                              handlEdit(
                                allBankAccounts,
                                setAllBankAccounts,
                                index
                              )
                            }
                          >
                            <i className="pi pi-file-edit text-dark"></i>
                            Edit
                          </li>
                          {/* )} */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() => {
                              setIdToDelete({ id: bankAcc?.id, index: index });
                              setDeleteModal(true);
                            }}
                          >
                            <i className="pi pi-trash cursor-pointer text-dark"></i>
                            Delete
                          </li>
                        </ul>
                      </CustomOverlay>
                    </td>
                  ) : (
                    <td>
                      <div className="d-flex gap-3">
                        <a
                          className="cursor-pointer"
                          onClick={() =>
                          //  !isSearch && 
                           handleSaveNew(index, allBankAccounts, setAllBankAccounts, ignoreInputs)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                              fill="#787878"
                            ></path>
                          </svg>
                        </a>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            if (bankAcc.id) {
                              getAccountInfo(bankAcc.id, index);
                            } else {
                              handleDeleteNew(
                                allBankAccounts,
                                setAllBankAccounts,
                                index
                              );
                              // handleDelete(
                              //   allBankAccounts,
                              //   setAllBankAccounts,
                              //   errors,
                              //   setErrors,
                              //   index
                              // )
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#787878"
                              d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </td>
                  )}
                </tr>
                <CustomModal
                  isOpen={deleteModal}
                  setIsOpen={setDeleteModal}
                  heading={"Delete"}
                  body={modalContent}
                  submitLabel={"Confirm"}
                  onSubmit={handleDeleteBankAcc}
                  loading={loading}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
    </CustomForm>
  );
};

export default AddBankAccounts;
