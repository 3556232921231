import api from "../../services/api";
import endPoints from "../../services/endPoints";

const addOfficeUnitAction = (company, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.ADD_OFFICE_UNIT + `${company}/`,
        data
    );
    if (res?.success) {
        navigate("/administrator/office-unit/list")
    } else {
        onError(res?.message);
    }
    loading(false)
};

const getOfficeUnitListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/`,
    );
    onRes(res);
};

const officeUnitListAction = (company, rows, page, search, ordering, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&ordering=${ordering}`,
    );
    onRes(res);
};

const getOfficeUnitInfoAction = (company, id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_UNIT_LIST + `${company}/${id}`,
    );
    onRes(res);
};

const updateOfficeUnitAction = (company, id, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE_UNIT + `${company}/${id}/`,
        data
    );
    if (res?.success) {
        navigate(`/administrator/office-unit/view/${id}`)
    } else {
        onError(res?.message);
    }
    loading(false)
};

const deleteOfficeUnitAction = (company, id, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE_UNIT + `${company}/${id}/`,
        data
    );
    if (res?.success) {
        navigate(`/administrator/office-unit/view/${id}`)
    } else {
        onError(res?.message);
    }
    loading(false)
};

const unitDeleteAction = (company, unitId, loading, deleteModal, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_UNIT_LIST + `${company}/${unitId}`,
    );
    onRes(res);
    loading(false)
    deleteModal(false)
};

export {
    addOfficeUnitAction,
    getOfficeUnitListAction,
    getOfficeUnitInfoAction,
    updateOfficeUnitAction,
    deleteOfficeUnitAction,
    unitDeleteAction,
    officeUnitListAction,
};
