import React from "react";
import { CustomForm, CustomRatings } from "../../../../../shared/AllInputs";
import {
  CustomImageView,
  CustomViewInput,
} from "../../../../../shared/AllViewInputs";

const DamageFeedbackView = ({ data }) => {
  return (
    <>
      <CustomForm title="Comments">
        <CustomViewInput
          data={data?.damageFeedback}
          name="comment"
          ignoreLabel
        />
      </CustomForm>
      {data?.ratings && (
        <CustomForm title="Handyman Review">
          <CustomRatings name="ratings" data={data} ignoreLabel disabled />
        </CustomForm>
      )}
      <div className="title gap-3">
        Fixed Images
        <div className="flex gap-4 flex-wrap mt-3">
          {data?.fixedImages?.map((image, index) => {
            return <CustomImageView image={image?.image} />;
          })}
        </div>
      </div>
    </>
  );
};

export default DamageFeedbackView;
