const AllRoles = {
    "Real Estate": {
        "Properties": [
            { name: "View Employee", value: "view_employee", check: false },
            { name: "Add Employee", value: "add_employee", check: false },
            { name: "Edit Employee", value: "change_employee", check: false },
            { name: "Delete Employee", value: "delete_employee", check: false },
            { name: "View Contract", value: "view_contract", check: false },
            { name: "Add Contract", value: "add_contract", check: false },
            { name: "Edit Contract", value: "change_contract", check: false },
            { name: "Delete Contract", value: "delete_contract", check: false },
        ],
        "Landlord": [
            { name: "View Landlord", value: "view_landlord", check: false },
            { name: "Add Landlord", value: "add_landlord", check: false },
            { name: "Edit Landlord", value: "change_landlord", check: false },
            { name: "Delete Landlord", value: "delete_landlord", check: false },
        ],
        "Maintenance": [
            { name: "View Handyman", value: "view_handyman", check: false },
            { name: "Add Handyman", value: "add_handyman", check: false },
            { name: "Edit Handyman", value: "change_handyman", check: false },
            { name: "Delete Handyman", value: "delete_handyman", check: false },
            { name: "View Damage", value: "view_damage", check: false },
            { name: "Add Damage", value: "add_damage", check: false },
            { name: "Edit Damage", value: "change_damage", check: false },
            { name: "Delete Damage", value: "delete_damage", check: false },
            { name: "Process Damage", value: "delete_process", check: false },
            { name: "View Maintenance Request", value: "view_maintenance_request", check: false },
            { name: "Delete Maintenance Request", value: "delete_maintenance_request", check: false },
            { name: "Process Maintenance Request", value: "process_maintenance_request", check: false },
        ],
    },
    "Tenant": {
        "Tenant": [
            { name: "View Tenant", value: "view_tenant", check: false },
            { name: "Add Tenant", value: "add_tenant", check: false },
            { name: "Edit Tenant", value: "change_tenant", check: false },
            { name: "Delete Tenant", value: "delete_tenant", check: false },
            { name: "Assign other office", value: "assign_other_office", check: false },
        ],
    },
    "Administrator": {
        "Office Unit": [
            { name: "View Unit", value: "view_officeunit", check: false },
            { name: "Add Unit", value: "add_officeunit", check: false },
            { name: "Edit Unit", value: "change_officeunit", check: false },
            { name: "Delete Unit", value: "delete_officeunit", check: false },
        ],
        "Office": [
            { name: "View Office", value: "view_office", check: false },
            { name: "Add Office", value: "add_office", check: false },
            { name: "Edit Office", value: "change_office", check: false },
            { name: "Delete Office", value: "delete_office", check: false },
        ],
        "Employee": [
            { name: "View Employee", value: "view_employee", check: false },
            { name: "Add Employee", value: "add_employee", check: false },
            { name: "Edit Employee", value: "change_employee", check: false },
            { name: "Delete Employee", value: "delete_employee", check: false },
        ],
        "Role": [
            { name: "View Role", value: "view_companyrole", check: false },
            { name: "Add Role", value: "add_companyrole", check: false },
            { name: "Edit Role", value: "change_companyrole", check: false },
            { name: "Delete Role", value: "delete_companyrole", check: false },
        ],
    },

    "Settings": {
        "Settings": [
            { name: "Access Internal Rent Settings", value: "access_internal_rent_settings", check: false },
            { name: "Access External Rent settings", value: "access_external_rent_settings", check: false },
            { name: "Access Plans", value: "access_plans", check: false },
        ],
    }
}

export default AllRoles;