import React, { useRef } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../../../../shared/AllInputs";
import EmployeeContainer from "./EmployeeContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../shared/CustomButton";
import AddOffices from "../../../../shared/Components/AddOffices";

export default function EmployeeForm({ type }) {
  const msgs = useRef(null);
  const {
    data,
    handleChange,
    rolesList,
    allOffices,
    setAllOffices,
    onSubmit,
    loading,
    employeeIdOptions,
    handleEmployeeIdChange,
  } = EmployeeContainer();

  return (
    <Page
      title={`${type} Employee`}
      description={`Let’s ${type} your Employee Details`}
      navigateLink={"/administrator/employees/list"}
    >
      <CustomCard>
        <CustomForm title="Employee Details">
          <CustomDropDown
            data={data}
            onChange={handleEmployeeIdChange}
            name="employeeId"
            options={employeeIdOptions}
            label={"Employee ID"}
            placeholder={"Select Employee ID"}
            required
            editable
          />
          {/* <CustomInput
            data={data}
            onChange={handleChange}
            name="employeeId"
            required
          /> */}
          <CustomInput
            data={data}
            onChange={handleChange}
            name="firstName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="lastName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="email"
            required
          />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
            required
          />
        </CustomForm>
        <br />
        <CustomForm title="Role">
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="selectRole"
            placeholder="Select Role"
            options={rolesList}
            required
          />
        </CustomForm>
        <br />
        <AddOffices allOffices={allOffices} setAllOffices={setAllOffices} />
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined label="Cancel" />
      </Buttonlayout>
    </Page>
  );
}
