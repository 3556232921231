export const handleSetPropertyData = (data, setData) => {
    let propertyData = ""
    if (data) {
        propertyData = {
            propertyId: data?.property_id,
            address: data?.address,
            zipCode: data?.zipcode,
            city: data?.city,
            houseNo: data?.house_number,
            street: data?.street,
            cityDivision: data?.city_division,
            country: data?.country,
        }
    } else {
        propertyData = {
            propertyId: "",
            address: "",
            zipCode: "",
            city: "",
            houseNo: "",
            street: "",
            cityDivision: "",
            country: "",
        }
    }

    setData(propertyData)
}

export const handleSetBuildingSturctureData = (building, data, setData, setStructureDocuments) => {
    let buildingData = ""
    if (data) {
        buildingData = {
            building: data?.id,
            buildingName: data?.name,
            buildingType: data?.type,
            buildingMaxFloor: data?.max_floor,
            staircaseAccess: data?.staircase,
            size: data?.size,
            longitude: data?.longitude,
            latitude: data?.latitude,
            address: data?.address
        }
    } else {
        buildingData = {
            building: building || "",
            buildingType: "",
            buildingMaxFloor: "",
            staircaseAccess: "",
            size: "",
            longitude: "",
            latitude: "",
            address: ""
        }
    }
    if (data?.building_document?.length) {
        let documents = data?.building_document?.map((file) => {
            return { document: file?.document, id: file?.id }
        })
        buildingData.document = documents
        setStructureDocuments(data?.building_document)
    } else {
        buildingData.document = []
    }
    setData(buildingData)
}

export const handleSetLandlordData = (landlord, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture) => {
    setLandLordData({
        id: landlord?.id,
        existingLandlord: landlord?.landlord_id,
        landlordName: landlord?.landlord_name,
        landlordType: landlord?.type,
        address: landlord?.address,
        street: landlord?.street,
        zipCode: landlord?.zip_code,
        city: landlord?.city,
        cityDivision: landlord?.city_division,
        country: landlord?.country,
        email: landlord?.email,
        phoneNumber: landlord?.phone_number,
        directDebit: landlord?.direct_debit ? "yes" : "no",
        furnitureProvided: landlord?.is_furniture ? "yes" : "no",
        landlord_furniture: landlord?.landlord_furniture || []
    })
    if (landlord?.bank_accounts?.length) {
        let bankAccounts = landlord?.bank_accounts?.map((bank) => {
            return { ...bank, status: "save" }
        })
        setAllBankAccounts(bankAccounts)
    }else{
        setAllBankAccounts([])

    }

    if (landlord?.contacts?.length) {
        let contacts = landlord?.contacts?.map((contact) => {
            return { ...contact, status: "save" }
        })
        setAllContacts(contacts)
    }else{
        setAllContacts([])
    }

    if (landlord?.landlord_furniture?.length) {
        let furniture = landlord?.landlord_furniture?.map((item) => {
            return { ...item, status: "save" }
        })
        setAllFurniture(furniture)
    }else {
        setAllFurniture([])
    }
}

export const handleSetContractData = (contract, setContractualData, setAllMaturities) => {
    let data = {
        id: contract?.id,
        existingContract: contract?.contract_id,
        movingDate: contract?.moving_in_date ? new Date(contract?.moving_in_date) : "",
        estateCompany: contract?.estate_company,
        contractualSize: contract?.size,
        contractualNo: contract?.room,
        maturityType: contract?.maturity_type,
        maturityDate: contract?.maturity_date ? new Date(contract?.maturity_date) : "",
        maturityExtension: contract?.maturity_extension,
        noticePeriod: contract?.maturity_notice_period,
        depositType: contract?.deposit_type,
        amount: contract?.deposit_amount
    }

    setContractualData(data)
    if (contract?.optional_maturities?.length) {
        let maturities = contract?.optional_maturities?.map((maturity) => {
            return { ...maturity, status: "save" }
        })
        setAllMaturities(maturities)
    }else {
        setAllMaturities([])
    }
}

export const handleSetBuildingOptions = (newOption, buildingOptions, setBuildingOptions) => {
    let options = [...buildingOptions]
    const index = options.findIndex(item => item.id === newOption.id);
    if (index !== -1) {
        options[index] = { ...options[index], ...newOption };
    } else {
        options.push({
            ...newOption,
            name: newOption?.name,
            value: newOption?.id
        });
    }
    setBuildingOptions(options)
}

export const handleSetBuildingUnitData = (data, setBuildingUnitdata, setAllRooms, setAllEnergyMeters) => {
    let unit = {
        id: data?.id || "",
        // name: data?.name,
        unitType: data?.unit_type,
        location: data?.location,
        floor: data?.floor,
        waterHeating: data?.water_heating,
        size: data?.unit_size,
        heatingTechnology: data?.heating_technology,
        rooms: data?.rooms,
        energy_meters: data?.energy_meters,
    };

    setBuildingUnitdata(unit);
    if(data?.rooms){
        let options = data?.rooms?.map((item, index) => {
            return {
                srNo: index + 1,
                id: item?.id,
                doors: item?.doors,
                size: item?.size,
                name: item?.name,
                status: "save",
            };
        });
        setAllRooms(options);
    }else{
        setAllRooms([]);
    }

    if(data?.energy_meters){
        let energyOptions = data?.energy_meters?.map((item, index) => {
            return {
                ...item,
                status: "save",
            };
        });
        setAllEnergyMeters(energyOptions);
    }else{
        setAllEnergyMeters([]);
    }
}

export const handleSetRentData = (data, setData, setAllExtraCost) => {
    let rentandcost = {
        amount: data?.rent_amount,
        amountFirstMonth: data?.rent_amount_first_month,
        rentRiseType: data?.rent_rise_type,
        intervalType: data?.rent_rise_interval_type,
        riseInterval: data?.rent_rise_interval,
        startDate: data?.date_of_first_rise ? new Date(data?.date_of_first_rise) : "",
        endDate: data?.date_of_last_rise ? new Date(data?.date_of_last_rise) : "",
        riseAmount: data?.rent_rise_amount,
        propertyRent: data?.property_rent,
    };

    if (data?.landlord) {
        rentandcost.landlord = data?.landlord
    }

    setData(rentandcost)
    if (data?.extra_costs?.length) {
        let extraCost = data?.extra_costs?.map((cost) => {
            if (cost?.applicable_tenant_rent === true) {
                return { ...cost, applicable_tenant_rent: "yes", status: "save" };
            } else if (cost?.applicable_tenant_rent === false) {
                return { ...cost, applicable_tenant_rent: "no", status: "save" };
            }
        });
        setAllExtraCost(extraCost);
    }else{
        setAllExtraCost([])
    }
}