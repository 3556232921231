import React, { useRef } from "react";
import {
  CustomAutoComplete,
  CustomCalander,
  // CustomDropDown,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  CustomSizeM2,
} from "../../shared/AllInputs";
import Maturity from "../../shared/Components/Maturity";
import Deposits from "../../shared/Components/Deposits";
import AddOptionalMaturity from "../../shared/Components/AddOptionalMaturity";
// import { ContractualRoomOpt } from "../../shared/AllDropDownOptions";
import { Toast } from "primereact/toast";

const ContractualInfoForm = ({
  ContractOptions,
  data,
  handleChange,
  allMaturities,
  setAllMaturities,
  // maturityErrors,
  // setMaturityErrors,
  contractIdChange,
  isContactExist,
}) => {
  const msgs = useRef(null);
  return (
    <>
      <CustomForm title="Contractual Info">
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        {/* <CustomDropDown
          data={data}
          onChange={handleChange}
          name="existingContract"
          options={ContractOptions}
          label={"Search Existing Contract ID"}
          placeholder={"Search Contract ID"}
          required
          editable
          disabled={isDisabled}
        /> */}
        <CustomAutoComplete
          field={"contract_id"}
          data={data}
          onChange={handleChange}
          name="existingContract"
          suggestions={ContractOptions}
          required
          editable
          label={"Search Existing Contract ID"}
          placeholder={"Search Contract ID"}
          search={(e) => contractIdChange.current(e.query)}
          errorMessage={
            isContactExist === false
              ? "contract does not exist please enter the details to create a new contract."
              : null
          }
          max={9}
        />

        <CustomCalander
          // minDate={new Date()}
          data={data}
          onChange={handleChange}
          name="movingDate"
          label="Moving In Date"
        />

        <CustomInput data={data} onChange={handleChange} name="estateCompany" />
        <CustomSizeM2
          data={data}
          onChange={handleChange}
          name="contractualSize"
          max={9}
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="contractualNo"
          label="Contractual No. Of Room"
          placeholder={"Contractual No. Of Room"}
          max={9}
        />
        {/* <CustomDropDown
          data={data}
          onChange={handleChange}
          name="contractualNo"
          options={ContractualRoomOpt}
          label="Contractual No. Of Room"
          placeholder={"Select Contractual No. Of Room"}
          required
          // editable
          // disabled={isDisabled}
        /> */}
        {/* <CustomNumberInput
          data={data}
          onChange={handleChange}
          extraClassName="d-grid"
          name="contractualNo"
          inputClass="w-3rem"
          inputStyle={{ width: "4rem", borderLeft: "0", borderRight: "0" }}
          label="Contractual No. Of Room"
          showButtons
          buttonLayout="horizontal"
          decrementButtonClassName="bg-white text-gray-600 border-right-none border-gray-200"
          incrementButtonClassName="bg-white text-gray-600 border-left-none border-gray-200"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          min={0}
        /> */}
      </CustomForm>
      <Maturity data={data} handleChange={handleChange} />
      {data?.maturityType === "Optional Renewal" && (
        <AddOptionalMaturity
          allMaturities={allMaturities}
          setAllMaturities={setAllMaturities}
          // errors={maturityErrors}
          // setErrors={setMaturityErrors}
        />
      )}
      <Deposits data={data} handleChange={handleChange} />
    </>
  );
};

export default React.memo(ContractualInfoForm);
