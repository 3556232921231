import React, { useState, useRef } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
  CustomInput,
  // CustomNumberInput2,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
// import { Messages } from "primereact/messages";
import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
import {
  AccessOptions,
  ElectricityuPurposeOptions,
  GasPurposeOptions,
  LocationOptions,
  MeterTypeOpt,
  // PurposeOptions,
} from "../AllDropDownOptions";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
import CustomOverlay from "../CustomOverlay";
import { Sidebar } from "primereact/sidebar";
import AddEnergyClocks from "./AddEnergyClock";
import CustomModal from "../Modals/CustomModal";
import { useDispatch } from "react-redux";
import {
  addEnergyMeterAction,
  deleteEnergyMeterAction,
  getEnergyMeterAction,
  updateEnergyMeterAction,
} from "../../store/actions/buildingUnitActions";
// import { Avatar } from "primereact/avatar";
import { spaceNotAllowed } from "../../utils/regex";
import { Toast } from "primereact/toast";
import formValidation from "../../utils/validations";
const AddEnergyMeters = ({
  // type,
  unitId,
  // energyMeters,
  allEnergyMeters,
  setAllEnergyMeters,
  // errors,
  // setErrors,
}) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const InputObj = {
    id: null,
    purpose: "",
    type: "",
    meter_id: "",
    location: "",
    access: "",
    amount: "",
    status: "",
  };

  // const errorObj = {
  //   prupose: null,
  //   type: null,
  //   name: null,
  //   location: null,
  //   access: null,
  //   amount: null,
  // };

  const ignoreInputs = ["status", "id"];
  const [openRightbar, setOpenRightbar] = useState({
    isOpen: false,
    id: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  // useEffect(() => {
  //   if (allEnergyMeters?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allEnergyMeters?.map((meter, roomIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === roomIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allEnergyMeters]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={
          () => HandleAddNew(InputObj, allEnergyMeters, setAllEnergyMeters)
          // HandleAdd(
          //   meterInputObj,
          //   allEnergyMeters,
          //   setAllEnergyMeters,
          //   errorObj,
          //   errors,
          //   setErrors
          // )
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy meter from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleAddEnergyMeter = (meter, index) => {
    let hasErrors = handleSaveNew(
      index,
      allEnergyMeters,
      setAllEnergyMeters,
      ignoreInputs
    );

    if (!hasErrors && unitId) {
      let data = {
        purpose: meter?.purpose,
        type: meter?.type,
        meter_id: meter?.meter_id,
        location: meter?.location,
        access: meter?.access,
        amount: meter?.amount,
        building_unit: unitId,
      };
      if (meter?.id) {
        dispatch(
          updateEnergyMeterAction(meter?.id, data, dispatch, (res) => {
            if (!res?.success) {
              handlEdit(allEnergyMeters, setAllEnergyMeters, index);
            }
          })
        );
      } else {
        dispatch(
          addEnergyMeterAction(data, dispatch, (res) => {
            if(res){
              allEnergyMeters[index].id = res?.id
              allEnergyMeters[index].status = "save"
              setAllEnergyMeters(allEnergyMeters)
            }else{
              handlEdit(allEnergyMeters, setAllEnergyMeters, index);
            }
          })
        );
      }
    }
  };

  const handleEnergyMeterDelete = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteEnergyMeterAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(
            allEnergyMeters,
            setAllEnergyMeters,
            idToDelete?.index
          );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allEnergyMeters, setAllEnergyMeters, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  // const handleDeleteEnergyMeter = (meter, index) => {
  //   if (meter?.id) {
  //     let originalMeter = energyMeters.find((item) => item?.id === meter?.id);
  //     originalMeter.status = "save";
  //     if (originalMeter) {
  //       const updatedMeters = [...allEnergyMeters];
  //       updatedMeters[index] = { ...originalMeter };
  //       setAllEnergyMeters(updatedMeters);
  //     }
  //   } else {
  //     handleDeleteNew(
  //       allEnergyMeters,
  //       setAllEnergyMeters,
  //       index
  //     );
  //   }
  // };

  const customHeader = (
    <div className="">
      <div className="sidebar-title">Energy Clock </div>
    </div>
  );

  const getMeterDetails = (id, index) => {
    dispatch(
      getEnergyMeterAction(id, (res) => {
        const info = res?.data?.results;
        if (res?.success) {
          handleCancelNew(info, index, allEnergyMeters, setAllEnergyMeters);
        }
      })
    );
  };

  // const handleChange = (name, value, index) => {
  //   const updatedArray = [...allEnergyMeters];
  //   if (index > -1 && index < updatedArray.length) {
  //     let formErrors = formValidation(
  //       name,
  //       value,
  //       updatedArray[index],
  //       ignoreInputs
  //     );
  //     updatedArray[index] = {
  //       ...updatedArray[index],
  //       [name]: value,
  //       formErrors,
  //     };
  //     setAllEnergyMeters(updatedArray);
  //   }
  // };
  return (
    <>
      <CustomForm title={"Energy Meters"} header={header}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />

        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Type</th>
              <th>Purpose</th>
              <th>Meter ID</th>
              <th>Location</th>
              <th>Access</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allEnergyMeters?.map((meter, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        value={meter?.type}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allEnergyMeters,
                          //   setAllEnergyMeters,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="type"
                        col={12}
                        options={MeterTypeOpt}
                        disabled={meter?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={meter?.purpose}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allEnergyMeters,
                          //   setAllEnergyMeters,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="purpose"
                        options={
                          meter.type === "Electricity"
                            ? ElectricityuPurposeOptions
                            : GasPurposeOptions
                        }
                        disabled={meter?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomInput
                        value={meter?.meter_id}
                        col={12}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (spaceNotAllowed(value)) {
                            handleChangeNew(
                              e?.target?.name,
                              value.toUpperCase(),
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            );
                            // handleObjChange(
                            //   allEnergyMeters,
                            //   setAllEnergyMeters,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   e?.target?.name,
                            //   value.toUpperCase(),
                            //   index
                            // );
                          } else if (!value) {
                            handleChangeNew(
                              e?.target?.name,
                              value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            );
                            // handleObjChange(
                            //   allEnergyMeters,
                            //   setAllEnergyMeters,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   e?.target?.name,
                            //   value,
                            //   index
                            // );
                          }
                        }}
                        name="meter_id"
                        disabled={meter?.status === "save" ? true : false}
                        placeholder="Enter Meter ID"
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={meter?.location}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allEnergyMeters,
                          //   setAllEnergyMeters,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="location"
                        col={12}
                        options={LocationOptions}
                        disabled={meter?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={meter?.access}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allEnergyMeters,
                          //   setAllEnergyMeters,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="access"
                        col={12}
                        options={AccessOptions}
                        disabled={meter?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomAmountInput
                        value={meter?.amount}
                        // data={meter}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              "amount",
                              e?.value,
                              index,
                              allEnergyMeters,
                              setAllEnergyMeters,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allEnergyMeters,
                          //   setAllEnergyMeters,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   "amount",
                          //   e?.value,
                          //   index
                          // )
                        }
                        name="amount"
                        disabled={meter?.status === "save" ? true : false}
                        spanLabel={
                          meter?.type === "Electricity" ? (
                            "kWh"
                          ) : (
                            <p>
                              m<sup>3</sup>
                            </p>
                          )
                        }
                        spanExtraClassName={"p-1"}
                        ignoreLabel
                      />
                      {/* <CustomNumberInput2
                        value={meter?.amount}
                        col={12}
                        onChange={(e) =>
                          handleObjChange(
                            allEnergyMeters,
                            setAllEnergyMeters,
                            errors,
                            setErrors,
                            ignoreInputs,
                            e?.target?.name,
                            e?.target?.value,
                            index
                          )
                        }
                        name="amount"
                        disabled={meter?.status === "save" ? true : false}
                        spanLabel={
                          meter?.type === "Electricity" ? (
                            "kWh"
                          ) : (
                            <p>
                              m<sup>3</sup>
                            </p>
                          )
                        }
                        spanExtraClassName={"p-1"}
                        ignoreLabel
                      /> */}
                    </td>
                    {meter?.status === "save" ? (
                      <td>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            {/* {type == "Edit" &&  */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(
                                  allEnergyMeters,
                                  setAllEnergyMeters,
                                  index
                                )
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            {/* } */}
                            {unitId && (
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  setOpenRightbar({
                                    isOpen: true,
                                    id: meter?.id,
                                  })
                                }
                              >
                                <i className="pi pi-bolt text-dark"></i>
                                Energy Clock
                              </li>
                            )}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: meter?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() => handleAddEnergyMeter(meter, index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (meter?.id) {
                                getMeterDetails(meter?.id, index);
                              } else {
                                handleDeleteNew(
                                  allEnergyMeters,
                                  setAllEnergyMeters,
                                  index
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>

                  {(meter?.formErrors?.type ||
                    meter?.formErrors?.purpose ||
                    meter?.formErrors?.meter_id ||
                    meter?.formErrors?.location ||
                    meter?.formErrors?.access ||
                    meter?.formErrors?.amount) && (
                    <tr>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.type}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.purpose}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.meter_id}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.location}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.access}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {meter?.formErrors?.amount}
                        </small>
                      </td>
                    </tr>
                  )}

                  {/* {(errors[index]?.type ||
                    errors[index]?.purpose ||
                    errors[index]?.meter_id ||
                    errors[index]?.location ||
                    errors[index]?.access ||
                    errors[index]?.amount) && (
                    <tr>
                      <td>
                        <small className="p-error">{errors[index]?.type}</small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.purpose}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.meter_id}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.location}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.access}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.amount}
                        </small>
                      </td>
                    </tr>
                  )} */}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
      <Sidebar
        header={customHeader}
        className="view-enery-meter-sidebar"
        visible={openRightbar.isOpen}
        position="right"
        onHide={() => setOpenRightbar({ isOpen: false, id: "" })}
      >
        <AddEnergyClocks meterId={openRightbar?.id} />
      </Sidebar>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleEnergyMeterDelete}
        loading={loading}
      />
    </>
  );
};

export default AddEnergyMeters;
