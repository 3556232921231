import React, { useEffect, useMemo, useRef, useState } from "react";
import StepOne from "./StepOne";
import OfficeInfofmation from "./OfficeInformation";
import FinancialDetails from "./FinancialDetails";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, storeUserInfo } from "../../services/auth";
import BoardingLayout from "../../layout/BoardingLayout";
import CustomCard from "../../shared/CustomCard";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../shared/CustomButton";
import DetailsPreviweModal from "../../shared/Modals/DetailsPreviewModal";
import formValidation from "../../utils/validations";
import { handleSetAddress, showFormErrors } from "../../utils/commonFunctions";
import AddBankAccounts from "../../shared/Components/AddBankAccounts";
// import { Messages } from "primereact/messages";
import InviteEmployees from "../../shared/Components/InviteEmployees";
import {
  addOfficeAction,
  searchOfficeIdAction,
} from "../../store/actions/createProjectActions";
import { setUserProfile } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { checkBankErrors } from "../../shared/Components/commonFunctions";
import { Toast } from "primereact/toast";
export default function CreateProject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [profileInfo, setProfileInfo] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [pageHeading, setPageHeading] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const msgs = useRef(null);
  const [address, setAddress] = useState();
  const [officeData, setOfficeData] = useState({
    officeId: "",
    officeName: "",
    purpose: "",
    address: "",
    street: "",
    zipCode: "",
    city: "",
    cityDivision: "",
    country: "",
    email: "",
    phoneNumber: "",
  });
  const [financialData, setFinancialData] = useState({
    costCenter: "",
  });
  const [allBankAccounts, setAllBankAccounts] = useState([
    {
      id: null,
      purpose: "All Combined",
      owner_name: "",
      iban: "",
      bic: "",
      status: "",
    },
  ]);
  const [bankErrors, setBankErrors] = useState([]);

  const [officeIdOptions, setOfficeIdOptions] = useState([]);

  const description1 = (
    <p>
      Begin by entering the basic information about the office. This may include
      the office name, location, contact details, and a unique identifier or
      code.
      <span className="font-bold">
        Specify the office's type and purposes, such as a property management
        office or social worker.
      </span>
    </p>
  );
  const description2 = "Invite your employees by adding their email IDs.";

  const [allInvites, setAllInvites] = useState([]);
  const [inviteError, setInviteError] = useState("");

  useMemo(() => {
    if (address) {
      handleSetAddress(address, officeData, setOfficeData, [
        "longitude",
        "latitude",
      ]);
    }
  }, [address]);

  useEffect(() => {
    let info = "";
    profile?.first_name ? (info = profile) : (info = getUserInfo());
    setProfileInfo(info);
  }, [profile]);

  useEffect(() => {
    if (activeStep === 1) {
      setPageHeading("Office Information");
    } else if (activeStep === 2) {
      setPageHeading("Financial Details");
    } else if (activeStep === 3) {
      setPageHeading(" Invite Your Employees");
    }
  }, [activeStep]);

  const officeHandleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, officeData);
    setOfficeData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const officeSubmit = () => {
    if (showFormErrors(officeData, setOfficeData)) {
      setActiveStep(2);
    }
  };

  const financialHandleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, financialData);
    setFinancialData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const financialSubmit = () => {
    // const hasBankErrors = handleBankSave(allBankAccounts, setAllBankAccounts, bankErrors, setBankErrors);
    // const hasBankErrors = checkBankErrors(allBankAccounts, setAllBankAccounts, bankErrors, setBankErrors);
    const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts);

    if (
      showFormErrors(financialData, setFinancialData) &&
      !bankAccounts?.hasErrors
    ) {
      setActiveStep(3);
    }

    // if (!isAllAccSaved) {
    //   msgs.current.show({
    //     severity: "error",
    //     detail: "Please save your bank account first.",
    //   });
    // }
  };

  const inviteEmpSubmit = () => {
    if (allInvites.length) {
      setInviteError("");
      // const isAllSaved = allBankAccounts.every((obj) => obj.status === "save");

      let payload = {
        office_id: officeData?.officeId,
        name: officeData?.officeName,
        purpose: officeData?.purpose,
        address: officeData?.address,
        street: officeData?.street,
        zipcode: officeData?.zipCode,
        city: officeData?.city,
        city_division: officeData?.cityDivision,
        country: officeData?.country,
        office_email: officeData?.email,
        phone_number: officeData?.phoneNumber,
        company: profileInfo?.company,
        cost_center: financialData?.costCenter,
        invite_employee: allInvites,
      };
      const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts);

      if (!bankAccounts?.hasErrors && bankAccounts?.bankAccounts?.length) {
        payload.bank_accounts = bankAccounts?.bankAccounts;
      }

      let data = JSON.stringify(payload);
      dispatch(
        addOfficeAction(profileInfo?.company, data, setLoading, (res) => {
          if (res.success) {
            let user = { ...profileInfo, is_onboarding: false };
            storeUserInfo(user, user?.rememberMe);
            dispatch(setUserProfile(user));
            navigate("/dashboard");
          } else {
            msgs.current.show({
              severity: "error",
              detail: res?.message,
            });
          }
        })
      );
    } else {
      setInviteError("Please invite at least one employee.");
    }
  };

  const handleNextClick = (step) => {
    if (step === 1) {
      officeSubmit();
    } else if (step === 2) {
      financialSubmit();
    } else if (step === 3) {
      inviteEmpSubmit();
    }
  };

  const handleBack = (step) => {
    setActiveStep(step - 1);
  };

  const handleOfficeIdChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, officeData);
    setOfficeData((prev) => ({ ...prev, [name]: value, formErrors }));
    if (value) {
      debouncedOnChange.current(value);
    }
  };

  const handleSearch = (value) => {
    let payload = {
      office_id: value,
    };

    dispatch(
      searchOfficeIdAction(payload, (res) => {
        if (!res?.success) {
          const formErrors = { ["officeId"]: res?.message };
          setOfficeData((prev) => ({
            ...prev,
            ["officeId"]: value,
            formErrors,
          }));
          setOfficeIdOptions(res?.data?.results);
        } else {
          setOfficeIdOptions([]);
        }
      })
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  return (
    <BoardingLayout>
      {activeStep === 0 ? (
        <StepOne setActiveStep={setActiveStep} profileInfo={profileInfo} />
      ) : (
        <div className="wrapper-auth h-full py-5 bg-lightest">
          <div className="container relative ">
            <h3 className="text-center fs-3 text-black fw-bold mb-5">
              Let’s get started with adding your office details!
            </h3>
            {/* <Link to="" className="text-black absolute top-0 right-0 text-base">
              Skip
            </Link> */}
            <div className="text-center flex indicators-step justify-content-center mb-4">
              <span className={"step-indicator active"} id="step-1-indicator">
                1
              </span>
              <span
                className={
                  activeStep === 2 || activeStep === 3
                    ? "step-indicator active"
                    : "step-indicator"
                }
                id="step-2-indicator"
              >
                2
              </span>
              <span
                className={
                  activeStep === 3 ? "step-indicator active" : "step-indicator"
                }
                id="step-3-indicator"
              >
                3
              </span>
            </div>
            <div
              className={activeStep === 1 ? "form-step active" : "form-step"}
              id="step-1"
            >
              <div className="text-center mb-5">
                <h4 className="text-black fs-5 fw-bold mb-3">{pageHeading}</h4>
                <p className="text-gray-color fw-medium">
                  {activeStep === 3 ? description2 : description1}
                </p>
              </div>
              <CustomCard>
                {/* <Messages className="w-full" ref={msgs} /> */}
                <Toast ref={msgs} />
                {activeStep === 1 ? (
                  <OfficeInfofmation
                    data={officeData}
                    handleChange={officeHandleChange}
                    officeIdOptions={officeIdOptions}
                    handleOfficeIdChange={handleOfficeIdChange}
                    setAddress={setAddress}
                    debouncedOnChange={debouncedOnChange}
                  />
                ) : activeStep === 2 ? (
                  <>
                    <FinancialDetails
                      data={financialData}
                      title={"Enter Financial Details"}
                      handleChange={financialHandleChange}
                    />
                    <AddBankAccounts
                      title="Add Bank Account"
                      allBankAccounts={allBankAccounts}
                      setAllBankAccounts={setAllBankAccounts}
                      errors={bankErrors}
                      setErrors={setBankErrors}
                    />
                  </>
                ) : activeStep === 3 ? (
                  <InviteEmployees
                    title="Invite Employees"
                    error={inviteError}
                    setError={setInviteError}
                    allInvites={allInvites}
                    setAllInvites={setAllInvites}
                  />
                ) : null}
              </CustomCard>
              <Buttonlayout>
                {activeStep !== 1 && (
                  <CustomButtonOutlined
                    disabled={loading}
                    extraClassNames="btn primary-button-outlined bg-transparent"
                    label="Back"
                    type="button"
                    onClick={() => handleBack(activeStep)}
                  />
                )}
                {activeStep === 3 && (
                  <CustomButtonOutlined
                    disabled={loading}
                    extraClassNames="primary-button-outlined bg-transparent"
                    onClick={() => setDetailsModal(true)}
                    label="Preview"
                    type="button"
                  />
                )}
                <CustomButton
                  onClick={() => handleNextClick(activeStep)}
                  label={activeStep === 3 ? "Save" : "Next"}
                  type="button"
                  loading={loading}
                ></CustomButton>
              </Buttonlayout>
            </div>
          </div>
        </div>
      )}
      <DetailsPreviweModal
        visible={detailsModal}
        setVisible={setDetailsModal}
        info={officeData}
      />
    </BoardingLayout>
  );
}
