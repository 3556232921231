import React, { useEffect, useRef, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFurnitureAction,
  getFurnitureAction,
} from "../../store/actions/landlordActions";
import { getAllHandymanServiceListAction } from "../../store/actions/handymanServiceAction";

const AddService = ({ title, allValues, setAllValues }) => {
  const openAction = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const InputObj = {
    service: "",
    email: "",
    work_phone: "",
    home_phone: "",
  };
  const ignoreInputs = ["status", "id"];
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  const [selectedItem, setSelectedItem] = useState(null);
  const items = useRef(Array.from({ length: 100000 }));
  // const [loading, setLoading] = useState(false);
  const loadLazyTimeout = useRef();
  const [servicesList, setServicesList] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company);
    }
  }, [profile]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(InputObj, allValues, setAllValues)}
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected furniture from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleFurnitureDelete = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteFurnitureAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allValues, setAllValues, idToDelete?.index);

          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allValues, setAllValues, idToDelete?.index);

      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getFunrnitureInfo = (id, index) => {
    dispatch(
      getFurnitureAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allValues, setAllValues);
        }
      })
    );
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  // const onLazyLoad = (event) => {
  //   setLoading(true);

  //   if (loadLazyTimeout.current) {
  //     clearTimeout(loadLazyTimeout.current);
  //   }

  //   //imitate delay of a backend call
  //   loadLazyTimeout.current = setTimeout(() => {
  //     const { first, last } = event;
  //     const _items = [...items.current];

  //     for (let i = first; i < last; i++) {
  //       _items[i] = { label: `Item #${i}`, value: i };
  //     }

  //     items.current = _items;
  //     setLoading(false);
  //   }, Math.random() * 1000 + 250);
  // };

  return (
    <>
      <CustomForm title={title} header={header}>
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Service</th>
              <th>Email</th>
              <th>Work Phone</th>
              <th>Home Phone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allValues?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        data={item}
                        onChange={(e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allValues,
                            setAllValues,
                            ignoreInputs
                          )
                        }
                        name="service"
                        col={12}
                        options={servicesList}
                        disabled={item?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={item?.formErrors?.type}
                      />
                      {/* <Dropdown
                        value={selectedItem}
                        onChange={(e) => setSelectedItem(e.value)}
                        options={servicesList}
                        placeholder="Select Item"
                        className="w-full md:w-14rem"
                        optionLabel="name"
                        virtualScrollerOptions={{
                          lazy: true,
                          onLazyLoad: onLazyLoad,
                          itemSize: 38,
                          showLoader: true,
                          loading: loading,
                          delay: 250,
                        }}
                      /> */}
                    </td>
                    <td>
                      <CustomInput
                        disabled={item?.status === "save"}
                        type="text"
                        name="email"
                        extraClassName="w-full"
                        value={item?.email}
                        onChange={(e) => {
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allValues,
                            setAllValues,
                            ignoreInputs
                          );
                        }}
                        ignoreLabel
                        errorMessage={item?.formErrors?.email}
                      />
                    </td>
                    <td>
                      <CustomPhoneInput
                        disabled={item?.status === "save"}
                        data={item}
                        extraClassName="w-full"
                        onChange={(e) => {
                          handleChangeNew(
                            e?.name,
                            e?.value,
                            index,
                            allValues,
                            setAllValues,
                            ignoreInputs
                          );
                        }}
                        name="work_phone"
                        required
                        ignoreLabel
                        errorMessage={item?.formErrors?.office_phone}
                      />
                    </td>
                    <td>
                      <CustomPhoneInput
                        disabled={item?.status === "save"}
                        data={item}
                        extraClassName="w-full"
                        onChange={(e) => {
                          handleChangeNew(
                            e?.name,
                            e?.value,
                            index,
                            allValues,
                            setAllValues,
                            ignoreInputs
                          );
                        }}
                        name="home_phone"
                        required
                        ignoreLabel
                        errorMessage={item?.formErrors?.office_phone}
                      />
                    </td>
                    {item?.status === "save" ? (
                      <td>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(allValues, setAllValues, index)
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: item?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              handleSaveNew(
                                index,
                                allValues,
                                setAllValues,
                                ignoreInputs
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (item.id) {
                                getFunrnitureInfo(item.id, index);
                              } else {
                                handleDeleteNew(allValues, setAllValues, index);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleFurnitureDelete}
        loading={loading}
      />
    </>
  );
};

export default AddService;
