import React, { useEffect, useState } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import { CustomForm } from "../../../../shared/AllInputs";
import { CustomViewInput } from "../../../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeInfoAction } from "../../../../store/actions/EmployeeActions";
import { getUserInfo } from "../../../../services/auth";
import EditIcon from "../../../../assets/images/icons/editicon.png";
import CommonViewTable from "../../../Tables/CommonViewTable";
import { setUserProfile } from "../../../../store/slices/userSlice";
export default function ViewEmployee() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.company) {
      getEmployeeInfo(profile?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/administrator/employees/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  const getEmployeeInfo = (company) => {
    dispatch(
      getEmployeeInfoAction(company, params?.id, dispatch, (res) => {
        let info = {
          employeeId: res?.employee_id,
          firstName: res?.user?.first_name,
          lastName: res?.user?.last_name,
          email: res?.user?.email,
          phoneNumber: res?.user?.phone_number,
          role: res?.role?.name,
          offices: res?.offices,
        };
        setData(info);
      })
    );
  };

  const columns = [
    {
      name: "Office",
      accessor: "name",
    },
    {
      name: "Position",
      accessor: "position",
    },
  ];

  return (
    <Page
      title="View Employee"
      description={`Let’s view your Employee Details`}
      navigateLink={"/administrator/employees/list"}
    >
      <CustomCard>
        <CustomForm title="General" header={header()}>
          <CustomViewInput
            name="employeeId"
            data={data}
            label={"Employee ID"}
          />
          <CustomViewInput name="firstName" data={data} />
          <CustomViewInput name="lastName" data={data} />
          <CustomViewInput name="email" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
        </CustomForm>
        <CustomForm title="Role">
          <CustomViewInput name="role" data={data} />
        </CustomForm>
        <CommonViewTable
          title={"Office"}
          list={data?.offices}
          columns={columns}
        />
      </CustomCard>
    </Page>
  );
}
