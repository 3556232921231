import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import formValidation from "../../../../../utils/validations";
import { showFormErrors } from "../../../../../utils/commonFunctions";
import { addHandymanAction } from "../../../../../store/actions/HandymanActions";
import { useDispatch, useSelector } from "react-redux";
import { checkHandymanServiceErrors } from "../../../../../shared/Components/commonFunctions";
import { getUserInfo } from "../../../../../services/auth";
import { setUserProfile } from "../../../../../store/slices/userSlice";
import { InputSwitch } from "primereact/inputswitch";
import { CustomSwitch } from "../../../../../shared/AllInputs";
import { setHandymanSlice } from "../../../../../store/slices/handymanSlice";

const HandymanContainer = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        name: "", address: "", feedback: false
    })
    const [selectedAddress, setSelectedAddress] = useState()
    const [loading, setLoading] = useState(false)
    const [allServices, setAllServices] = useState([
        { id: "", service: "", email: "", work_phone: "", home_phone: "", status: "" }
    ])
    const navigate = useNavigate()
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        let info = ""
        if (!profile.company) {
            info = getUserInfo()
            dispatch(setUserProfile(info))
        }
    }, [profile])

    const header = () => {
        return (
            <div className="col-6 text-right">
               <CustomSwitch flexClass="justify-content-end" data={data} extraClassName="ml-auto" onChange={handleChange} name="feedback" label="feedback" col={10}/>
            </div>
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "address") {
            setSelectedAddress("")
        }
    }

    useMemo(() => {
        if (selectedAddress) {
            const formErrors = formValidation("address", selectedAddress?.address, data);
            setData((prev) => ({ ...prev, ["address"]: selectedAddress?.address, formErrors }));
        }
    }, [selectedAddress])

    const onSubmit = () => {
        const services = checkHandymanServiceErrors(allServices, setAllServices)
        if (showFormErrors(data, setData) && !services?.hasErrors) {
            let paylod = {
                ...data,
                services: services.services
            }
            dispatch(
                addHandymanAction(
                    profile?.company,
                    paylod,
                    setLoading,
                    dispatch,
                    (res) => {
                        navigate("/real-estate/maintenance/handyman/list")
                        dispatch(setHandymanSlice(res))
                    }
                )
            )
        }
    }

    return {
        header,
        data,
        handleChange,
        setSelectedAddress,
        onSubmit,
        selectedAddress,
        loading,
        navigate,
        allServices,
        setAllServices
    }
}
export default HandymanContainer