import React, { useEffect, useRef } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  CustomPhoneInput,
} from "../../../../shared/AllInputs";
import { Messages } from "primereact/messages";
import OfficeContainer from "./OfficeContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../shared/CustomButton";
import FinancialDetails from "../../../CreateProject/FinancialDetails";
import AddExistingOfficeEmployee from "../../../../shared/Components/AddExistingOfficeEmployee";
import AddBankAccounts from "../../../../shared/Components/AddBankAccounts";
import InviteEmployees from "../../../../shared/Components/InviteEmployees";
import { Toast } from "primereact/toast";
import GooglePlacesInput from "../../../../shared/GooglePlacesInput";

export default function OfficeForm({ type }) {
  const msgs = useRef(null);
  const {
    data,
    handleChange,
    onSubmit,
    allBankAccounts,
    setAllBankAccounts,
    allEmployees,
    setAllEmployees,
    loading,
    msgToShow,
    unitList,
    officeOptions,
    employeesList,
    inviteError,
    setInviteError,
    allInvites,
    setAllInvites,
    officeIdOptions,
    handleOfficeIdChange,
    bankErrors,
    setBankErrors,
    empErrors,
    setEmpErrors,
    setSelectedAddress,
  } = OfficeContainer();

  useEffect(() => {
    if (msgToShow?.message) {
      msgs?.current?.show({
        severity: msgToShow?.type,
        detail: msgToShow?.message,
      });
    }
  }, [msgToShow]);

  return (
    <Page
      title={`${type} Office`}
      description={`Let’s ${type} your Office Details`}
    >
      <CustomCard>
        <CustomForm title="Office Information">
          {/* <Messages className="w-full" ref={msgs} /> */}
          <Toast ref={msgs} />
          <CustomDropDown
            data={data}
            onChange={handleOfficeIdChange}
            name="officeId"
            options={officeIdOptions}
            label={"Office ID"}
            placeholder="Office ID"
            required
            editable
          />
          {/* <CustomInput
            data={data}
            onChange={handleChange}
            name="officeId"
            required
          /> */}
          <CustomInput
            data={data}
            onChange={handleChange}
            name="officeName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="officeNickName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="officeEmail"
            required
          />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unit"
            options={unitList}
            placeholder="Select Unit"
            // required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="purpose"
            label={"Office Purpose"}
            options={officeOptions}
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="representative"
            label={"Select Office Representative"}
            options={employeesList}
          />
        </CustomForm>
        <br />
        <CustomForm title="Address">
          <GooglePlacesInput
            id="office"
            data={data}
            onChange={(e) => {
              handleChange({ name: e.name, value: e.value });
            }}
            name="address"
            required
            setAddress={setSelectedAddress}
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="street"
            required
          />
          <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="zipCode"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="city"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="cityDivision"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="country"
            required
          />
        </CustomForm>
        <FinancialDetails
          data={data}
          title="Financial Details"
          handleChange={handleChange}
        />
        <br />
        <AddBankAccounts
          title="Add Bank Account"
          allBankAccounts={allBankAccounts}
          setAllBankAccounts={setAllBankAccounts}
          errors={bankErrors}
          setErrors={setBankErrors}
        />
        <br />
        <AddExistingOfficeEmployee
          allEmployees={allEmployees}
          setAllEmployees={setAllEmployees}
          empErrors={empErrors}
          setEmpErrors={setEmpErrors}
        />
        <br />
        <InviteEmployees
          title="Invite Employees"
          error={inviteError}
          setError={setInviteError}
          allInvites={allInvites}
          setAllInvites={setAllInvites}
          disabled={type === "Edit" ? true : false}
        />
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined label="Cancel" />
      </Buttonlayout>
    </Page>
  );
}
