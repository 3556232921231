import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { addContractAction, contractSuggestions, getContractAction, updateContractAction } from "../../../../store/actions/contractActions";
import { useDispatch, useSelector } from "react-redux";
import formValidation from "../../../../utils/validations";
import { getUserInfo } from "../../../../services/auth";
import { setUserProfile } from "../../../../store/slices/userSlice";
import { landlordDropdownAction } from "../../../../store/actions/landlordActions";
import { searchProperty } from "../../../../store/actions/propertyActions";
import { checkExtraCostErrors, checkOptionalMaturtiesErrors } from "../../../../shared/Components/commonFunctions";
import { showFormErrors } from "../../../../utils/commonFunctions";
import { useNavigate, useParams } from "react-router-dom";

const ContractContainer = () => {
    const dispatch = useDispatch()
    const { profile } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [data, setData] = useState({
        id: "",
        contractId: "",
        estateCompany: "",
        contractualSize: "",
        contractualNo: 0,
        landlord: "",
        landlordId: "",
        property: "",
        amount: "",
        amountFirstMonth: "",
        rentRiseType: "",
        intervalType: "",
        riseInterval: "",
        startDate: "",
        endDate: "",
        riseAmount: "",
    })

    const [maturityData, setMaturityData] = useState({
        maturityType: "",
        maturityDate: "",
        maturityExtension: "",
        noticePeriod: "",
    })

    const [depositData, setDepositData] = useState({
        depositType: "",
        amount: ""
    })

    // const [isContactExist, setIsContactExist] = useState("")
    const [ContractOptions, setContractOptions] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [loading, setLoading] = useState(false);
    const skipAble = [1]

    const [allExtraCost, setAllExtraCost] = useState([{
        id: "",
        type: "",
        payment_type: "",
        amount: "",
        amount_first_month: "",
        amount_last_month: "",
        applicable_tenant_rent: "",
        status: "",
    }])
    const userProfileRef = useRef()
    const [allMaturities, setAllMaturities] = useState([
        { id: "", extension: "", notice_period: "", status: "" }
    ])
    // const loadLazyTimeout = useRef();
    const [landlordList, setLandlordList] = useState()
    const [propertyList, setPropertyList] = useState()
    const [disableTabs, setDisableTabs] = useState([1, 2])
    // const [disableTabs, setDisableTabs] = useState([])
    const { contract } = useSelector((state) => state?.contract)
    const params = useParams()

    useEffect(() => {
        let info = ""
        if (!profile.company) {
            info = getUserInfo()
            dispatch(setUserProfile(info))
        } else {
            info = profile
        }
        userProfileRef.current = info
    }, [profile])

    useEffect(() => {
        if (params?.id) {
            contract ? handleContractData(contract) : getContractDetail(profile?.company, params?.id)
        }
    }, [params?.id, profile, contract])

    const getContractDetail = (company, id) => {
        dispatch(getContractAction(company, id, dispatch, (res) => {
            handleContractData(res)
        }))
    }

    const handleContractSearch = (value) => {
        dispatch(
            contractSuggestions(value, setContractOptions, (error) => {
                const formErrors = ({ ["contractId"]: error })
                setData((prev) => ({ ...prev, ["contractId"]: value, formErrors }));
            })
        );
    };

    const contractIdChange = useRef(debounce((value) => { handleContractSearch(value) }, 1000));

    // const onLazyLoad = (event) => {
    //     // setLoading(true);

    //     if (loadLazyTimeout.current) {
    //         clearTimeout(loadLazyTimeout.current);
    //     }

    //     //imitate delay of a backend call
    //     loadLazyTimeout.current = setTimeout(
    //         () => {
    //             // const { first, last } = event;
    //             // const _items = [...items.current];

    //             // for (let i = first; i < last; i++) {
    //             //     _items[i] = { label: `Item #${i}`, value: i };
    //             // }

    //             // items.current = _items;
    //             // setLoading(false);
    //         },
    //         Math.random() * 1000 + 250
    //     );
    // };

    const handleChange = ({ name, value }) => {
        let ignoreInputs = ["id", "landlordId"]
        if (name === "rentRiseType" && (value === "None" || !value)) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
        }

        const formErrors = formValidation(name, value, data, ignoreInputs);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "landlord") {
            if (value?.landlord) {
                setData((prev) => ({ ...prev, ["landlordId"]: value?.landlord, formErrors }));
            } else {
                setData((prev) => ({ ...prev, ["landlordId"]: "", formErrors }));
            }
        }
    }

    const handleMaturityChange = ({ name, value }) => {
        let ignoreInputs = []
        if (name === "maturityType" && (value === "None" || !value)) {
            ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
        }

        if (maturityData?.maturityType === "Optional Renewal") {
            ignoreInputs.push("noticePeriod")
            ignoreInputs.push("maturityExtension")
        }

        if(name === "maturityType" && value === "Automatic Renewal"){
            const formErrors = formValidation(name, value, maturityData, ignoreInputs);
            setMaturityData((prev) => ({ ...prev, ["maturityExtension"]: 12, formErrors }));
        }else if(name === "maturityType"){
            const formErrors = formValidation(name, value, maturityData, ignoreInputs);
            setMaturityData((prev) => ({ ...prev, ["maturityExtension"]: "", formErrors }));
        }

        const formErrors = formValidation(name, value, maturityData, ignoreInputs);
        setMaturityData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    const handleDepositChange = ({ name, value }) => {
        let ignoreInputs = []
        const formErrors = formValidation(name, value, depositData, ignoreInputs);

        if (depositData.depositType === "None") {
            ignoreInputs.push("amount")
            setDepositData((prev) => ({ ...prev, ["amount"]: null, formErrors }));
        }
        setDepositData((prev) => ({ ...prev, [name]: value, formErrors }));
    }

    const landlordChange = useRef(debounce((value) => { handleLandlordList(value) }, 1000))

    const handleLandlordList = (value) => {
        dispatch(
            landlordDropdownAction(userProfileRef?.current?.company, value, setLandlordList, (res) => {
                if (!res.length) {
                    const formErrors = ({ ["landlord"]: "Landlord does not exist!." })
                    setData((prev) => ({ ...prev, formErrors }));
                }
            })
        );
    }

    const propertyChange = useRef(debounce((value) => {
        handlePropertyList(value)
    }, 1000))

    const handlePropertyList = (value) => {
        dispatch(
            searchProperty(userProfileRef?.current?.company, value, (res) => {
                if (res?.length) {
                    let options = res?.map((item) => {
                        return { id: item?.id, property_id: item?.property_id }
                    })
                    setPropertyList(options)
                } else {
                    const formErrors = ({ ["property"]: "Property does not exist!." })
                    setData((prev) => ({ ...prev, formErrors }));
                    setPropertyList([])
                }
            })
        );
    }

    const handleTabChange = (index) => {
        setActiveIndex(index)
        const updatedTabs = disableTabs.filter(tab => tab !== index)
        setDisableTabs(updatedTabs)
    }

    const contractSubmit = () => {
        const extraCosts = checkExtraCostErrors(allExtraCost, setAllExtraCost)
        let ignoreInputs = ["id", "landlordId"]

        if (data?.rentRiseType === "None" || !data?.rentRiseType && data?.formErrors) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
            delete data?.formErrors?.intervalType
            delete data?.formErrors?.riseInterval
            delete data?.formErrors?.startDate
            delete data?.formErrors?.riseAmount
            delete data?.formErrors?.endDate
        }

        let contractData = {
            ...data,
            extra_costs: extraCosts?.extraCosts
        }

        if (showFormErrors(data, setData, ignoreInputs) && !extraCosts?.hasErrors) {
            if (data?.id) {
                dispatch(
                    updateContractAction(profile?.company, data?.id, contractData, setLoading, dispatch, (res) => {
                        setData((prev) => ({ ...prev, id: res?.id }))
                        if (res?.extra_costs?.length) {
                            let extraCost = res?.extra_costs?.map((cost) => {
                                if (cost?.applicable_tenant_rent === true) {
                                    return { ...cost, applicable_tenant_rent: "yes", status: "save" };
                                } else if (cost?.applicable_tenant_rent === false) {
                                    return { ...cost, applicable_tenant_rent: "no", status: "save" };
                                }
                            });
                            setAllExtraCost(extraCost);
                        } else {
                            setAllExtraCost([])
                        }
                        handleTabChange(activeIndex + 1)
                    }))
            } else {
                dispatch(
                    addContractAction(profile?.company, contractData, setLoading, dispatch, (res) => {
                        setData((prev) => ({ ...prev, id: res?.id }))
                        if (res?.extra_costs?.length) {
                            let extraCost = res?.extra_costs?.map((cost) => {
                                if (cost?.applicable_tenant_rent === true) {
                                    return { ...cost, applicable_tenant_rent: "yes", status: "save" };
                                } else if (cost?.applicable_tenant_rent === false) {
                                    return { ...cost, applicable_tenant_rent: "no", status: "save" };
                                }
                            });
                            setAllExtraCost(extraCost);
                        } else {
                            setAllExtraCost([])
                        }
                        handleTabChange(activeIndex + 1)
                    }))
            }
        }
    }

    const maturitySubmit = () => {
        const maturities = checkOptionalMaturtiesErrors(allMaturities, setAllMaturities)
        let ignoreInputs = []
        if (maturityData?.maturityType === "None" || !maturityData?.maturityType && maturityData?.formErrors) {
            ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
            delete maturityData?.formErrors?.maturityDate
            delete maturityData?.formErrors?.maturityExtension
            delete maturityData?.formErrors?.noticePeriod
        }

        if (maturityData?.maturityType === "Optional Renewal") {
            ignoreInputs.push("noticePeriod", "maturityExtension")
            delete maturityData?.formErrors?.noticePeriod
        }

        if (showFormErrors(maturityData, setMaturityData, ignoreInputs) && !maturities?.hasErrors) {
            let payload = {
                ...data,
                ...maturityData,
                optional_maturities: maturities?.optionalMaturities,
            }
            dispatch(
                updateContractAction(profile?.company, data?.id, payload, setLoading, dispatch, (res) => {
                    if (res?.optional_maturities?.length) {
                        let maturities = res?.optional_maturities?.map((maturity) => {
                            return { ...maturity, status: "save" }
                        })
                        setAllMaturities(maturities)
                    } else {
                        setAllMaturities([])
                    }
                    handleTabChange(activeIndex + 1)
                }))

        }
    }

    const depositSubmit = () => {
        let ignoreInputs = []
        if (depositData.depositType === "None") {
            ignoreInputs.push("amount")
        }

        if (showFormErrors(depositData, setDepositData, ignoreInputs)) {
            let payload = {
                ...data,
                ...depositData,
            }
            dispatch(
                updateContractAction(profile?.company, data?.id, payload, setLoading, dispatch, (res) => {
                    params?.id ? navigate(`/real-estate/property/contract/view/${params?.id}`) : navigate(`/real-estate/property/contract/list`)
                }))
        }
    }

    const onSubmit = () => {
        if (activeIndex === 0) {
            contractSubmit()
        } else if (activeIndex === 1) {
            maturitySubmit()
        } else if (activeIndex === 2) {
            depositSubmit()
        }
    };

    const handleContractData = (info) => {
        let contractData = {
            id: info?.id,
            contractId: info?.contract_id,
            estateCompany: info?.estate_company,
            contractualSize: info?.size,
            contractualNo: info?.room,
            landlord: `${info?.landlord_name} (${info?.landlord_id})`,
            landlordId: info?.landlord,
            property: info?.property,
            amount: info?.rent_amount,
            amountFirstMonth: info?.rent_amount_first_month,
            rentRiseType: info?.rent_rise_type,
            intervalType: info?.rent_rise_interval_type,
            riseInterval: info?.rent_rise_interval,
            startDate: new Date(info?.date_of_first_rise),
            endDate: new Date(info?.date_of_last_rise),
            riseAmount: info?.rent_rise_amount,
        }

        setData(contractData)

        if (info?.extra_costs?.length) {
            let extraCost = info?.extra_costs?.map((cost) => {
                if (cost?.applicable_tenant_rent === true) {
                    return { ...cost, applicable_tenant_rent: "yes", status: "save" };
                } else if (cost?.applicable_tenant_rent === false) {
                    return { ...cost, applicable_tenant_rent: "no", status: "save" };
                }
            });
            setAllExtraCost(extraCost);
        } else {
            setAllExtraCost([])
        }

        if (info?.optional_maturities?.length) {
            let maturities = info?.optional_maturities?.map((maturity) => {
                return { ...maturity, status: "save" }
            })
            setAllMaturities(maturities)
        } else {
            setAllMaturities([])
        }

        let depositInfo = {
            depositType: info?.deposit_type,
            amount: info?.deposit_amount
        }

        setDepositData(depositInfo)

        let maturityInfo = {
            maturityType: info?.maturity_type,
            maturityDate: new Date(info?.maturity_date),
            maturityExtension: info?.maturity_extension,
            noticePeriod: info?.maturity_notice_period,
        }

        setMaturityData(maturityInfo)
    }

    return {
        data,
        handleChange,
        ContractOptions,
        contractIdChange,
        landlordChange,
        landlordList,
        propertyChange,
        propertyList,
        // onLazyLoad,
        disableTabs,

        //maturity
        maturityData,
        handleMaturityChange,

        depositData,
        handleDepositChange,

        // isContactExist,
        handleTabChange,
        activeIndex,
        skipAble,
        onSubmit,
        loading,

        //rent and cost
        allExtraCost,
        setAllExtraCost,

        allMaturities,
        setAllMaturities,

    }
}

export default ContractContainer