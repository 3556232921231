import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomViewInput } from "../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/icons/editicon.png";
const ViewPropertyComponent = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/property/edit/property/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <CustomForm title="Property Info" header={header()}>
      <CustomViewInput name="propertyId" data={data} label={"Property ID"}/>
      <CustomViewInput name="address" data={data} />
      <CustomViewInput name="houseNo" data={data} />
      <CustomViewInput name="street" data={data} />
      <CustomViewInput name="zipCode" data={data} />
      <CustomViewInput name="city" data={data} />
      <CustomViewInput name="cityDivision" data={data} />
      <CustomViewInput name="country" data={data} />
    </CustomForm>
  );
};

export default React.memo(ViewPropertyComponent);
