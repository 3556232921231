import React, { useEffect, useState } from "react";
import {
  CustomDropDown,
  CustomFileInput,
  CustomForm,
  CustomTextArea,
} from "../../shared/AllInputs";
import { damageCauseopt } from "../../shared/AllDropDownOptions";
import { getAllHandymanServiceListAction } from "../../store/actions/handymanServiceAction";
import { useDispatch, useSelector } from "react-redux";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../shared/CustomButton";
import formValidation from "../../utils/validations";
import {
  getFileExtension,
  getFileName,
  // handleFileConversion,
  showFormErrors,
} from "../../utils/commonFunctions";
import Page from "../../shared/Page";
import CustomCard from "../../shared/CustomCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  addDamageAction,
  getDamageDetailAction,
  updateDamageAction,
} from "../../store/actions/damageActions";
import { getUserInfo } from "../../services/auth";
import { setUserProfile } from "../../store/slices/userSlice";

const AddDamageForm = ({
  allDamages,
  setAllDamages,
  setIsAddDamage,
  reportId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { profile } = useSelector((state) => state?.user);
  const [serviceList, setServicesList] = useState([]);
  const [damageDocuments, setDamageDocuments] = useState([]);
  const [fileKey, setFileKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const dataInitialState = {
    index: "",
    id: "",
    damageCause: "",
    damageType: "",
    damageDescription: "",
    document: [],
    damage_report: reportId || "",
  };

  const [data, setData] = useState(dataInitialState);

  useEffect(() => {
    if (profile?.company) {
      getServiceList();
    } else {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.company && params?.id && !reportId) {
      getDamageDetails(profile?.company, params?.id);
    }
  }, [profile, params, reportId]);

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data, [
      "index",
      "id",
      "damage_report",
    ]);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setDamageDocuments([...damageDocuments, file]);

    // const convertedFile = await handleFileConversion(file);
    let douments = [...data.document];
    const formErrors = formValidation("document", file, data);
    douments.push({ document: file });
    setData((prev) => ({ ...prev, ["document"]: douments, formErrors }));
  };

  const handleFileDelete = (index) => {
    let arr = [...damageDocuments];
    arr?.splice(index, 1);
    setDamageDocuments(arr);

    let douments = [...data.document];
    douments?.splice(index, 1);
    setData((prev) => ({ ...prev, ["document"]: douments }));

    if (damageDocuments.length == 1) {
      setDamageDocuments([]);
      setFileKey((prevKey) => prevKey + 1);
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData, ["index", "id", "damage_report"])) {
      if (reportId) {
        dispatch(
          addDamageAction(
            profile?.company,
            data,
            setLoading,
            dispatch,
            (res) => {
              setDamageDocuments([]);
              setData(dataInitialState);
              setIsAddDamage(false);
              const damages = [...allDamages];
              damages.push({
                damageCause: data.damageCause,
                damageDescription: data.damageDescription,
                damageType: data.damageType,
                document: data.document,
              });
              setAllDamages(damages);
            }
          )
        );
      } else if (params?.id) {
        dispatch(
          updateDamageAction(
            profile?.company,
            params?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              navigate(-1);
              // dispatch(setIsDamageUpdateSlice(true));
              setDamageDocuments([]);
              setData(dataInitialState);
            }
          )
        );
      } else {
        const damages = [...allDamages];
        if (data?.index === 0 || data?.index > 0) {
          damages[data.index] = { ...data };
        } else {
          damages.push({
            damageCause: data.damageCause,
            damageDescription: data.damageDescription,
            damageType: data.damageType,
            document: data.document,
          });
        }

        // Update state
        setAllDamages(damages);
        setIsAddDamage(false);
        setDamageDocuments([]);
        setData(dataInitialState);
      }
    }
  };

  const onDamageCancel = () => {
    setDamageDocuments([]);
    setData(dataInitialState);
    setIsAddDamage(false);
  };

  const getDamageDetails = (companyId, damageId) => {
    dispatch(
      getDamageDetailAction(companyId, damageId, (res) => {
        let documents = res?.images?.map((file) => {
          return { document: file?.image, id: file?.id };
        });

        setData({
          index: "",
          id: res?.id,
          damageCause: res?.damage_cause,
          damageType: res?.damage_type?.id,
          damageDescription: res?.description,
          document: documents,
        });

        setDamageDocuments(res?.images);
      })
    );
  };

  return (
    <Page
      title={`Report Damage`}
      description={`Let’s ${params?.id ? "Edit" : "Add"} your Damage Details`}
      // navigateLink={"/real-estate/maintenance/handyman/list"}
    >
      <CustomCard>
        <CustomForm title={`${params?.id ? "Edit" : "Add"} Damage`}>
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="damageCause"
            options={damageCauseopt}
            label="Damage Cause"
            placeholder="Select Cause"
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="damageType"
            options={serviceList}
            required
          />
          <CustomTextArea
            col={12}
            data={data}
            onChange={handleChange}
            name="damageDescription"
            max={9}
            required
          />
        </CustomForm>
        <CustomForm>
          <CustomFileInput
            data={data}
            selectedFiles={data?.document}
            inputKey={fileKey}
            type="file"
            name="document"
            label="Add Damage Photo "
            onChange={handleFileChange}
            required
          />
          {damageDocuments.length > 0 && (
            <div>
              <h5>Selected files:</h5>
              {damageDocuments.map((file, index) => (
                <li className="upload-list" key={index}>
                  {file?.name
                    ? file?.name
                    : getFileName(file?.image) +
                      "." +
                      getFileExtension(file?.image)}{" "}
                  <i
                    onClick={() => handleFileDelete(index)}
                    className="pi pi-times"
                    style={{
                      color: "red",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  ></i>
                </li>
              ))}
            </div>
          )}
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined label="Cancel" onClick={onDamageCancel} />
      </Buttonlayout>
    </Page>
  );
};

export default AddDamageForm;
