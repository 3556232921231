import React, { useEffect, useMemo, useRef, useState } from "react";
import { handleSetAddress, showFormErrors } from "../../../../utils/commonFunctions";
import formValidation from "../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { addLandlordAction, getLandlordAction, landlordSuggestionsAction, updateLandlordAction } from "../../../../store/actions/landlordActions";
import { debounce } from "lodash";
import { checkContactErrors } from "../../../../shared/Components/commonFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "../../../../services/auth";
import { setUserProfile } from "../../../../store/slices/userSlice";
import { setLandlordSlice } from "../../../../store/slices/landlordSlice";

const LandlordContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const [data, setData] = useState({
        id: "",
        landlordId: "",
        landlordName: "",
        landlordType: "",
        address: "",
        street: "",
        zipCode: "",
        city: "",
        cityDivision: "",
        country: "",
        email: "",
        phoneNumber: "",
        // directDebit: "",
        // furnitureProvided: "",
    })

    //bankaccounts
    // const [allBankAccounts, setAllBankAccounts] = useState([
    //     { id: null, purpose: "All Combined", owner_name: "", iban: "", bic: "", status: "" },
    // ]);

    //furniture 
    // const [allFurniture, setAllFurniture] = useState([]);

    //contacts
    const [allContacts, setAllContacts] = useState([{
        type: "",
        name: "",
        role: "",
        mobile: "",
        office_phone: "",
        email: "",
    }])

    const [landlordOptions, setLandlordOptions] = useState([])
    const [landlordAddress, setLandlordAddress] = useState()
    const [loading, setLoading] = useState(false)
    const { profile } = useSelector((state) => state?.user)
    const { landlord } = useSelector((state) => state?.landlord)
    useEffect(() => {
        if (!profile?.company) {
            let info = getUserInfo()
            dispatch(setUserProfile(info))
        }
    }, [profile])

    useEffect(() => {
        if (params?.id && profile?.company) {
            landlord ? handleLandlordInfo(landlord) : getLandlordDetail(profile?.company, params?.id);
        }
    }, [params?.id, profile, landlord]);

    useMemo(() => {
        if (landlordAddress) {
            handleSetAddress(landlordAddress, data, setData, ["longitude", "latitude"])
        }
    }, [landlordAddress])

    // useEffect(() => {
    //     if (!data?.landlord_furniture?.length) {
    //         if (data?.furnitureProvided === "yes" && allFurniture?.length === 0) {
    //             setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "" }])
    //         } else if (data?.furnitureProvided === "no") {
    //             setAllFurniture([])
    //         }
    //     }
    // }, [data])

    const getLandlordDetail = (company, id) => {
        dispatch(getLandlordAction(company, id, dispatch, (res) => {
            handleLandlordInfo(res)
        }))
    }

    const handleLandlordInfo = (info) => {
        let landlordData = {
            id: info?.id,
            landlordId: info?.landlord_id,
            landlordName: info?.landlord_name,
            landlordType: info?.type,
            address: info?.address,
            street: info?.street,
            zipCode: info?.zip_code,
            city: info?.city,
            cityDivision: info?.city_division,
            country: info?.country,
            email: info?.email,
            phoneNumber: info?.phone_number,
            // directDebit: info?.direct_debit ? "yes" : "no",
            // furnitureProvided: info?.is_furniture ? "yes" : "no",
        }

        setData(landlordData)
        // if (info?.bank_accounts?.length) {
        //     let bankAccounts = info?.bank_accounts?.map((bank) => {
        //         return { ...bank, status: "save" }
        //     })
        //     setAllBankAccounts(bankAccounts)
        // } else {
        //     setAllBankAccounts([])

        // }

        if (info?.contacts?.length) {
            let contacts = info?.contacts?.map((contact) => {
                return { ...contact, status: "save" }
            })
            setAllContacts(contacts)
        } else {
            setAllContacts([])
        }

        // if (info?.landlord_furniture?.length) {
        //     let furniture = info?.landlord_furniture?.map((item) => {
        //         return { ...item, status: "save" }
        //     })
        //     setAllFurniture(furniture)
        // } else {
        //     setAllFurniture([])
        // }
    }

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleLandlordSearch = (value) => {
        dispatch(
            landlordSuggestionsAction(value, setLandlordOptions, (error) => {
                const formErrors = ({ ["landlordId"]: error })
                setData((prev) => ({ ...prev, ["landlordId"]: value, formErrors }));
            })
        );
    };

    const landlordIdChange = useRef(debounce((value) => {
        handleLandlordSearch(value)
    }, 1000));

    const onSubmit = () => {
        // const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
        // const furniture = checkFurnitureErrors(allFurniture, setAllFurniture)
        const contacts = checkContactErrors(allContacts, setAllContacts, ["office_phone", "email"])
        if (showFormErrors(data, setData) && !contacts?.hasErrors) {
            let info = {
                ...data,
                // bank_accounts: bankAccounts?.bankAccounts,
                contacts: contacts?.contacts,
                // landlord_furniture: furniture?.furniture,
            }
            if (data?.id) {
                dispatch(
                    updateLandlordAction(
                        profile?.company,
                        data?.id,
                        info,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate(`/real-estate/landlord/view/${params?.id}`)
                            handleLandlordInfo(res)
                            dispatch(setLandlordSlice(res))
                        }
                    )
                )
            } else {
                dispatch(
                    addLandlordAction(
                        profile?.company,
                        info,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate("/real-estate/landlord/list")
                            handleLandlordInfo(res)
                            dispatch(setLandlordSlice(res))
                        }
                    )
                )
            }
        }
    }

    return {
        data,
        handleChange,
        // allBankAccounts,
        // setAllBankAccounts,
        // allFurniture,
        // setAllFurniture,
        allContacts,
        setAllContacts,
        onSubmit,
        landlordOptions,
        landlordIdChange,
        setLandlordAddress,
        navigate,
        loading
    }
}

export default LandlordContainer