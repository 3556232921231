import React from "react";
import Page from "../../../../../shared/Page";
import CustomCard from "../../../../../shared/CustomCard";
import { CustomForm, CustomInput } from "../../../../../shared/AllInputs";
import GooglePlacesInput from "../../../../../shared/GooglePlacesInput";
import HandymanContainer from "./HandymanContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../../shared/CustomButton";
import AddService from "../../../../../shared/Components/AddService";

const HandymanForms = ({ type }) => {
  const {
    header,
    data,
    handleChange,
    setSelectedAddress,
    onSubmit,
    loading,
    navigate,
    allServices,
    setAllServices,
  } = HandymanContainer();

  const FormHeader = () => {
    return( 
      <div className="flex align-items-center col-12 justify-content-between">
        <h1 className="title">{`${type} Handyman`}</h1>
        {header()}
      </div>
    )
  }
  return (
    <Page
      title={`${type} Handyman`}
      description={`Let’s ${type} your Handyman Details`}
      navigateLink={"/real-estate/maintenance/handyman/list"}
    >
      <CustomCard>
        <CustomForm col={12}  header={FormHeader()}>
          <CustomInput
            data={data}
            onChange={handleChange}
            name="name"
            required
          />
          <GooglePlacesInput
            id="property"
            data={data}
            onChange={handleChange}
            name="address"
            required
            setAddress={setSelectedAddress}
          />
        </CustomForm>
        <AddService
          title="Services"
          allValues={allServices}
          setAllValues={setAllServices}
        />
      </CustomCard>

      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined
          label="Cancel"
          onClick={() => {
            navigate("/real-estate/maintenance/handyman/list");
          }}
        />
      </Buttonlayout>
    </Page>
  );
};

export default HandymanForms;
