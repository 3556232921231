import React, { useEffect, useRef, useState } from "react";
import { CustomForm, CustomInputMask } from "../AllInputs";
import CustomButton from "../CustomButton";
import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
import { useDispatch } from "react-redux";
// import { deleteEnergyMeterAction } from "../../store/actions/buildingUnitActions";
import CustomModal from "../Modals/CustomModal";
import CustomOverlay from "../CustomOverlay";
import {
  deleteOptionMaturityAction,
  getOptionMaturityAction,
} from "../../store/actions/contractActions";
import { Toast } from "primereact/toast";

const AddOptionalMaturity = ({
  // type,
  title,
  allMaturities,
  setAllMaturities,
  // errors,
  // setErrors,
}) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const InputObj = {
    extension: "",
    notice_period: "",
    status: "",
  };

  // const errorObj = {
  //   extension: "",
  //   notice_period: "",
  // };

  const ignoreInputs = ["status", "id"];

  // useEffect(() => {
  //   if (allMaturities?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allMaturities?.map((maturity, maturityIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === maturityIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allMaturities]);

  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={
          () => HandleAddNew(InputObj, allMaturities, setAllMaturities)
          // HandleAdd(
          //   InputObj,
          //   allMaturities,
          //   setAllMaturities,
          //   errorObj,
          //   errors,
          //   setErrors
          // )
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected maturity from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteMaturity = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteOptionMaturityAction(
          idToDelete?.id,
          setLoading,
          dispatch,
          (res) => {
            handleDeleteNew(allMaturities, setAllMaturities, idToDelete?.index);
            // handleDelete(
            //   allMaturities,
            //   setAllMaturities,
            //   errors,
            //   setErrors,
            //   idToDelete?.index
            // );
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
          }
        )
      );
    } else {
      handleDeleteNew(allMaturities, setAllMaturities, idToDelete?.index);
      // handleDelete(
      //   allMaturities,
      //   setAllMaturities,
      //   errors,
      //   setErrors,
      //   idToDelete?.index
      // );
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getOptionalMaturityInfo = (id, index) => {
    dispatch(
      getOptionMaturityAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allMaturities, setAllMaturities);
          // handleCancel(
          //   info,
          //   index,
          //   allMaturities,
          //   setAllMaturities,
          //   errorObj,
          //   errors,
          //   setErrors
          // );
        }
      })
    );
  };

  return (
    <CustomForm title={title ? title : "Optional Maturity"} header={header}>
      <Toast ref={msgs} />
      <table className="financial-table w-full mt-3">
        <thead>
          <tr>
            <th>Extension</th>
            <th>Notice Period</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="financial-table-form">
          {allMaturities?.map((maturity, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <CustomInputMask
                      col={12}
                      data={maturity}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allMaturities,
                          setAllMaturities,
                          ignoreInputs
                        );
                        // handleObjChange(
                        //   allMaturities,
                        //   setAllMaturities,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                      name="extension"
                      spanLabel={"Months"}
                      ignoreLabel
                      disabled={maturity?.status === "save" ? true : false}
                      max={9}
                    />
                  </td>
                  <td>
                    <CustomInputMask
                      col={12}
                      data={maturity}
                      onChange={
                        (e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allMaturities,
                            setAllMaturities,
                            ignoreInputs
                          )
                        // handleObjChange(
                        //   allMaturities,
                        //   setAllMaturities,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // )
                      }
                      name="notice_period"
                      ignoreLabel
                      spanLabel={"Months"}
                      max={9}
                      disabled={maturity?.status === "save" ? true : false}
                    />
                  </td>
                  {maturity?.status === "save" ? (
                    <td>
                      <CustomOverlay ref={openAction}>
                        <ul className="no-style p-0">
                          {/* {type == "Edit" && ( */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() =>
                              handlEdit(allMaturities, setAllMaturities, index)
                            }
                          >
                            <i className="pi pi-file-edit text-dark"></i>
                            Edit
                          </li>
                          {/* )} */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() => {
                              setIdToDelete({ id: maturity?.id, index: index });
                              setDeleteModal(true);
                            }}
                          >
                            <i className="pi pi-trash cursor-pointer text-dark"></i>
                            Delete
                          </li>
                        </ul>
                      </CustomOverlay>
                    </td>
                  ) : (
                    <td>
                      <div className="d-flex gap-3">
                        <a
                          className="cursor-pointer"
                          onClick={
                            () =>
                              handleSaveNew(
                                index,
                                allMaturities,
                                setAllMaturities,
                                ignoreInputs
                              )
                            // handleSave(
                            //   allMaturities,
                            //   setAllMaturities,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   index
                            // )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                              fill="#787878"
                            ></path>
                          </svg>
                        </a>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            if (maturity?.id) {
                              getOptionalMaturityInfo(maturity?.id, index);
                            } else {
                              handleDeleteNew(
                                allMaturities,
                                setAllMaturities,
                                index
                              );
                              // handleDelete(
                              //   allMaturities,
                              //   setAllMaturities,
                              //   errors,
                              //   setErrors,
                              //   index
                              // );
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#787878"
                              d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </td>
                  )}
                </tr>

                {/* {(errors[index]?.extension || errors[index]?.notice_period) && (
                  <tr>
                    <td>
                      <small className="p-error">
                        {errors[index]?.extension}
                      </small>
                    </td>
                    <td>
                      <small className="p-error">
                        {errors[index]?.notice_period}
                      </small>
                    </td>
                  </tr>
                )} */}
                <CustomModal
                  isOpen={deleteModal}
                  setIsOpen={setDeleteModal}
                  heading={"Delete"}
                  body={modalContent}
                  submitLabel={"Confirm"}
                  onSubmit={handleDeleteMaturity}
                  loading={loading}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
    </CustomForm>
  );
};

export default AddOptionalMaturity;
