import React, { useEffect, useRef } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import {
    CustomForm,
    CustomInput,
} from "../../../../shared/AllInputs";
import { Messages } from "primereact/messages";
import CustomButton, {
    Buttonlayout,
} from "../../../../shared/CustomButton";
import RolesPanel from "./RolesPanel";
import RolesContainer from "./RolesContainer";
import { Toast } from "primereact/toast";

export default function RoleForm({ type }) {
    const msgs = useRef(null);
    const {
        data,
        handleChange,
        onSubmit,
        loading,
        msgToShow,
        header,
        roles,
        keys,
        setRoles,

    } = RolesContainer();

    useEffect(() => {
        if (msgToShow?.message) {
            msgs?.current?.show({
                severity: msgToShow?.type,
                detail: msgToShow?.message,
            });
        }
    }, [msgToShow]);



    return (
        <Page title="Add Role" description="Let’s Add a Role">
            <CustomCard title="Role">
                {/* <Messages className="w-full" ref={msgs} /> */}
                <Toast ref={msgs} />
                <CustomForm>
                    <CustomInput col={6} extraClassName="p-0 mt-2" data={data} onChange={handleChange} name="roleName" required />
                </CustomForm>
            </CustomCard>
            <CustomCard title="Security Permission" header={header()}>
                <br />
                <div className="grid permission-list">
                    <div className="col-12 md:col-4">
                        <div className="bg-lightest-main py-3 px-2 border-round-lg">
                            <p className="font-semibold fs-6 px-3 mb-3">Real Estate</p>
                            <div
                                className="accordion add-role-accordion"
                                id="accordionExample"
                            >
                                {roles[keys?.realEsate] &&
                                    Object.keys(roles[keys?.realEsate]).map((parent, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <RolesPanel
                                                    parentKey={keys?.realEsate}
                                                    parent={parent}
                                                    roles={roles}
                                                    setRoles={setRoles}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="bg-lightest-main py-3 px-2 border-round-lg mb-3">
                            <p className="font-semibold fs-6 px-3 mb-3">Tenant</p>
                            <div
                                className="accordion add-role-accordion"
                                id="accordionExample"
                            >
                                {roles[keys?.tenant] &&
                                    Object.keys(roles[keys?.tenant]).map((parent, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <RolesPanel
                                                    parentKey={keys?.tenant}
                                                    parent={parent}
                                                    roles={roles}
                                                    setRoles={setRoles}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="bg-lightest-main py-3 px-2 border-round-lg">
                            <p className="font-semibold fs-6 px-3 mb-3">Settings</p>
                            <div
                                className="accordion add-role-accordion"
                                id="accordionExample"
                            >
                                {roles[keys?.settings] &&
                                    Object.keys(roles[keys?.settings]).map((parent, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <RolesPanel
                                                    parentKey={keys?.settings}
                                                    parent={parent}
                                                    roles={roles}
                                                    setRoles={setRoles}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="bg-lightest-main py-3 px-2 border-round-lg">
                            <p className="font-semibold fs-6 px-3 mb-3">Administrator</p>
                            <div
                                className="accordion add-role-accordion"
                                id="accordionExample"
                            >
                                {roles[keys?.administrator] &&
                                    Object.keys(roles[keys?.administrator]).map(
                                        (parent, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <RolesPanel
                                                        parentKey={keys?.administrator}
                                                        parent={parent}
                                                        roles={roles}
                                                        setRoles={setRoles}
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>

                </div>
            </CustomCard>
            <Buttonlayout>
                <CustomButton onClick={onSubmit} label="Save" loading={loading} />
                <CustomButton label="Cancel" />
            </Buttonlayout>
        </Page>
    );
}
