import React, { useEffect, useState } from "react";
import { CustomDropDown, CustomForm } from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { PositionOptions } from "../PositionsList";
import { getEmployeesListAction } from "../../store/actions/EmployeeActions";
import { getUserInfo } from "../../services/auth";
import {
  HandleAddNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
const AddExistingOfficeEmployee = ({ allEmployees, setAllEmployees }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const ignoreInputs = ["status", "id"];
  const InputObj = {
    id: "",
    position: "",
    employee: "",
    status: "",
  };
  const [employeesList, setEmployeesList] = useState([]);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);

    if (info?.company) {
      getEmployeesList(info?.company);
    }
  }, [profile]);

  const getEmployeesList = (company) => {
    dispatch(
      getEmployeesListAction(company, (res) => {
        if (res?.success) {
          let options = res?.data.results?.map((employee) => {
            return {
              value: employee?.id,
              name: `${employee?.user?.first_name} ${employee?.user?.last_name}`,
            };
          });
          setEmployeesList(options);
        }
      })
    );
  };

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() => HandleAddNew(InputObj, allEmployees, setAllEmployees)}
      />
    </div>
  );

  return (
    <>
      <CustomForm title={"Add Existing Office Employee"} header={header}>
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Select Position</th>
              <th>Select Employee</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allEmployees?.map((emp, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        data={emp}
                        onChange={(e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allEmployees,
                            setAllEmployees,
                            ignoreInputs
                          )
                        }
                        name="position"
                        col={12}
                        options={PositionOptions}
                        disabled={emp?.status === "save" ? true : false}
                        placeholder="Select Position"
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        data={emp}
                        onChange={(e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allEmployees,
                            setAllEmployees,
                            ignoreInputs
                          )
                        }
                        name="employee"
                        col={12}
                        options={employeesList}
                        disabled={emp?.status === "save" ? true : false}
                        placeholder="Select Employee"
                      />
                    </td>
                    {emp?.status === "save" ? (
                      <td>
                        <i
                          className="pi pi-ellipsis-v cursor-pointer text-dark"
                          onClick={() =>
                            handlEdit(allEmployees, setAllEmployees, index)
                          }
                        ></i>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              handleSaveNew(
                                index,
                                allEmployees,
                                setAllEmployees,
                                ignoreInputs
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              handleDeleteNew(
                                allEmployees,
                                setAllEmployees,
                                index
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
    </>
  );
};

export default AddExistingOfficeEmployee;
