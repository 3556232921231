import React, { useEffect, useRef, useState } from "react";
import { CustomDropDown, CustomForm } from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
// import { Toast } from "primereact/toast";
import {
  FurnitureBathopt,
  FurnitureKitchenOpt,
  FurnitureOtheropt,
  FurnitureType,
} from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import { useDispatch } from "react-redux";
import {
  deleteFurnitureAction,
  getFurnitureAction,
} from "../../store/actions/landlordActions";
const AddFurniture = ({
  title,
  allValues,
  setAllValues,
  // errors,
  // setErrors,
}) => {
  // const msgs = useRef(null);
  const openAction = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const InputObj = {
    type: "Kitchen",
    item: "",
    status: "",
  };

  // const errorObj = {
  //   type: "",
  //   item: "",
  // };

  const ignoreInputs = ["status", "id"];
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (allValues?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allValues?.map((room, roomIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === roomIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allValues]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={
          () => HandleAddNew(InputObj, allValues, setAllValues)
          // HandleAdd(
          //   InputObj,
          //   allValues,
          //   setAllValues,
          //   errorObj,
          //   errors,
          //   setErrors
          // )
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected furniture from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleFurnitureDelete = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteFurnitureAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allValues, setAllValues, idToDelete?.index);
          // handleDelete(
          //   allValues,
          //   setAllValues,
          //   errors,
          //   setErrors,
          //   idToDelete?.index
          // );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allValues, setAllValues, idToDelete?.index);
      // handleDelete(
      //   allValues,
      //   setAllValues,
      //   errors,
      //   setErrors,
      //   idToDelete?.index
      // );
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getFunrnitureInfo = (id, index) => {
    dispatch(
      getFurnitureAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allValues, setAllValues);
          // handleCancel(
          //   info,
          //   index,
          //   allValues,
          //   setAllValues,
          //   errorObj,
          //   errors,
          //   setErrors
          // );
        }
      })
    );
  };

  return (
    <>
      <CustomForm title={title} header={header}>
        {/* <Toast ref={msgs} /> */}
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Type</th>
              <th>Item</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allValues?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        value={item?.type}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allValues,
                          //   setAllValues,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="type"
                        col={12}
                        options={FurnitureType}
                        disabled={item?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={item?.formErrors?.type}
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={item?.item}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          // handleObjChange(
                          //   allValues,
                          //   setAllValues,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="item"
                        col={12}
                        options={
                          item?.type === "Kitchen"
                            ? FurnitureKitchenOpt
                            : item.type === "Bath"
                            ? FurnitureBathopt
                            : FurnitureOtheropt
                        }
                        disabled={item?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={item?.formErrors?.item}
                      />
                    </td>
                    {item?.status === "save" ? (
                      <td>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(allValues, setAllValues, index)
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: item?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={
                              () =>
                                handleSaveNew(
                                  index,
                                  allValues,
                                  setAllValues,
                                  ignoreInputs
                                )
                              // handleSave(
                              //   allValues,
                              //   setAllValues,
                              //   errors,
                              //   setErrors,
                              //   ignoreInputs,
                              //   index
                              // )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (item.id) {
                                getFunrnitureInfo(item.id, index);
                              } else {
                                handleDeleteNew(
                                  allValues,
                                  setAllValues,
                                  index
                                );
                                // handleDelete(
                                //   allValues,
                                //   setAllValues,
                                //   errors,
                                //   setErrors,
                                //   index
                                // );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                  {/* {(item?.formErrors?.type || item?.formErrors?.item) && (
                    <tr>
                      <td>
                        <small className="p-error">
                          {item?.formErrors?.type}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {item?.formErrors?.item}
                        </small>
                      </td>
                    </tr>
                  )} */}
                  {/* {(errors[index]?.type || errors[index]?.item) && (
                    <tr>
                      <td>
                        <small className="p-error">{errors[index]?.type}</small>
                      </td>
                      <td>
                        <small className="p-error">{errors[index]?.item}</small>
                      </td>
                    </tr>
                  )} */}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleFurnitureDelete}
        loading={loading}
      />
    </>
  );
};

export default AddFurniture;
