import React, { useRef, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "../../utils/validations";
import CustomOverlay from "../../shared/CustomOverlay";
import CustomModal from "../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "../../shared/Components/commonFunctions";
import { Toast } from "primereact/toast";
import {
  ContactOptions,
  ContactRoleOptions,
} from "../../shared/AllDropDownOptions";
import {
  deleteContactAction,
  getLandlordAction,
  getPropertyLandlordAction,
  updateContactAction,
} from "../../store/actions/landlordActions";
import { useParams } from "react-router-dom";

export default function CustomLandlordContactTable({
  action,
  id,
  title,
  allContacts,
  setAllContacts,
}) {
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user);

  const params = useParams();

  const TypeEditor = (options) => {
    const formErrors = formValidation("type", options.value, allContacts);
    return (
      <CustomDropDown
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        options={ContactOptions}
        name="type"
        ignoreLabel
        errorMessage={formErrors["type"]}
      />
    );
  };

  const NameEditor = (options) => {
    const formErrors = formValidation("name", options.value, allContacts);
    return (
      <CustomInput
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="name"
        ignoreLabel
        errorMessage={formErrors["name"]}
      />
    );
  };

  const RoleEditor = (options) => {
    const formErrors = formValidation("role", options.value, allContacts);
    return (
      <CustomDropDown
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        options={ContactRoleOptions}
        name="role"
        ignoreLabel
        errorMessage={formErrors["role"]}
      />
    );
  };

  const MobileEditor = (options) => {
    const formErrors = formValidation("mobile", options?.value, allContacts);
    return (
      <CustomPhoneInput
        data={options?.rowData}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          options.editorCallback(e?.value || "");
        }}
        name="mobile"
        ignoreLabel
        errorMessage={formErrors["mobile"]}
      />
    );
  };

  const OfficePhoneEditor = (options) => {
    const formErrors = formValidation(
      "office_phone",
      options.value,
      allContacts
    );
    return (
      <CustomPhoneInput
        data={options?.rowData}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          options.editorCallback(e.value);
        }}
        name="office_phone"
        ignoreLabel
        errorMessage={formErrors["office_phone"]}
      />
    );
  };

  const EmailEditor = (options) => {
    const formErrors = formValidation("email", options.value, allContacts);
    return (
      <CustomInput
        value={options.value}
        extraClassName="w-full"
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="email"
        ignoreLabel
        errorMessage={formErrors["email"]}
      />
    );
  };

  const rowEditValidator = async (rowData, options) => {
    const { type, name, role, mobile, office_phone, email } = rowData;
    const typeError = formValidation("type", type, allContacts, [
      "office_phone",
      "email",
    ]);
    const nameError = formValidation("name", name, allContacts, [
      "office_phone",
      "email",
    ]);
    const roleError = formValidation("role", role, allContacts, [
      "office_phone",
      "email",
    ]);
    const mobileError = formValidation("mobile", mobile, allContacts, [
      "office_phone",
      "email",
    ]);

    if (
      !typeError["type"] &&
      !nameError["name"] &&
      !roleError["role"] &&
      !mobileError["mobile"]
    ) {
      let payload = {
        type: type,
        name: name,
        role: role,
        mobile: mobile,
        office_phone: office_phone,
        email: email,
        landlord: id,
      };
      dispatch(
        updateContactAction(rowData?.id, payload, dispatch, (res) => {
          let contacts = [...allContacts];
          contacts[options.rowIndex] = rowData;
          setAllContacts(contacts);
          setEditingRows({});
          handleGetInfo();
          return true;
        })
      );
    } else {
      return false;
    }
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                  fill="#787878"
                ></path>
              </svg>
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#787878"
                  d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                ></path>
              </svg>
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected contact from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleDeleteContact = () => {
    setLoading(true);
    dispatch(
      deleteContactAction(idToDelete?.id, setLoading, dispatch, (res) => {
        handleDelete(allContacts, setAllContacts, "", "", idToDelete?.index);
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
        handleGetInfo();
      })
    );
  };

  const handleGetInfo = () => {
    if (action === "property") {
      dispatch(getPropertyLandlordAction(params?.id, dispatch));
    } else if (action === "landlord") {
      dispatch(getLandlordAction(profile?.company, params?.id, dispatch));
    }
  };

  return (
    <CustomForm title={title}>
      <Toast ref={msgs} />
      <DataTable
        className="col-12"
        value={allContacts}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "100rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="type"
          header="Type"
          editor={(options) => TypeEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="name"
          header="Name"
          editor={(options) => NameEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="role"
          header="Role"
          editor={(options) => RoleEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="mobile"
          header="Mobile"
          editor={(options) => MobileEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="office_phone"
          header="Office Phone"
          editor={(options) => OfficePhoneEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="email"
          header="Email"
          editor={(options) => EmailEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteContact}
        loading={loading}
      />
    </CustomForm>
  );
}
