import React, { useRef } from "react";
import { CustomForm, CustomRadioInput } from "../../shared/AllInputs";
import {
  CustomAmountViewInput,
  CustomNumberViewInput,
  CustomViewInput,
} from "../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/icons/editicon.png";
// import CommonViewTable from "../Tables/CommonViewTable";
// import { Messages } from "primereact/messages";
import {
  // convertAmountInGerman,
  handleFormatDate,
} from "../../utils/commonFunctions";
import CustomExtraCostTable from "../Tables/CustomExtraCostTable";

const ViewRentandCost = ({
  title,
  type, 
  isEditable,
  contractId,
  data,
  allExtraCost,
  setAllExtraCost,
}) => {
  const params = useParams()
  const navigate = useNavigate();

  // const costColumns = useMemo(
  //   () => [
  //     {
  //       name: "Type",
  //       accessor: "type",
  //     },
  //     {
  //       name: "Payment Type",
  //       accessor: "payment_type",
  //     },
  //     {
  //       name: "Amount",
  //       accessor: "amount",
  //       body: (rowData) => (
  //         <span className="lowercase-text">
  //           {convertAmountInGerman(rowData.amount)}
  //         </span>
  //       ),
  //     },
  //     {
  //       name: "Amount First Month",
  //       accessor: "amount_first_month",
  //       body: (rowData) => (
  //         <span className="lowercase-text">
  //           {convertAmountInGerman(rowData.amount_first_month)}
  //         </span>
  //       ),
  //     },
  //     {
  //       name: "Amount Last Month",
  //       accessor: "amount_last_month",
  //       body: (rowData) => (
  //         <span className="lowercase-text">
  //           {convertAmountInGerman(rowData.amount_last_month)}
  //         </span>
  //       ),
  //     },
  //     {
  //       name: "Application for tenant Rent",
  //       accessor: "applicable_tenant_rent",
  //     },
  //   ],
  //   []
  // );

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/property/edit/rent-and-cost/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm title={title || "Contractual Rent"} header={isEditable? header() : null}>
        <CustomAmountViewInput
          name="amount"
          data={data}
          icon={<i className="pi pi-euro text-xs"> </i>}
        />
        <CustomAmountViewInput
          name="amountFirstMonth"
          data={data}
          icon={<i className="pi pi-euro text-xs"> </i>}
        />
      </CustomForm>

      <CustomForm title="Automatic Rent Increment">
        <CustomViewInput name="rentRiseType" data={data} />
      </CustomForm>
      <CustomForm>
        {data?.rentRiseType === "None" ||
        data?.rentRiseType === "null" ? null : (
          <>
            <CustomViewInput
              name="intervalType"
              data={data}
              label={"Rent Rise Interval Type"}
            />
            <CustomViewInput
              name="riseInterval"
              data={data}
              label={"Rent Rise Interval"}
            />
            <CustomNumberViewInput
              name="startDate"
              value={handleFormatDate(data?.startDate)}
              label={"Date of First Rise"}
            />

            <CustomAmountViewInput
              name="riseAmount"
              data={data}
              label={"Rent Rise Amount"}
            />
            <CustomNumberViewInput
              name="endDate"
              value={handleFormatDate(data?.endDate)}
              label={"Date of Last Rise"}
            />
          </>
        )}
      </CustomForm>
      {/* <CommonViewTable
        title={"Extra Cost"}
        list={allExtraCost}
        columns={costColumns}
        actions={actions}
      /> */}
      <CustomExtraCostTable
        action={type}
        id={contractId}
        title={"Extra Cost"}
        allExtraCost={allExtraCost}
        setAllExtraCost={setAllExtraCost}
      />
      {data?.propertyRent && <CustomForm title={"Property Rent"}>
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Size"
          label={<div className="inline-block">By Size</div>}
          checked={data?.propertyRent === "Size"}
        />
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Room"
          label={<div className="inline-block">By rooms</div>}
          checked={data?.propertyRent === "Room"}
        />
        <CustomRadioInput
          disabled={true}
          extraClassName="w-25rem"
          inputClass="mr-2"
          name="propertyRent"
          value="Manually"
          label={<div className="inline-block">Manually Enter</div>}
          checked={data?.propertyRent === "Manually"}
        />
      </CustomForm>}
    </>
  );
};

export default ViewRentandCost;
