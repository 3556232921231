import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { debounce } from "lodash";
import CustomCard from "../../../../shared/CustomCard";
import CustomModal from "../../../../shared/Modals/CustomModal";
import { getUserInfo } from "../../../../services/auth";
import { setUserProfile } from "../../../../store/slices/userSlice";
import {
  CustomCalander,
  CustomDropDown,
  CustomInput,
  CustomSearch,
} from "../../../../shared/AllInputs";
import CustomOverlay from "../../../../shared/CustomOverlay";
import CustomTableContainer from "../../../Tables/CustomTableContainer";
import { deleteDamageReportAction } from "../../../../store/actions/damageActions";
import {
  damageCauseopt,
  maintenanceReqType,
} from "../../../../shared/AllDropDownOptions";
import { getMaintenanceReqListAction } from "../../../../store/actions/maintenanceActions";
import { getAllHandymanServiceListAction } from "../../../../store/actions/handymanServiceAction";
import { setMaintenanceReqSlice } from "../../../../store/slices/maintenanceSlice";

const MaintenaceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalFilters = [
    "reportedDate",
    "status",
    "property_id",
    "damage_cause",
    "damage_type",
  ];
  const [maintenanceList, setMaintenanceList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    reportedDate: "",
    status: "",
    property_id: "",
    damage_cause: "",
    damage_type: "",
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { maintenanceReq } = useSelector((state) => state?.maintenanceReq);
  const [serviceList, setServicesList] = useState([]);
  const [selectedItems, setSelectedItems] = useState();

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.company) {
      getServiceList();
    }
  }, [profile]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex align-items-center justify-content-end flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                search,
                filters?.status,
                filters?.property_id,
                filters?.damage_cause,
                filters?.damage_type
              );
            }}
            isSearch={isSearch}
            placeholder="Search Maintenance"
          />
        </div>
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-upload text-dark"></i> Export
            </li>
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const StatusFilter = (options) => {
    return (
      <CustomDropDown
        data={filters}
        name="status"
        options={maintenanceReqType}
        onChange={({ value }) => {
          setFilters((prev) => ({ ...prev, status: value }));
        }}
        optionLabel="name"
        placeholder="Type of Status"
        className="p-column-filter"
        colors
      />
    );
  };

  const DamageCauseFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={filters?.damage_cause}
          options={damageCauseopt}
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, damage_cause: e?.value }));
          }}
          optionLabel="name"
          placeholder="Type of Damage Cause"
          className="p-column-filter"
        />
      </div>
    );
  };

  const DamageTypeFilter = (options, list) => {
    return (
      <div>
        <Dropdown
          value={filters?.damage_type}
          options={list}
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, damage_type: e?.value }));
          }}
          optionLabel="name"
          placeholder="Type of Damage"
          className="p-column-filter"
          name="damage_type"
        />
      </div>
    );
  };

  const PropertyIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={filters?.property_id}
          name="propertyID"
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, property_id: e?.value }));
          }}
          optionLabel="name"
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Initiated":
        return "text-red-600";

      case "Back Office":
        return "text-yellow-500";

      case "Social Worker":
        return "text-blue-600";

      case "Follow-up":
        return "text-orange-500";

      case "Fixed":
        return "text-green-600";

      case "Handyman":
        return "text-cyan-500";

      default:
        return "";
    }
  };

  const ReportedDateFilter = (options) => {
    return (
      <div>
        <CustomCalander
          col={12}
          value={filters.reportedDate}
          name="name"
          onChange={({ value }) => {
            // options.filterCallback(value, options.index);
            setFilters((prev) => ({
              ...prev,
              reportedDate: value,
            }));
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Reported Date",
        accessor: "reportedDate",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        dataType: "date",
        // body: (options) => dateBodyTemplate(options),
        filterElement: (option) => ReportedDateFilter(option),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        filter: true,
        body: (options) => statusBodyTemplate(options),
        filterElement: (option) => StatusFilter(option),
      },
      {
        name: "Property ID",
        accessor: "property_id",
        value: true,
        filter: true,
        filterElement: (options) => PropertyIDFilter(options),
      },
      {
        name: "Damage Cause",
        accessor: "damage_cause",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => DamageCauseFilter(options),
      },
      {
        name: "Damage Type",
        accessor: "damage_type",
        sortable: false,
        value: true,
        filter: true,
        body: (options) => options?.damage_type?.name,
        filterElement: (options) => DamageTypeFilter(options, serviceList),
      },
      {
        name: "Landlord Name",
        accessor: "landlord_name",
        sortable: false,
        value: true,
      },
    ],
    [serviceList, filters, maintenanceList]
  );

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/maintenance/view/${d.id}`);
              dispatch(setMaintenanceReqSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {/* <li
            className="flex gap-2 text-xs font-medium mb-3"
            onClick={() => {
              setIdToDelete(d?.id);
              setDeleteModal(true);
            }}
          >
            <i className="pi pi-trash cursor-pointer text-dark"></i> Delete
          </li> */}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected maintenance request from
        the list? This action cannot be undone.
      </span>
    );
  };

  const handleMaintenanceReqDelete = () => {
    dispatch(
      deleteDamageReportAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getMaintenanceReqList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} /> */}
          {/* <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const getMaintenanceReqList = (
    company,
    rows,
    page,
    search,
    status,
    propertyId,
    damageCause,
    damageType,
    reportedDate
  ) => {
    dispatch(
      getMaintenanceReqListAction(
        company,
        rows,
        page,
        search,
        status,
        propertyId,
        damageCause,
        damageType,
        reportedDate,
        (res) => {
          setMaintenanceList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(`/real-estate/maintenance/list?page=${page}&rows=${rows}`);
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    status,
    propertyId,
    damageCause,
    damageType,
    reportedDate
  ) => {
    setIsSearch(true);
    getMaintenanceReqList(
      company,
      rows,
      page,
      search,
      status,
      propertyId,
      damageCause,
      damageType,
      reportedDate
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getMaintenanceReqList(
        profile?.company,
        rows,
        page,
        "",
        filters?.status,
        filters?.property_id,
        filters?.damage_cause,
        filters?.damage_type,
        filters?.reportedDate
      );
    }
  }, [
    profile?.company,
    rows,
    page,
    first,
    // filters?.status?.value,
    // filters?.property_id?.value,
    // filters?.damage_cause?.value,
    // filters?.damage_type?.value,
    // filters?.reportedDate?.value
  ]);

  const onPageChange = (e) => {
    navigate(`/real-estate/maintenance/list?page=${e.page + 1}&rows=${e.rows}`);
    setFirst(e.first);
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const applyFilters = (options) => {
    getMaintenanceReqList(
      profile?.company,
      rows,
      page,
      "",
      filters?.status,
      filters?.property_id,
      filters?.damage_cause,
      filters?.damage_type,
      filters?.reportedDate
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getMaintenanceReqList(
      profile?.company,
      rows,
      page,
      "",
      field === "status" ? "" : filters?.status,
      field === "property_id" ? "" : filters?.property_id,
      field === "damage_cause" ? "" : filters?.damage_cause,
      field === "damage_type" ? "" : filters?.damage_type,
      field === "reportedDate" ? "" : filters?.reportedDate
    );
  };

  return (
    <div>
      <CustomCard title="Maintenance Request" header={header()}>
        <CustomTableContainer
          list={maintenanceList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={maintenanceReq?.id}
          slice={setMaintenanceReqSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleMaintenanceReqDelete}
        loading={loading}
      />
    </div>
  );
};

export default MaintenaceList;
