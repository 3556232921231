import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomViewInput } from "../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/icons/editicon.png";
import CustomRoomTable from "../Tables/CustomRoomTable";
import CustomEnergyTable from "../Tables/CustomEnergyTable";

const ViewBuildingUnit = ({
  data,
  allRooms,
  setAllRooms,
  allEnergyMeters,
  setAllEnergyMeters,
}) => {
  const params = useParams()
  const navigate = useNavigate();
  // const msgs = useRef(null);
  // const dispatch = useDispatch();
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  // const roomColumns = useMemo(
  //   () => [
  //     {
  //       name: "Room ID",
  //       accessor: "srNo",
  //     },
  //     {
  //       name: "Doors",
  //       accessor: "doors",
  //     },
  //     {
  //       name: "Size",
  //       accessor: "size",
  //       body: (rowData) => (
  //         <span className="lowercase-text">
  //           {rowData.size} m<sup>2</sup>
  //         </span>
  //       ),
  //     },
  //     {
  //       name: "Name",
  //       accessor: "name",
  //     },
  //   ],
  //   []
  // );

  // const meterColumns = useMemo(
  //   () => [
  //     {
  //       name: "Purpose",
  //       accessor: "purpose",
  //     },
  //     {
  //       name: "Type",
  //       accessor: "type",
  //     },
  //     {
  //       name: "Meter ID",
  //       accessor: "meter_id",
  //     },
  //     {
  //       name: "Location",
  //       accessor: "location",
  //     },
  //     {
  //       name: "Access",
  //       accessor: "access",
  //     },
  //     {
  //       name: "Amount",
  //       accessor: "amount",
  //       body: (rowData) => {
  //         return rowData?.type == "Gas" ? (
  //           <span className="lowercase-text">
  //             {rowData.amount ? (
  //               <>
  //                 {convertAmountInGerman(rowData.amount)}
  //                 <span
  //                   dangerouslySetInnerHTML={{ __html: " m<sup>3</sup>" }}
  //                 />
  //               </>
  //             ) : (
  //               "-----"
  //             )}
  //           </span>
  //         ) : (
  //           <span className="lowercase-text">
  //             {rowData.amount
  //               ? convertAmountInGerman(rowData.amount) + " kWh"
  //               : "-----"}
  //           </span>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/property/edit/building-unit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  // const actions = (d, action) => {
  //   return (
  //     <CustomOverlay>
  //       <ul className="no-style p-0">
  //         <li
  //           className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
  //           // onClick={() => {
  //           //   navigate(`/real-estate/property/view/building-structure/${d.id}`);
  //           // }}
  //         >
  //           <i className="pi pi-file-edit text-dark"></i>
  //           Edit
  //         </li>
  //         <li className="flex gap-2 text-xs font-medium mb-3">
  //           <i
  //             className="pi pi-trash cursor-pointer text-dark"
  //             onClick={() => {
  //               setIdToDelete(d?.id);
  //               setDeleteModal(true);
  //             }}
  //           ></i>{" "}
  //           Delete
  //         </li>
  //       </ul>
  //     </CustomOverlay>
  //   );
  // };

  // const modalContent = () => {
  //   return (
  //     <span>
  //       Are you sure you want to delete the selected energy meter from the list?
  //       This action cannot be undone.
  //     </span>
  //   );
  // };

  // const handleDeleteRoom = () => {
  //   setLoading(true);
  //   dispatch(
  //     deleteRoomAction(idToDelete?.id, (res) => {
  //       if (res?.success) {
  //         msgs?.current?.show({
  //           severity: "success",
  //           detail: res?.message,
  //         });
  //         setIdToDelete({ id: "", index: "" });
  //       } else {
  //         msgs?.current?.show({
  //           severity: "error",
  //           detail: res?.message,
  //         });
  //         setIdToDelete({ id: "", index: "" });
  //       }
  //       setLoading(false);
  //       setDeleteModal(false);
  //     })
  //   );
  // };

  return (
    <>
      <CustomForm title="Building Unit" header={header()}>
        {/* <CustomViewInput name="property" data={data} /> */}
        {/* <CustomViewInput name="name" data={data} /> */}
        <CustomViewInput name="unitType" data={data} />
        <CustomViewInput name="location" data={data} />
        <CustomViewInput name="floor" data={data} label={"Floor No"} />
        <CustomViewInput name="waterHeating" data={data} />
        <CustomViewInput name="size" data={data} label={"Size"} />
        <CustomViewInput name="heatingTechnology" data={data} />
      </CustomForm>
      <CustomRoomTable
        id={data?.id}
        maxSize={data?.size}
        title={"Room"}
        allRooms={allRooms}
        setAllRooms={setAllRooms}
      />
      <CustomEnergyTable
        id={data?.id}
        title={"Energy Meters"}
        allEnergyMeters={allEnergyMeters}
        setAllEnergyMeters={setAllEnergyMeters}
      />
      {/* <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteRoom}
        loading={loading}
      /> */}
    </>
  );
};

export default React.memo(ViewBuildingUnit);
