import React, { useState, useRef } from "react";
import {
  CustomForm,
  CustomInput,
  CustomNumberInput2,
  CustomSizeM2,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleDeleteNew,
  handlEdit,
  handleRoomChange,
  // handleRoomSizeError,
  handleSaveRoom,
} from "./commonFunctions";
import { Toast } from "primereact/toast";
import {
  deleteRoomAction,
  getRoomAction,
} from "../../store/actions/buildingUnitActions";
import { useDispatch } from "react-redux";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
// import formValidation from "../../utils/validations";

const AddRooms = ({
  // type,
  allRooms,
  setAllRooms,
  // errors,
  // setErrors,
  maxSize,
}) => {
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const roomInputObj = {
    id: "",
    doors: 1,
    size: "",
    name: "",
    status: "",
  };

  // const errorObj = {
  //   doors: "",
  //   size: "",
  // };

  const ignoreInputs = ["status", "id"];
  const optional = ["name"];
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy meter from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteRoom = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteRoomAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(allRooms, setAllRooms, idToDelete?.index);
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allRooms, setAllRooms, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getRoomDetails = (id, index) => {
    dispatch(
      getRoomAction(id, (res) => {
        const info = res?.data?.results;
        if (res?.success) {
          handleCancelNew(info, index, allRooms, setAllRooms);
        }
      })
    );
  };

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(roomInputObj, allRooms, setAllRooms)}
      />
    </div>
  );

  return (
    <>
      <CustomForm title={"Room"} header={header}>
        <Toast ref={msgs} />
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Room ID</th>
              <th>Doors</th>
              <th>Size </th>
              <th>Name (optional)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allRooms?.map((room, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                    <td>
                      <CustomNumberInput2
                        value={room?.doors}
                        col={12}
                        max={9}
                        onChange={(e) =>
                          handleRoomChange(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allRooms,
                            setAllRooms,
                            ignoreInputs,
                            optional,
                            maxSize
                          )
                        }
                        name="doors"
                        disabled={room?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomSizeM2
                        value={room?.size}
                        col={12}
                        max={9}
                        onChange={(e) => {
                          handleRoomChange(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allRooms,
                            setAllRooms,
                            ignoreInputs,
                            optional,
                            maxSize
                          );
                        }}
                        name="size"
                        disabled={room?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomInput
                        value={room?.name}
                        col={12}
                        onChange={(e) =>
                          handleRoomChange(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allRooms,
                            setAllRooms,
                            ignoreInputs,
                            optional,
                            maxSize
                          )
                        }
                        name="name"
                        disabled={room?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    {room?.status === "save" ? (
                      <td>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            {/* {type === "Edit" && ( */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(allRooms, setAllRooms, index)
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            {/* )} */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: room?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              handleSaveRoom(
                                index,
                                allRooms,
                                setAllRooms,
                                ignoreInputs,
                                optional,
                                maxSize
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (room.id) {
                                getRoomDetails(room.id, index);
                              } else {
                                handleDeleteNew(allRooms, setAllRooms, index);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                  {(room?.formErrors?.doors ||
                    room?.formErrors?.size ||
                    room?.formErrors?.name) && (
                    <tr>
                      <td></td>
                      <td>
                        <small className="p-error">
                          {room?.formErrors?.doors}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {room?.formErrors?.size}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {room?.formErrors?.name}
                        </small>
                      </td>
                    </tr>
                  )}
                  <CustomModal
                    isOpen={deleteModal}
                    setIsOpen={setDeleteModal}
                    heading={"Delete"}
                    body={modalContent}
                    submitLabel={"Confirm"}
                    onSubmit={handleDeleteRoom}
                    loading={loading}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
    </>
  );
};

export default AddRooms;
