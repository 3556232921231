import React from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import {
  CustomDropDown,
  CustomForm,
  CustomNumberInput2,
  CustomSizeM2,
} from "../../../../shared/AllInputs";
import GooglePlacesInput from "../../../../shared/GooglePlacesInput";
import BuildingCotainer from "./BuildingContainer";
import { BuildingTypeOptions, StairAccessOptions } from "../../../../shared/AllDropDownOptions";
import CustomButton, { Buttonlayout, CustomButtonOutlined } from "../../../../shared/CustomButton";

const BuildingForm = ({ type }) => {
  const { data, handleChange, setSelectedAddress, onSubmit, loading, navigate } = BuildingCotainer();

  return (
    <Page
      title={`${type} building`}
      description={`Let’s ${type} your building Details`}
        navigateLink={"/real-estate/property/building/list"}
    >
      <CustomCard>
        <CustomForm title="Building">
          <GooglePlacesInput
            id="property"
            data={data}
            onChange={handleChange}
            name="address"
            required
            setAddress={setSelectedAddress}
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="buildingType"
            options={BuildingTypeOptions}
            required
          />
          <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="buildingMaxFloor"
            max={9}
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="staircaseAccess"
            options={StairAccessOptions}
            required
          />
          <CustomSizeM2
            data={data}
            onChange={handleChange}
            name="size"
            label={"Size"}
            placeholder={"Enter Size"}
            spanLabel="m2"
            spanExtraClassName="p-1"
            max={9}
            required
          />
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label={type === "Edit" ? "Save Changes" : "Save"}
          loading={loading}
        />
        <CustomButtonOutlined label="Cancel" onClick={() => navigate('/real-estate/property/building/list')}/>
      </Buttonlayout>
    </Page>
  );
};

export default BuildingForm;
