import React, { useEffect, useRef, useState } from "react";
import formValidation from "../../../../utils/validations";
import { showFormErrors } from "../../../../utils/commonFunctions";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import { useNavigate, useParams } from "react-router-dom";

import AllRoles from "./AllRoles";
import { addRoleAction, getRoleInfoAction, updateRoleAction } from "../../../../store/actions/rolesActions";
import { Menu } from "primereact/menu";

const RolesContainer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const menuRight = useRef(null);
    const [msgToShow, setMsgToShow] = useState({ type: "", message: "" })
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        roleName: "",
    });

    const [userInfo, setUserInfo] = useState()
    const [permissions, setPermissions] = useState([]);
    const keys = {
        realEsate: "Real Estate",
        administrator: "Administrator",
        tenant: "Tenant",
        settings: "Settings",
    };

    useEffect(() => {
        setRoles(AllRoles);
        let info = getUserInfo()
        setUserInfo(info)
        getRoleInfo(info?.company, params?.id);
    }, [AllRoles]);

    const getRoleInfo = (companyId, roleId) => {
        dispatch(
            getRoleInfoAction(companyId, roleId, dispatch, (res) => {
                setData({
                    roleName: res?.name,
                    employees: res?.employees,
                });
                setPermissions(res?.permission);
            })
        );
    };

    useEffect(() => {
        updateRolesWithPermissions();
    }, [permissions]);

    const updateRolesWithPermissions = () => {
        const updatedRoles = { ...AllRoles };
        permissions.forEach((permission) => {
            Object.keys(updatedRoles).forEach((roleKey) => {
                Object.keys(updatedRoles[roleKey]).forEach((sectionKey) => {
                    updatedRoles[roleKey][sectionKey].forEach((role) => {
                        if (role.value === permission.codename) {
                            role.check = true;
                        }
                    });
                });
            });
        });
        setRoles(updatedRoles);
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = (e) => {
        const filteredRoles = filterObjectsWithTrueCheck(roles);
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            let payload = {
                name: data?.roleName,
                permission: filteredRoles,
            };
            params?.id ? handleUpdateRole(payload) : handleAddRole(payload)
        }
    };

    const handleAddRole = (payload) => {
        dispatch(
            addRoleAction(userInfo?.company, payload, setLoading, navigate, (error) => {
                setMsgToShow({ type: "error", message: error })
            })
        );
    }

    const handleUpdateRole = (payload) => {
        dispatch(
            updateRoleAction(userInfo?.company, params?.id, payload, setLoading, navigate, (error) => {
                setMsgToShow({ type: "error", message: error })
            })
        );
    }

    const filterObjectsWithTrueCheck = (obj) => {
        const filteredRoles = [];
        for (let category in obj) {
            for (let subCategory in obj[category]) {
                obj[category][subCategory].forEach((item) => {
                    if (item.check === true) {
                        filteredRoles.push(item.value);
                    }
                });
            }
        }
        return filteredRoles;
    };

    const items = [
        {
            label: "Import",
            icon: "pi pi-download",
        },
        {
            label: "Export",
            icon: "pi pi-upload",
        },
    ];

    const header = () => {
        return (
            <div className="w-12 md:w-7 flex justify-content-between flex-wrap">
                <div className="w-12 md:w-9 mr-3"></div>
                <Menu model={items} popup ref={menuRight} id="popup_menu_right" />
            </div>
        );
    };

    return {
        data,
        handleChange,
        onSubmit,
        loading,
        msgToShow,

        header,
        roles,
        keys,
        setRoles,

    }
}

export default RolesContainer