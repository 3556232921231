import React from "react";
import ViewLandlordInfo from "../../../CommonViewComponents/LandlordInfo";
import LandlordContainer from "./LandlordContainer";
import CustomCard from "../../../../shared/CustomCard";
import Page from "../../../../shared/Page";
import CustomLandlordContactTable from "../../../Tables/CustomLandlordContactTable";

export default function ViewLandlord() {
  const {
    data,
    // allFurniture,
    // setAllFurniture,
    // allBankAccounts,
    // setAllBankAccounts,
    allContacts,
    setAllContacts,
  } = LandlordContainer();

  return (
    <Page
      title="View Details"
      description={`Let’s View your Landlord Details`}
      navigateLink={"/real-estate/landlord/list"}
    >
      <CustomCard>
        <ViewLandlordInfo
          type="landlord"
          data={data}
          // allFurniture={allFurniture}
          // setAllFurniture={setAllFurniture}
          // allBankAccounts={allBankAccounts}
          // setAllBankAccounts={setAllBankAccounts}
          // allContacts={allContacts}
          // setAllContacts={setAllContacts}
        />
        <CustomLandlordContactTable
          action="landlord"
          id={data?.id}
          title={"Contacts"}
          allContacts={allContacts}
          setAllContacts={setAllContacts}
        />
      </CustomCard>
    </Page>
  );
}
