import React, { useRef } from "react";
import { CustomForm } from "../../shared/AllInputs";
import {
  CustomAmountViewInput,
  CustomNumberViewInput,
  CustomViewInput,
} from "../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/icons/editicon.png";
// import CommonViewTable from "../Tables/CommonViewTable";
// import { Messages } from "primereact/messages";
import { handleFormatDate } from "../../utils/commonFunctions";
import { Toast } from "primereact/toast";
import CustomMaturityTable from "../Tables/CustomMaturityTable";

const ViewContractualInfo = ({
  type,
  data,
  // propertyId,
  allMaturities,
  setAllMaturities,
}) => {
  const msgs = useRef(null);
  const navigate = useNavigate();
  const params = useParams()
  // const maturityColumns = useMemo(
  //   () => [
  //     {
  //       name: "Extension",
  //       accessor: "extension",
  //     },
  //     {
  //       name: "Notice Period",
  //       accessor: "notice_period",
  //     },
  //   ],
  //   []
  // );

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(
              `/real-estate/property/edit/contractual-info/${params?.id}`
            )
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm title="Contractual info" header={header()}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <CustomNumberViewInput
          name="existingContract"
          data={data}
          label={"Contract ID"}
        />
        <CustomNumberViewInput
          name="movingDate"
          value={handleFormatDate(data?.movingDate)}
          label={"Moving in date"}
        />
        <CustomViewInput name="estateCompany" data={data} />
        <CustomNumberViewInput
          name="contractualSize"
          data={data}
          label="Contractual Size"
        />
        <CustomNumberViewInput
          name="contractualNo"
          data={data}
          label="Bedroom"
        />
      </CustomForm>
      <CustomForm title="Maturity">
        <CustomViewInput
          name="maturityType"
          data={data}
          label={"Type of Maturity"}
        />
        {data?.maturityType === "None" || data?.maturityType === null ? null : (
          <>
            <CustomNumberViewInput
              name="maturityDate"
              value={handleFormatDate(data?.maturityDate)}
              label="First Maturity Date"
            />
            {data?.maturityType === "Automatic Renewal" && (
              <>
                {" "}
                <CustomNumberViewInput name="maturityExtension" data={data} />
                <CustomNumberViewInput
                  name="noticePeriod"
                  data={data}
                  label="Notice Period"
                />
              </>
            )}
          </>
        )}
      </CustomForm>
      {allMaturities?.length ? (
        <>
        <CustomMaturityTable 
          action={type}
          id={data?.id}
          title={"Optional Maturity"}
          allMaturities={allMaturities}
          setAllMaturities={setAllMaturities}
        />
        </>
      ) : null}
      <CustomForm title="Deposits">
        <CustomViewInput name="depositType" data={data} />
        {data?.amount ? (
          <>
            <CustomAmountViewInput
              name="amount"
              data={data}
              icon={<i className="pi pi-euro text-xs"> </i>}
            />
          </>
        ) : (
          data?.depositType !="None" && <CustomAmountViewInput inputClass="pl-5" name="amount" data={data} />
        )}
      </CustomForm>
    </>
  );
};

export default React.memo(ViewContractualInfo);
