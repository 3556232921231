import React, { useEffect, useRef } from "react";

// import OfficeUnitContainer from "./OfficeUnitContainer";
import PropertyContainer from "./PropertyContainer";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
// import { Messages } from "primereact/messages";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../shared/CustomButton";
import { TabPanel, TabView } from "primereact/tabview";
import BuildingStucture from "../../../CommonComponents/BuildingStucture";
import BuildingUnit from "../../../CommonComponents/BuildingUnit";
import LandlordInfo from "../../../CommonComponents/LandlordInfo";
import ContractualInfoForm from "../../../CommonComponents/ContractualInfoForm";
import AddRooms from "../../../../shared/Components/AddRooms";
import AddEnergyMeters from "../../../../shared/Components/AddEnergyMeters";
// import AddLegalAllowance from "../../../../shared/Components/AddLegalAllowance";
import RentAndCost from "../../../CommonComponents/RentAndCost";
import AnnualStatementList from "../../AnnualStatement/AnnualStatementList";
import AddPropertyForm from "../../../CommonComponents/AddPropertyForm";
import { Toast } from "primereact/toast";
import BankingDetailForm from "../../../CommonComponents/BankingDetailForm";
import FurnitureForm from "../../../CommonComponents/FurnitureForm";
import AddContacts from "../../../../shared/Components/AddContacts";
export default function PropertyFrom({ type }) {
  const msgs = useRef(null);
  const {
    //common
    loading,
    msgToShow,
    skipAble,
    activeIndex,
    handleTabChange,
    disableTabs,
    setTabToActive,

    //add property
    data,
    handleChange,
    onSubmit,
    propertyIdOptions,
    propertyId,
    setSelectedAddress,
    propertyIdChange,

    //building structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    fileKey,
    sturcureDocuments,
    handleStructureFileChange,
    handleStructureFileDelete,

    //building unit
    buildingUnitData,
    BuildingUnitChange,

    //rooms
    allRooms,
    setAllRooms,
    // roomErrors,
    // setRoomErrors,

    //energy metters
    allEnergyMeters,
    setAllEnergyMeters,
    // meterErrors,
    // setMeterErrors,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    setLandlordAddress,
    landlordIdChange,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,
    // bankErrors,
    // setBankErrors,

    //contacts
    allContacts,
    setAllContacts,
    // contactErrors,
    // setContactErrors,

    //furniture
    allFurniture,
    setAllFurniture,
    // furnitureErrors,
    // setFurnitureErrors,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    maturityErrors,
    setMaturityErrors,
    contractIdChange,
    isContactExist,
    //legal allowance
    // allLegalAllowance,
    // setAllLegalAllowance,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,
    // RentAndCostSubmit,

    //extra cost
    allExtraCost,
    setAllExtraCost,
    // costErrors,
    // setCostErrors,
  } = PropertyContainer();

  useEffect(() => {
    if (msgToShow?.message) {
      msgs?.current?.show({
        severity: msgToShow?.type,
        detail: msgToShow?.message,
        life: 3000,
      });
    }
  }, [msgToShow]);

  return (
    <Page
      title={`${type === "Add" ? "Add New" : "View"} Property`}
      description={`Let’s ${type} your Property Details`}
      navigateLink={"/real-estate/property/list"}
    >
      {/* <Messages className="w-full" ref={msgs} /> */}
      <Toast ref={msgs} />
      <CustomCard>
        <AddPropertyForm
          type={type}
          data={data}
          handleChange={handleChange}
          propertyId={propertyId}
          propertyIdOptions={propertyIdOptions}
          activeIndex={activeIndex}
          setSelectedAddress={setSelectedAddress}
          propertyIdChange={propertyIdChange}
        />
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            handleTabChange(e?.index);
            // setTabToActive(e?.index);
            // skipAble.includes(activeIndex)? handleTabChange(e?.index) : onSubmit()
          }}
        >
          <TabPanel header="Building Structure">
            <BuildingStucture
              data={buildingStuctureData}
              handleChange={BuildingStuctureHandleChange}
              buildingOptions={buildingOptions}
              fileKey={fileKey}
              sturcureDocuments={sturcureDocuments}
              fileChange={handleStructureFileChange}
              fileDelete={handleStructureFileDelete}
            />
          </TabPanel>

          <TabPanel header="Building Unit" disabled={disableTabs.includes(1)}>
            <BuildingUnit
              data={buildingUnitData}
              handleChange={BuildingUnitChange}
            />
            <AddRooms
              // type="Add"
              maxSize={buildingUnitData?.size}
              allRooms={allRooms}
              setAllRooms={setAllRooms}
              // errors={roomErrors}
              // setErrors={setRoomErrors}
            />
            <AddEnergyMeters
              // type="Add"
              allEnergyMeters={allEnergyMeters}
              setAllEnergyMeters={setAllEnergyMeters}
              // errors={meterErrors}
              // setErrors={setMeterErrors}
            />
          </TabPanel>

          <TabPanel header="Landlord Info" disabled={disableTabs.includes(2)}>
            <LandlordInfo
              data={landLordData}
              handleChange={LandlordChange}
              landlordOptions={landlordOptions}
              // allBankAccounts={allBankAccounts}
              // setAllBankAccounts={setAllBankAccounts}
              // allContacts={allContacts}
              // setAllContacts={setAllContacts}
              // bankErrors={bankErrors}
              // setBankErrors={setBankErrors}
              // contactErrors={contactErrors}
              // setContactErrors={setContactErrors}
              landlordIdChange={landlordIdChange}
              setLandlordAddress={setLandlordAddress}
              // allFurniture={allFurniture}
              // setAllFurniture={setAllFurniture}
              // furnitureErrors={furnitureErrors}
              // setFurnitureErrors={setFurnitureErrors}
            />
            <BankingDetailForm
              title="Banking Details"
              data={landLordData}
              handleChange={LandlordChange}
              allBankAccounts={allBankAccounts}
              setAllBankAccounts={setAllBankAccounts}
            />
            <FurnitureForm
              title="Landlord Furniture"
              data={landLordData}
              handleChange={LandlordChange}
              allFurniture={allFurniture}
              setAllFurniture={setAllFurniture}
            />
            <AddContacts
              title="Add New Contact"
              allContacts={allContacts}
              setAllContacts={setAllContacts}
              // errors={contactErrors}
              // setErrors={setContactErrors}
            />
          </TabPanel>

          <TabPanel
            header="Contractual Info"
            disabled={disableTabs.includes(3)}
          >
            <ContractualInfoForm
              ContractOptions={ContractOptions}
              data={contractualData}
              handleChange={ContractualChange}
              allMaturities={allMaturities}
              setAllMaturities={setAllMaturities}
              maturityErrors={maturityErrors}
              setMaturityErrors={setMaturityErrors}
              contractIdChange={contractIdChange}
              isContactExist={isContactExist}
            />
            {/* <AddLegalAllowance
              allLegalAllowance={allLegalAllowance}
              setAllLegalAllowance={setAllLegalAllowance}
            /> */}
          </TabPanel>

          <TabPanel header="Rent & Cost" disabled={disableTabs.includes(4)}>
            <RentAndCost
              data={rentAndCostData}
              handleChange={RentAndCostChange}
              allExtraCost={allExtraCost}
              setAllExtraCost={setAllExtraCost}
              // costErrors={costErrors}
              // setCostErrors={setCostErrors}
            />
          </TabPanel>
          <TabPanel
            header="Annual Statement"
            disabled={disableTabs.includes(5)}
          >
            <AnnualStatementList />
          </TabPanel>
        </TabView>
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label="Save & Next"
          loading={loading}
        />
        <CustomButtonOutlined label="Cancel" />
        {skipAble.includes(activeIndex) && (
          <CustomButtonOutlined
            label="Skip"
            onClick={() => handleTabChange(activeIndex + 1)}
          />
        )}
      </Buttonlayout>
    </Page>
  );
}
