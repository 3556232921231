import React from "react";
import BgImage from "../../assets/images/dashboard-bg.jpg";

const StepOne = ({ setActiveStep, profileInfo }) => {
  return (
    <div
      className="signup-img p-5 side-img-div min-h-screen flex align-items-center "
      style={{ backgroundImage: `url(${BgImage})`, backgroundSize: "cover" }}
    >
      <div className="w-50 mb-4 me-4 p-4 rounded auth-content p-4 border-round-xl">
        <h1 className="text-white display-2 mb-2 fw-bold firstletter_capital">
          Welcome! <span>&#128073;</span>
          {profileInfo?.first_name} {profileInfo?.last_name}
        </h1>
        <p className="text-white mb-3">
          Let’s get you started by entering your office details
        </p>
        <div className=" ">
          <button
            type="button"
            className="btn primary-button cursor-pointer fw-normal text-lg text-white p-3 px-5 border-round-lg"
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Create a New Project
            <i className="pi pi-chevron-right ml-2 "></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
