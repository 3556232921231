import React from "react";
import { Button } from "primereact/button";
export default function CustomButton({
  label = "Submit",
  extraClassNames,
  ...props
}) {
  return (
    <Button
      className={`primary-button align-center mx-2 ${extraClassNames}`}
      label={label}
      style={{ alignSelf: "center" }}
      {...props}
    />
  );
}
export function CustomButtonOutlined({
  label = "Submit",
  extraClassNames,
  ...props
}) {
  return (
    <Button
      className={`primary-button-outlined align-center mx-2 ${extraClassNames}`}
      label={label}
      style={{ alignSelf: "center" }}
      outlined
      {...props}
    />
  );
}
export function Buttonlayout({ className, position = "center", children }) {
  return (
    <div className={`flex my-2 justify-content-${position} ${className}`}>
      {children}
    </div>
  );
}
