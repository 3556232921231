import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setEmployeeSlice } from "../slices/employeeSlice";

const addEmployeeAction = (company, data, offices, loading, navigate, dispatch, onRes) => async () => {
    loading(true)

    let payload = {
        employee_id: data?.employeeId,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_number: data?.phoneNumber,
        role: data?.selectRole,
    };

    if (offices.length) {
        payload.offices = offices
    }

    const res = await api(
        "post",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
        payload
    );

    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate("/administrator/employees/list")
        onRes && onRes(res)
        dispatch(res?.data?.results)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const getEmployeesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
    );
    onRes(res);
};

const employeesListAction = (company, rows, page, search, name, phoneNumber, role, position, createdAt, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&name=${name}&phone_number=${phoneNumber}&role=${role}&position=${position}&active_from=${createdAt}`,
    );
    onRes(res);
};

const getEmployeeInfoAction = (company, empId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    if (res?.success) {
        const info = res?.data?.results
        onRes(info);
        dispatch(setEmployeeSlice(info));
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const updateEmployeeAction = (company, id, data, offices, loading, navigate, dispatch, onRes) => async () => {
    loading(true)
    let payload = {
        employee_id: data?.employeeId,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        phone_number: data?.phoneNumber,
        role: data?.selectRole,
    };

    if (offices.length) {
        payload.offices = offices
    }
    const res = await api(
        "put",
        endPoints?.EMPLOYEE_LIST + `${company}/${id}/`,
        payload
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        navigate(`/administrator/employees/view/${id}`)
        onRes && onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    loading(false)
};

const employeeDeleteAction = (company, empId, loading, deleteModal, dispatch, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    if (res?.success) {
        onRes(res);
        dispatch(showToastAction({
            severity: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            severity: "error",
            detail: res?.message,
        }))
    }
    loading(false)
    deleteModal(false)
};

const searchEmployeeIdAction = (payload, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.SEARCH_EMPLOYEE_ID,
        payload
    )
    onRes(res)
}

export {
    addEmployeeAction,
    getEmployeesListAction,
    getEmployeeInfoAction,
    updateEmployeeAction,
    employeeDeleteAction,
    employeesListAction,
    searchEmployeeIdAction
};
