import React, { useEffect, useState, useRef } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import { Messages } from "primereact/messages";
import {
  HandleAdd,
  handleDelete,
  handlEdit,
  handleObjChange,
  handleSave,
} from "./commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeListAction } from "../../store/actions/officeActions";
import { getUserInfo } from "../../services/auth";
import { PositionOptions } from "../AllDropDownOptions";
import { Toast } from "primereact/toast";
const AddOffices = ({ title, allOffices, setAllOffices }) => {
  const dispatch = useDispatch();
  const msgs = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const InputObj = { id: null, office: null, position: null, status: null };
  const errorObj = { office: null, position: null };
  const [errors, setErrors] = useState([]);
  const ignoreInputs = ["status", "id"];
  const [officeList, setOfficeList] = useState([]);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    if (info?.company) {
      getOfficeList(info?.company);
    }
  }, [profile]);

  const getOfficeList = (company) => {
    dispatch(
      getOfficeListAction(company, (res) => {
        if (res?.success) {
          let options = res?.data.results.map((office) => {
            return { name: office?.name, value: office?.id };
          });
          setOfficeList(options);
        }
      })
    );
  };

  useEffect(() => {
    if (allOffices?.length) {
      let allErrors = [...errors];
      let myErrors = allOffices?.map((office, officeIndex) => {
        let error = allErrors.find(
          (err, errorIndex) => errorIndex === officeIndex
        );
        if (error) {
          return error;
        } else {
          return errorObj;
        }
      });
      setErrors(myErrors);
    }
  }, [allOffices]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() =>
          HandleAdd(
            InputObj,
            allOffices,
            setAllOffices,
            errorObj,
            errors,
            setErrors
          )
        }
      />
    </div>
  );

  return (
    <>
      <CustomForm title={title ? title : "Office"} header={header}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Select Office</th>
              <th>Select Position</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allOffices?.map((office, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        value={office?.office}
                        col={12}
                        onChange={(e) =>
                          handleObjChange(
                            allOffices,
                            setAllOffices,
                            errors,
                            setErrors,
                            ignoreInputs,
                            e?.target?.name,
                            e?.target?.value,
                            index
                          )
                        }
                        name="office"
                        options={officeList}
                        disabled={office?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={office?.position}
                        onChange={(e) =>
                          handleObjChange(
                            allOffices,
                            setAllOffices,
                            errors,
                            setErrors,
                            ignoreInputs,
                            e?.target?.name,
                            e?.target?.value,
                            index
                          )
                        }
                        name="position"
                        col={12}
                        options={PositionOptions}
                        disabled={office?.status === "save" ? true : false}
                        ignoreLabel
                      />
                    </td>
                    {office?.status === "save" ? (
                      <td>
                        <i
                          className="pi pi-ellipsis-v cursor-pointer text-dark"
                          onClick={() =>
                            handlEdit(allOffices, setAllOffices, index)
                          }
                        ></i>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            onClick={() =>
                              handleSave(
                                allOffices,
                                setAllOffices,
                                errors,
                                setErrors,
                                ignoreInputs,
                                index
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            onClick={() =>
                              handleDelete(
                                allOffices,
                                setAllOffices,
                                errors,
                                setErrors,
                                index
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>

                  {(errors[index]?.office || errors[index]?.position) && (
                    <tr>
                      <td>
                        <small className="p-error">
                          {errors[index]?.office}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.position}
                        </small>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
    </>
  );
};

export default AddOffices;
