import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
const CustomTableContainer = ({
  list,
  columns,
  actions,
  first,
  rows,
  count,
  ActionTemplate,
  filters,
  setFilters,
  globalFilters,
  activeRow,
  handleSort,
  onPageChange,
  slice,
  selectionMode,
  allowExpansion,
  expandedRows,
  setExpandedRows,
  rowExpansionTemplate,
  onApplyFilter,
  onClearFilter,
  selectedItems,
  setSelectedItems,
  notShowSelection
}) => {
  const [rowClick, setRowClick] = useState(true);
  const [timer, setTimer] = useState(5);
  const dispatch = useDispatch();

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-times"
        // onClick={() => options.filterClearCallback("")}
        onClick={() => {
          onClearFilter
            ? onClearFilter(options)
            : options.filterClearCallback("");
        }}
        className="clear-button-outlined"
      >
        Clear
      </Button>
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        // icon="pi pi-check"
        // onClick={options.filterApplyCallback}
        onClick={() => {
          onApplyFilter
            ? onApplyFilter(options)
            : options.filterApplyCallback();
        }}
        className="bg-primary-color"
      >
        Apply
      </Button>
    );
  };

  const rowClass = (data) => {
    return {
      "bg-primary-color": data?.id == activeRow,
    };
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timer > 0) {
        let newTime = timer - 1;
        setTimer(newTime);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [timer]);

  useEffect(() => {
    if (timer === 0 && slice) {
      dispatch(slice(""));
    }
  }, [timer]);

  return (
    <>
      <DataTable
        className="mt-4 table-list"
        value={list}
        tableStyle={{ minWidth: "50rem" }}
        selectionMode={rowClick ? null : "checkbox"}
        selection={selectedItems && selectedItems}
        onSelectionChange={(e) => {
          setSelectedItems && setSelectedItems(e.value);
        }}
        dataKey="id"
        onSort={handleSort}
        rowClassName={rowClass}
        scrollable
        filters={filters}
        globalFilterFields={globalFilters}
        onFilter={(e) => {
          setFilters(e.filters);
        }}
        onRowToggle={(e) => setExpandedRows(e?.data)}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        {!notShowSelection && <Column
          headerClassName="custom-header"
          selectionMode={selectionMode || "multiple"}
          headerStyle={{ width: "3rem" }}
          frozen
        ></Column>}
        {allowExpansion && (
          <Column expander={allowExpansion} style={{ width: "5rem" }} frozen/>
        )}
        {columns?.map((col, index) => {
          return (
            <Column
              key={index}
              headerClassName="custom-header"
              filterField={col?.accessor}
              field={col?.accessor}
              header={col?.name}
              frozen={col?.frozen}
              sortable={col?.sortable}
              filter={col?.filter}
              filterElement={col?.filterElement}
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "80px" }}
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              body={col?.body}
              showFilterMenuOptions={false}
              showFilterMatchModes={false}
            ></Column>
          );
        })}
        <Column
          headerClassName="custom-header"
          body={actions || ""}
          style={{ width: "80px" }}
          header={ActionTemplate || ""}
          alignFrozen="right"
          frozen={true}
        ></Column>
      </DataTable>
      {onPageChange && (
        <Paginator
          first={Number(first)}
          rows={Number(rows)}
          totalRecords={Number(count)}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={onPageChange}
          // lazy
        ></Paginator>
      )}
    </>
  );
};

export default CustomTableContainer;
