import React from "react";
import Page from "../../../../../shared/Page";
import CustomCard from "../../../../../shared/CustomCard";
import { CustomForm, CustomInput, CustomSwitch } from "../../../../../shared/AllInputs";
import ViewHandymanContainer from "./ViewHandymanContainer";
import { CustomViewInput } from "../../../../../shared/AllViewInputs";
import { TabPanel, TabView } from "primereact/tabview";
import CustomHandymanServiceTable from "../../../../Tables/CustomHandymanServiceTable";
import EditIcon from "../../../../../assets/images/icons/editicon.png";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../../shared/CustomButton";
import GooglePlacesInput from "../../../../../shared/GooglePlacesInput";
import CommonViewTable from "../../../../Tables/CommonViewTable";
const ViewHandymanForms = ({ type }) => {
  const { data, servicesList, onSubmit, loading, handleChange, damageColumns } =
    ViewHandymanContainer();
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex cursor-pointer align-items-center ">
        <CustomSwitch data={data} onChange={handleChange} name="feedback" label="feedback" col={10} disabled={type === "View"}/>
        {type === "View" && <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/real-estate/maintenance/handyman/edit/${params?.id}`)
          }
        ></img>}
      </div>
    );
  };

  return (
    <Page
      title={`${type} Handyman`}
      description={`Let’s ${type} your Handyman Details`}
      navigateLink={"/real-estate/maintenance/handyman/list"}
    >
      <CustomCard>
        <CustomForm title={`Handyman Details`} header={header()}>
        {type === "Edit" ? (
          <>
            <CustomInput
              data={data}
              name="name"
              onChange={handleChange}
              required
            />
            <GooglePlacesInput
              data={data}
              name="address"
              onChange={handleChange}
              required
            />
            </>
        ) : (
          <>
            <CustomViewInput name="name" data={data} />
            <CustomViewInput name="address" data={data} />
            </>
        )}
          </CustomForm>
        <TabView className="tabs-design">
          <TabPanel header="Damage History">
            <CommonViewTable title={""} list={[]} columns={damageColumns} />
          </TabPanel>
          <TabPanel header="Services">
            <CustomHandymanServiceTable services={servicesList} />
          </TabPanel>
        </TabView>
      </CustomCard>
      {type === "Edit" && (
        <Buttonlayout>
          <CustomButton onClick={onSubmit} label={type === "Edit" ? "Save Changes" : "Save"} loading={loading} />
          <CustomButtonOutlined
            label="Cancel"
            onClick={() => {
              navigate(`/real-estate/maintenance/handyman/view/${params?.id}`);
            }}
          />
        </Buttonlayout>
      )}
    </Page>
  );
};

export default ViewHandymanForms;
