import React, { useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
} from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "../../utils/validations";
import CustomOverlay from "../../shared/CustomOverlay";
import CustomModal from "../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "../../shared/Components/commonFunctions";
import {
  BankPurposeOpt,
  LocationOptions,
} from "../../shared/AllDropDownOptions";
import {
  deleteBankAccountAction,
  getLandlordAction,
  getPropertyLandlordAction,
  updateBankAccountAction,
} from "../../store/actions/landlordActions";
import { useParams } from "react-router-dom";
// import { debounce } from "lodash";
// import { checkIban } from "../../services/api";
// import { KEYS } from "../../utils/keys";

export default function CustomBankAccountTable({
  action,
  id,
  title,
  allBankAccounts,
  setAllBankAccounts,
  onUpdate,
  onDelete,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [ibanError, setIbanError] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const { profile } = useSelector((state) => state?.user);
  const PurposeEditor = (options) => {
    const formErrors = formValidation(
      "purpose",
      options.value,
      allBankAccounts
    );
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="purpose"
        col={12}
        options={BankPurposeOpt}
        ignoreLabel
        errorMessage={formErrors["purpose"]}
      />
    );
  };

  const OwnerNameEditor = (options) => {
    const formErrors = formValidation(
      "owner_name",
      options.value,
      allBankAccounts
    );

    return (
      <CustomInput
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="owner_name"
        ignoreLabel
        errorMessage={formErrors["owner_name"]}
      />
    );
  };

  // const handleIban = async (value) => {
  //   if(value){
  //     let payload = {
  //       api_key: KEYS.iBan,
  //       iban: value,
  //     };
  //     const res = await checkIban(payload);

  //     if (res?.data?.is_valid) {
  //       return "";
  //     } else if (!res?.data?.is_valid) {
  //        return "Please enter a valid Iban!";
  //     }
  //   }
  // };

  // const handleIbanChange = useRef(debounce(async (value, onError) => {
  //   const error = await handleIban(value);
  //   onError(error)
  //   setIsSearch(false)
  // }, 1000));

  const IBANEditor = (options) => {
    let formErrors = formValidation("iban", options.value, allBankAccounts);
    return (
      <CustomInput
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          // handleIbanChange.current(e.target.value, (setIbanError));
          // options.value? setIsSearch(true) : setIsSearch(false)
        }}
        name="iban"
        col={12}
        options={LocationOptions}
        ignoreLabel
        errorMessage={formErrors["iban"] || ibanError}
        isSearch={isSearch}
      />
    );
  };

  const BICEditor = (options) => {
    const formErrors = formValidation("bic", options.value, allBankAccounts);
    return (
      <CustomInput
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="bic"
        col={12}
        ignoreLabel
        errorMessage={formErrors["bic"]}
      />
    );
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                  fill="#787878"
                ></path>
              </svg>
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#787878"
                  d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                ></path>
              </svg>
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const rowEditValidator = async (rowData, options) => {
    const { purpose, owner_name, iban, bic } = rowData;
    const purposeError = formValidation("purpose", purpose, allBankAccounts);
    const ownerNameError = formValidation(
      "owner_name",
      owner_name,
      allBankAccounts
    );
    const ibanError = formValidation("iban", iban, allBankAccounts);
    const bicError = formValidation("bic", bic, allBankAccounts);
    if (
      !purposeError["purpose"] &&
      !ownerNameError["owner_name"] &&
      !ibanError["iban"] &&
      !bicError["bic"]
      // && !ibanError
      // && !isSearch
    ) {
      let payload = {
        purpose: purpose,
        owner_name: owner_name,
        iban: iban,
        bic: bic,
        landlord: id,
      };
      if (onUpdate) {
        onUpdate(rowData?.id, payload, (res) => {
          if (res) {
            let accounts = [...allBankAccounts];
            accounts[options.rowIndex] = rowData;
            setAllBankAccounts(accounts);
            setEditingRows({});
          }
        });
      } else {
        dispatch(
          updateBankAccountAction(rowData?.id, payload, dispatch, (res) => {
            let accounts = [...allBankAccounts];
            accounts[options.rowIndex] = rowData;
            setAllBankAccounts(accounts);
            setEditingRows({});
            handleGetInfo();
          })
        );
      }
    } else {
      return false;
    }
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const handleDeleteBankAccount = () => {
    if (onDelete) {
      onDelete(idToDelete?.id, (res) => {
        if (res) {
          handleDelete(
            allBankAccounts,
            setAllBankAccounts,
            "",
            "",
            idToDelete?.index
          );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
          handleGetInfo();
        }
      });
    } else {
      dispatch(
        deleteBankAccountAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDelete(
            allBankAccounts,
            setAllBankAccounts,
            "",
            "",
            idToDelete?.index
          );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
          handleGetInfo();
        })
      );
    }
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected bank account from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleGetInfo = () => {
    if (action === "property") {
      dispatch(getPropertyLandlordAction(params?.id, dispatch));
    } else if (action === "landlord") {
      dispatch(getLandlordAction(profile?.company, params?.id, dispatch));
    }
  };

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 properties-table-list"
        value={allBankAccounts}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="purpose"
          header="Purpose"
          editor={(options) => PurposeEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="owner_name"
          header="Owner Name"
          editor={(options) => OwnerNameEditor(options)}
          // style={{ width: "200px" }}
          className="firstletter_capital"
        ></Column>
        <Column
          headerClassName="custom-header"
          field="iban"
          header="IBAN"
          editor={(options) => IBANEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="bic"
          header="BIC"
          editor={(options) => BICEditor(options)}
          // style={{ width: "200px" }}
          className="firstletter_capital"
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          // headerStyle={{ minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteBankAccount}
        loading={loading}
      />
    </CustomForm>
  );
}
