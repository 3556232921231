import React, { useEffect, useRef } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomMulitSelect,
  CustomPhoneInput,
} from "../../../../shared/AllInputs";
import { Messages } from "primereact/messages";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../shared/CustomButton";
import OfficeUnitContainer from "./OfficeUnitContainer";
import { Toast } from "primereact/toast";

export default function OfficeUnitForm({ title }) {
  const msgs = useRef(null);
  const {
    data,
    handleChange,
    onSubmit,
    loading,
    officeList,
    msgToShow,
    employeesList,
  } = OfficeUnitContainer();

  useEffect(() => {
    if (msgToShow?.message) {
      msgs?.current?.show({
        severity: msgToShow?.type,
        detail: msgToShow?.message,
      });
    }
  }, [msgToShow]);

  return (
    <Page
      title={`${title} Office Unit`}
      description={`Let’s ${title} your Unit Details`}
    >
      <CustomCard>
        <CustomForm title="General">
          {/* <Messages className="w-full" ref={msgs} /> */}
          <Toast ref={msgs} />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="unitName"
            required
          />
          {/* <CustomDropDown
            data={data}
            onChange={handleChange}
            name="selectOffice"
            label={"Select Office"}
            options={officeList}
            placeholder="Select Office"
          /> */}
          <CustomMulitSelect
            data={data}
            onChange={handleChange}
            name="selectOffice"
            label={"Select Office"}
            options={officeList}
            placeholder="Select Office"
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="email"
            required
          />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="costCenter"
            required
          />
        </CustomForm>
        <br />
        <CustomForm title="Employee">
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitHeadOne"
            label={"Unit Head 1"}
            options={employeesList}
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitHeadTwo"
            label={"Unit Head 2"}
            options={employeesList}
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitCoHead"
            label="Unit Co-head"
            options={employeesList}
          />
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save" loading={loading} />
        <CustomButtonOutlined label="Cancel" />
      </Buttonlayout>
    </Page>
  );
}
