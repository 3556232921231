import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";

const addHandymanServiceAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        name: data?.serviceName
    }
    const res = await api(
        "post",
        endPoints?.HANDYMAN_SERVICE + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateHandymanServiceAction = (company, id, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        name: data?.serviceName
    }
    const res = await api(
        "put",
        endPoints?.HANDYMAN_SERVICE + `/${company}/${id}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const deleteHandymanServiceAction = (company, id, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.HANDYMAN_SERVICE + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const getHandymanServiceListAction = (company, rows, page, search, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.HANDYMAN_SERVICE + `/${company}/?limit=${rows}&page=${page}&search=${search}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes({ options: info, count: res?.data?.count })
    }
};

const getAllHandymanServiceListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.HANDYMAN_SERVICE + `/${company}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes({ options: info, count: res?.data?.count })
    }
};

const getSingleHandymanServiceListAction = (handyman, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.SERVICE + `?handyman_id=${handyman}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
    }
};

const getHandymanListByServiceTypeAction = (company, service, status, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.SERVICE + `?service=${service}&status=${status}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes({ options: info, count: res?.data?.count })
    }
};

export {
    addHandymanServiceAction,
    getHandymanServiceListAction,
    updateHandymanServiceAction,
    deleteHandymanServiceAction,
    getSingleHandymanServiceListAction,
    getAllHandymanServiceListAction,
    getHandymanListByServiceTypeAction
};
