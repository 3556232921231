import React, { useRef, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../AllInputs";
// import { InputText } from "primereact/inputtext";
import CustomButton from "../CustomButton";
import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
import { ContactOptions, ContactRoleOptions } from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import { useDispatch } from "react-redux";
import CustomModal from "../Modals/CustomModal";
import {
  deleteContactAction,
  getContactAction,
} from "../../store/actions/landlordActions";
import { Toast } from "primereact/toast";

const AddContacts = ({
  // type,
  title,
  allContacts,
  setAllContacts,
  // errors,
  // setErrors,
}) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const contactsInpObject = {
    type: "",
    name: "",
    role: "",
    mobile: "",
    office_phone: "",
    email: "",
  };

  // const errorObj = {
  //   type: null,
  //   name: null,
  //   role: null,
  //   mobile: null,
  //   office_phone: null,
  //   email: null,
  // };
  const ignoreInputs = ["status", "id"]
  const optional =["office_phone", "email"]

  // useEffect(() => {
  //   if (allContacts?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allContacts?.map((contact, contactInex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === contactInex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allContacts]);

  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={
          () => HandleAddNew(contactsInpObject, allContacts, setAllContacts)
          // HandleAdd(
          //   contactsInpObject,
          //   allContacts,
          //   setAllContacts,
          //   errorObj,
          //   errors,
          //   setErrors
          // )
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected contact from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleDeleteContact = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteContactAction(idToDelete?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(allContacts, setAllContacts, idToDelete?.index);
            // handleDelete(
            //   allContacts,
            //   setAllContacts,
            //   errors,
            //   setErrors,
            //   idToDelete?.index
            // );
            setIdToDelete({ id: "", index: "" });
          
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allContacts, setAllContacts, idToDelete?.index);
      // handleDelete(
      //   allContacts,
      //   setAllContacts,
      //   errors,
      //   setErrors,
      //   idToDelete?.index
      // );
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getContactInfo = (id, index) => {
    dispatch(
      getContactAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allContacts, setAllContacts);
          // handleCancel(
          //   info,
          //   index,
          //   allContacts,
          //   setAllContacts,
          //   errorObj,
          //   errors,
          //   setErrors
          // );
        }
      })
    );
  };

  return (
    <CustomForm title={title} header={header}>
      <Toast ref={msgs} />
      <table className="financial-table w-full mt-3">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Role</th>
            <th>Mobile</th>
            <th>Office Phone</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="financial-table-form">
          {allContacts?.map((contact, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <CustomDropDown
                      value={contact?.type}
                      onChange={
                        (e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          )
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // )
                      }
                      name="type"
                      col={12}
                      options={ContactOptions}
                      disabled={contact?.status === "save" ? true : false}
                      ignoreLabel
                      errorMessage={contact?.formErrors?.type}
                    />
                  </td>
                  <td>
                    <CustomInput
                      disabled={contact?.status === "save"}
                      type="text"
                      name="name"
                      extraClassName="w-full"
                      value={contact?.name}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allContacts,
                          setAllContacts,
                          ignoreInputs,
                          optional
                        );
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                      ignoreLabel
                      errorMessage={contact?.formErrors?.name}
                    />
                  </td>
                  <td>
                    <CustomDropDown
                      value={contact?.role}
                      onChange={
                        (e) =>
                          handleChangeNew(
                            e?.target?.name,
                            e?.target?.value,
                            index,
                            allContacts,
                            setAllContacts,
                            ignoreInputs,
                            optional
                          )
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // )
                      }
                      name="role"
                      col={12}
                      options={ContactRoleOptions}
                      disabled={contact?.status === "save" ? true : false}
                      ignoreLabel
                      errorMessage={contact?.formErrors?.role}
                    />
                  </td>
                  <td>
                    <CustomPhoneInput
                      disabled={contact?.status === "save"}
                      data={contact}
                      extraClassName="w-full"
                      onChange={(e) => {
                        handleChangeNew(
                          e?.name,
                          e?.value,
                          index,
                          allContacts,
                          setAllContacts,
                          ignoreInputs,
                          optional
                        );
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e.name,
                        //   e.value,
                        //   index
                        // );
                      }}
                      name="mobile"
                      required
                      ignoreLabel
                      errorMessage={contact?.formErrors?.mobile}
                    />
                  </td>
                  <td>
                    <CustomPhoneInput
                      disabled={contact?.status === "save"}
                      data={contact}
                      extraClassName="w-full"
                      onChange={(e) => {
                        handleChangeNew(
                          e?.name,
                          e?.value,
                          index,
                          allContacts,
                          setAllContacts,
                          ignoreInputs,
                          optional
                        );
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e.name,
                        //   e.value,
                        //   index
                        // );
                      }}
                      name="office_phone"
                      required
                      ignoreLabel
                      errorMessage={contact?.formErrors?.office_phone}
                    />
                  </td>
                  <td>
                    <CustomInput
                      disabled={contact?.status === "save"}
                      type="text"
                      name="email"
                      extraClassName="w-full"
                      value={contact?.email}
                      onChange={(e) => {
                        handleChangeNew(
                          e?.target?.name,
                          e?.target?.value,
                          index,
                          allContacts,
                          setAllContacts,
                          ignoreInputs,
                          optional
                        );
                        // handleObjChange(
                        //   allContacts,
                        //   setAllContacts,
                        //   errors,
                        //   setErrors,
                        //   ignoreInputs,
                        //   e?.target?.name,
                        //   e?.target?.value,
                        //   index
                        // );
                      }}
                      ignoreLabel
                      errorMessage={contact?.formErrors?.email}
                    />
                  </td>
                  {contact?.status === "save" ? (
                    <td>
                      <CustomOverlay ref={openAction}>
                        <ul className="no-style p-0">
                          {/* {type == "Edit" && ( */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() =>
                              handlEdit(allContacts, setAllContacts, index)
                            }
                          >
                            <i className="pi pi-file-edit text-dark"></i>
                            Edit
                          </li>
                          {/* )} */}
                          <li
                            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                            onClick={() => {
                              setIdToDelete({ id: contact?.id, index: index });
                              setDeleteModal(true);
                            }}
                          >
                            <i className="pi pi-trash cursor-pointer text-dark"></i>
                            Delete
                          </li>
                        </ul>
                      </CustomOverlay>
                    </td>
                  ) : (
                    <td>
                      <div className="d-flex gap-3">
                        <a
                          className="cursor-pointer"
                          onClick={
                            () =>
                              handleSaveNew(
                                index,
                                allContacts,
                                setAllContacts,
                                ignoreInputs,
                                optional
                              )
                            // handleSave(
                            //   allContacts,
                            //   setAllContacts,
                            //   errors,
                            //   setErrors,
                            //   ignoreInputs,
                            //   index
                            // )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                              fill="#787878"
                            ></path>
                          </svg>
                        </a>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            if (contact?.id) {
                              getContactInfo(contact?.id, index);
                            } else {
                              handleDeleteNew(
                                allContacts,
                                setAllContacts,
                                index
                              );
                              // handleDelete(
                              //   allContacts,
                              //   setAllContacts,
                              //   errors,
                              //   setErrors,
                              //   index
                              // )
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#787878"
                              d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </td>
                  )}
                </tr>
                {/* {(contact?.formErrors?.type ||
                  contact?.formErrors?.name ||
                  contact?.formErrors?.role ||
                  contact?.formErrors?.mobile ||
                  contact?.formErrors?.office_phone ||
                  contact?.formErrors?.email) && (
                  <tr>
                    <td>
                      <small className="p-error">{contact?.formErrors?.type}</small>
                    </td>
                    <td>
                      <small className="p-error">{contact?.formErrors?.name}</small>
                    </td>
                    <td>
                      <small className="p-error">{contact?.formErrors?.role}</small>
                    </td>
                    <td>
                      <small className="p-error">{contact?.formErrors?.mobile}</small>
                    </td>
                    <td>
                      <small className="p-error">
                        {contact?.formErrors?.office_phone}
                      </small>
                    </td>
                    <td>
                      <small className="p-error">{contact?.formErrors?.email}</small>
                    </td>
                  </tr>
                )} */}
                {/* {(errors[index]?.type ||
                  errors[index]?.name ||
                  errors[index]?.role ||
                  errors[index]?.mobile ||
                  errors[index]?.office_phone ||
                  errors[index]?.email) && (
                  <tr>
                    <td>
                      <small className="p-error">{errors[index]?.type}</small>
                    </td>
                    <td>
                      <small className="p-error">{errors[index]?.name}</small>
                    </td>
                    <td>
                      <small className="p-error">{errors[index]?.role}</small>
                    </td>
                    <td>
                      <small className="p-error">{errors[index]?.mobile}</small>
                    </td>
                    <td>
                      <small className="p-error">
                        {errors[index]?.office_phone}
                      </small>
                    </td>
                    <td>
                      <small className="p-error">{errors[index]?.email}</small>
                    </td>
                  </tr>
                )} */}
                <CustomModal
                  isOpen={deleteModal}
                  setIsOpen={setDeleteModal}
                  heading={"Delete"}
                  body={modalContent}
                  submitLabel={"Confirm"}
                  onSubmit={handleDeleteContact}
                  loading={loading}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
    </CustomForm>
  );
};

export default AddContacts;
