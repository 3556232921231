import React from "react";
import {
  CustomAutoComplete,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../../shared/AllInputs";
// import AddBankAccounts from "../../shared/Components/AddBankAccounts";
// import AddContacts from "../../shared/Components/AddContacts";
import { LandoardTypes, YesNoOptions } from "../../shared/AllDropDownOptions";
import GooglePlacesInput from "../../shared/GooglePlacesInput";
// import { Toast } from "primereact/toast";
// import AddFurniture from "../../shared/Components/AddFurniture";

const LandlordInfo = ({
  type,
  // isDisabled,
  data,
  handleChange,
  landlordOptions,
  // msgToShow,
  setLandlordAddress,
  landlordIdChange,

  // allBankAccounts,
  // setAllBankAccounts,
  // bankErrors,
  // setBankErrors,

  // allContacts,
  // setAllContacts,
  // contactErrors,
  // setContactErrors,

  // allFurniture,
  // setAllFurniture,
  // furnitureErrors,
  // setFurnitureErrors,
}) => {
  // const msgs = useRef(null);

  // useEffect(() => {
  //   if (msgToShow?.message) {
  //     msgs?.current?.show({
  //       severity: msgToShow?.type,
  //       detail: msgToShow?.message,
  //     });
  //   }
  // }, [msgToShow]);

  return (
    <>
      <CustomForm title="Landlord Info">
        {/* <Toast ref={msgs} /> */}
        {type === "new"? <CustomAutoComplete
          data={data}
          onChange={handleChange}
          name="landlordId"
          suggestions={landlordOptions}
          required
          editable
          label="Landlord ID"
          placeholder="Landlord ID"
          search={(e) => landlordIdChange.current(e.query)}
          max={9}
        /> : 
        <CustomAutoComplete
          field={"landlord_id"}
          data={data}
          onChange={handleChange}
          name="existingLandlord"
          suggestions={landlordOptions}
          required
          editable
          label="Search Existing Landlord ID"
          placeholder="Search landlord ID"
          search={(e) => landlordIdChange.current(e.query)}
          max={9}
        />}
        <CustomInput 
          data={data} 
          onChange={handleChange} 
          name="landlordName" 
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="landlordType"
          options={LandoardTypes}
          placeholder="Select Type"
          label={"Type"}
          required
        />
        <GooglePlacesInput
          id="landlord"
          data={data}
          onChange={handleChange}
          name="address"
          required
          setAddress={setLandlordAddress}
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="street"
          required
        />
         <CustomInput
          data={data}
          onChange={handleChange}
          name="zipCode"
          required
        />
        <CustomInput data={data} onChange={handleChange} name="city" required />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="cityDivision"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="country"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="email"
          required
        />
        <CustomPhoneInput
          data={data}
          onChange={handleChange}
          name="phoneNumber"
          required
        />
      </CustomForm>
      {/* <CustomForm title="Banking Details">
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="directDebit"
          options={YesNoOptions}
        />
      </CustomForm>
      <AddBankAccounts
        title={"Bank Accounts"}
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
        // errors={bankErrors}
        // setErrors={setBankErrors}
      /> */}
      {/* <CustomForm title="Landlord Furniture">
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="furnitureProvided"
          options={YesNoOptions}
          label="Is Furniture provide by the Landlord?"
        />
      </CustomForm>
      {data?.furnitureProvided === "yes" && (
        <AddFurniture
          title=""
          allValues={allFurniture}
          setAllValues={setAllFurniture}
          // errors={furnitureErrors}
          // setErrors={setFurnitureErrors}
        />
      )} */}
      {/* <AddContacts
        title="Add New Contact"
        allContacts={allContacts}
        setAllContacts={setAllContacts}
        // errors={contactErrors}
        // setErrors={setContactErrors}
      /> */}
    </>
  );
};

export default React.memo(LandlordInfo);
