import React, { useRef, useState } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
  CustomInput,
  // CustomNumberInput2,
  // CustomSizeM2,
} from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "../../utils/validations";
import CustomOverlay from "../../shared/CustomOverlay";
import CustomModal from "../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEnergyMeterAction,
  // deleteRoomAction,
  updateEnergyMeterAction,
} from "../../store/actions/buildingUnitActions";
import { handleDelete } from "../../shared/Components/commonFunctions";
import { Toast } from "primereact/toast";
import {
  // checkIsValueGreater,
  convertAmountInGerman,
} from "../../utils/commonFunctions";
import {
  AccessOptions,
  ElectricityuPurposeOptions,
  GasPurposeOptions,
  LocationOptions,
  MeterTypeOpt,
} from "../../shared/AllDropDownOptions";
import { spaceNotAllowed } from "../../utils/regex";
import { Sidebar } from "primereact/sidebar";
import AddEnergyClocks from "../../shared/Components/AddEnergyClock";
import { useParams } from "react-router-dom";
import { getPorpertyInfoAction } from "../../store/actions/propertyActions";

export default function CustomEnergyTable({
  id,
  title,
  allEnergyMeters,
  setAllEnergyMeters,
}) {
  const msgs = useRef(null);
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const [meterType, setMeterType] = useState("");
  const [openRightbar, setOpenRightbar] = useState({
    isOpen: false,
    id: "",
  });
  const { profile } = useSelector((state) => state.user);
  const params = useParams();

  const TypeEditor = (options) => {
    const formErrors = formValidation("type", options.value, allEnergyMeters);
    setMeterType(options.value);
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="type"
        col={12}
        options={MeterTypeOpt}
        ignoreLabel
        errorMessage={formErrors["type"]}
      />
    );
  };

  const PurposeEditor = (options) => {
    const formErrors = formValidation(
      "purpose",
      options.value,
      allEnergyMeters
    );
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="purpose"
        col={12}
        options={
          meterType === "Electricity"
            ? ElectricityuPurposeOptions
            : GasPurposeOptions
        }
        ignoreLabel
        errorMessage={formErrors["purpose"]}
      />
    );
  };

  const MeterIdEditor = (options) => {
    const formErrors = formValidation(
      "meter_id",
      options.value,
      allEnergyMeters
    );

    return (
      <CustomInput
        value={options.value}
        col={12}
        onChange={(e) => {
          const value = e.target.value;
          if (spaceNotAllowed(value)) {
            options.editorCallback(value.toUpperCase());
          } else if (!value) {
            options.editorCallback(value);
          }
        }}
        name="meter_id"
        ignoreLabel
        errorMessage={formErrors["meter_id"]}
      />
    );
  };

  const LocationEditor = (options) => {
    const formErrors = formValidation(
      "location",
      options.value,
      allEnergyMeters
    );
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="location"
        col={12}
        options={LocationOptions}
        ignoreLabel
        errorMessage={formErrors["location"]}
      />
    );
  };

  const AccessEditor = (options) => {
    const formErrors = formValidation("access", options.value, allEnergyMeters);
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="access"
        col={12}
        options={AccessOptions}
        ignoreLabel
        errorMessage={formErrors["access"]}
      />
    );
  };

  const AmountEditor = (options) => {
    const formErrors = formValidation("amount", options.value, allEnergyMeters);
    return (
      <CustomAmountInput
        type="text"
        col={12}
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        spanLabel={
          meterType === "Electricity" ? (
            "kWh"
          ) : (
            <p>
              m<sup>3</sup>
            </p>
          )
        }
        spanExtraClassName={"p-1"}
        name="amount"
        ignoreLabel
        errorMessage={formErrors["amount"]}
      />
    );
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                  fill="#787878"
                ></path>
              </svg>
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#787878"
                  d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                ></path>
              </svg>
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() =>
                    setOpenRightbar({
                      isOpen: true,
                      id: rowData?.id,
                    })
                  }
                >
                  <i className="pi pi-bolt text-dark"></i>
                  Energy Clock
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const rowEditValidator = async (rowData, options) => {
    const { type, purpose, meter_id, location, access, amount } = rowData;
    const typeError = formValidation("type", type, allEnergyMeters);
    const purposeError = formValidation("purpose", purpose, allEnergyMeters);
    const meteridError = formValidation("meter_id", meter_id, allEnergyMeters);
    const locationError = formValidation("location", location, allEnergyMeters);
    const accessError = formValidation("access", access, allEnergyMeters);
    const amountError = formValidation("amount", amount, allEnergyMeters);

    if (
      !typeError["type"] &&
      !purposeError["purpose"] &&
      !meteridError["meter_id"] &&
      !locationError["location"] &&
      !accessError["access"] &&
      !amountError["amount"]
    ) {
      let payload = {
        type: type,
        purpose: purpose,
        meter_id: meter_id,
        location: location,
        access: access,
        amount: amount,
        building_unit: id,
      };
      dispatch(
        updateEnergyMeterAction(rowData?.id, payload, dispatch, (res) => {
            let meters = [...allEnergyMeters];
            meters[options.rowIndex] = rowData;
            setAllEnergyMeters(meters);
            setEditingRows({});
            dispatch(getPorpertyInfoAction(profile?.company, params?.id, dispatch));
        })
      );
    } else {
      return false;
    }
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const handleDeleteEnergyMeter = () => {
    dispatch(
      deleteEnergyMeterAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDelete(allEnergyMeters, setAllEnergyMeters, "", "", idToDelete?.index);
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
          dispatch(getPorpertyInfoAction(profile?.company, params?.id, dispatch));
      })
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy meter from the list?
        This action cannot be undone.
      </span>
    );
  };

  const customHeader = (
    <div className="">
      <div className="sidebar-title">Energy Clock </div>
    </div>
  );

  return (
    <CustomForm title={title}>
      <Toast ref={msgs} />
      <DataTable
        className="col-12"
        value={allEnergyMeters}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="type"
          header="Type"
          editor={(options) => TypeEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="purpose"
          header="Purpose"
          editor={(options) => PurposeEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="meter_id"
          header="Meter ID"
          editor={(options) => MeterIdEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="location"
          header="Location"
          editor={(options) => LocationEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="access"
          header="Access"
          editor={(options) => AccessEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="amount"
          header="Amount"
          body={(rowData) => {
            return rowData?.type == "Gas" ? (
              <span className="lowercase-text">
                {rowData.amount ? (
                  <>
                    {convertAmountInGerman(rowData.amount)}
                    <span
                      dangerouslySetInnerHTML={{ __html: " m<sup>3</sup>" }}
                    />
                  </>
                ) : (
                  "-----"
                )}
              </span>
            ) : (
              <span className="lowercase-text">
                {rowData.amount
                  ? convertAmountInGerman(rowData.amount) + " kWh"
                  : "-----"}
              </span>
            );
          }}
          editor={(options) => AmountEditor(options)}
          style={{ width: "200px" }}
        ></Column>
        <Column
          header="Action"
          headerClassName="custom-header"
          rowEditor
          // headerStyle={{ minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <Sidebar
        header={customHeader}
        className="view-enery-meter-sidebar"
        visible={openRightbar.isOpen}
        position="right"
        onHide={() => setOpenRightbar({ isOpen: false, id: "" })}
      >
        <AddEnergyClocks meterId={openRightbar?.id} />
      </Sidebar>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteEnergyMeter}
        loading={loading}
      />
    </CustomForm>
  );
}
