import React from "react";
import {
  CustomDropDown,
  CustomForm,
  // CustomInput,
  CustomNumberInput2,
  CustomSizeM2,
} from "../../shared/AllInputs";
// import { Messages } from "primereact/messages";
import {
  HeatingTechOptions,
  UnitLocationOptions,
  UnitTypeOptions,
  WaterHeatingOptions,
} from "../../shared/AllDropDownOptions";
// import { Toast } from "primereact/toast";

const BuildingUnit = ({ 
  // type, 
  data, 
  handleChange, 
  // msgToShow
 }) => {
  // const msgs = useRef(null);

  // useEffect(() => {
  //   if (msgToShow?.message) {
  //     msgs?.current?.show({
  //       severity: msgToShow?.type,
  //       detail: msgToShow?.message,
  //     });
  //   }
  // }, [msgToShow]);

  return (
    <>
      <CustomForm title="Building Unit">
        {/* <Messages className="w-full" ref={msgs} /> */}
        {/* <Toast ref={msgs} /> */}
        {/* <CustomInput data={data} onChange={handleChange} name="name" /> */}
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="unitType"
          options={UnitTypeOptions}
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="location"
          options={UnitLocationOptions}
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="floor"
          label="Floor No"
          placeholder="Enter Floor No"
          max={9}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="waterHeating"
          options={WaterHeatingOptions}
        />
        <CustomSizeM2
          data={data}
          onChange={handleChange}
          name="size"
          max={9}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="heatingTechnology"
          options={HeatingTechOptions}
        />
      </CustomForm>
    </>
  );
};

export default React.memo(BuildingUnit);
