import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomImageView, CustomViewInput } from "../../shared/AllViewInputs";
import EditIcon from "../../assets/images/icons/editicon.png";
import { useNavigate, useParams } from "react-router-dom";

const ViewBuildingStructure = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(
              `/real-estate/property/edit/building-structure/${params?.id}`
            )
          }
        ></img>
      </div>
    );
  };
  return (
    <CustomForm title="Building Structure" header={header()}>
      <CustomViewInput name="buildingName" data={data} label={"Building"} />
      <CustomViewInput name="buildingType" data={data} />
      <CustomViewInput name="buildingMaxFloor" data={data} />
      <CustomViewInput name="staircaseAccess" data={data} />
      {/* <CustomViewInput name="size" data={data} label={"Size Sqm"} /> */}
      <CustomViewInput
        name="size"
        data={data}
        spanLabel={
          <span>
            m<sup>2</sup>
          </span>
        }
      />
      <div className="flex gap-4 flex-wrap">
        {data?.document?.map((image, index) => {
          return <CustomImageView image={image.document} />;
        })}
      </div>
    </CustomForm>
  );
};

export default React.memo(ViewBuildingStructure);
