import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "../../../shared/CustomCard";
import { CustomInput, CustomSearch } from "../../../shared/AllInputs";
import CustomButton from "../../../shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "../../Tables/CustomTableContainer";
import CustomOverlay from "../../../shared/CustomOverlay";
import CustomModal from "../../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import manageColICon from "../../../assets/images/icons/manage-col-icon.png";
import { debounce } from "lodash";
import { getUserInfo } from "../../../services/auth";
import { setUserProfile } from "../../../store/slices/userSlice";
import { setLandlordSlice } from "../../../store/slices/landlordSlice";
import { setListDetailSlice } from "../../../store/slices/listSlices";
import formValidation from "../../../utils/validations";
import {
  addHandymanServiceAction,
  deleteHandymanServiceAction,
  getHandymanServiceListAction,
  updateHandymanServiceAction,
} from "../../../store/actions/handymanServiceAction";

const HandymanServiceList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [selectedService, setSelectedService] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [addService, setAddService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState();
  const { landlord } = useSelector((state) => state?.landlord);
  const [filters, setFilters] = useState({
    landlord_name: { value: "" },
    type: { value: "" },
    address: { value: "" },
    email: { value: "" },
    phone_number: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);

  const [serviceData, setServiceData] = useState({
    serviceName: "",
  });

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, []);

  const handleServiceChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, serviceData);
    setServiceData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleAddService = () => {
    if (selectedService) {
      dispatch(
        updateHandymanServiceAction(
          profile?.company,
          selectedService,
          serviceData,
          setLoading,
          dispatch,
          (res) => {
            setAddService(false);
            setSelectedService("");
            getServiceList(profile?.company, rows, page, search);
            setServiceData({ serviceName: "" });
          }
        )
      );
    } else {
      dispatch(
        addHandymanServiceAction(
          profile?.company,
          serviceData,
          setLoading,
          dispatch,
          (res) => {
            setAddService(false);
            getServiceList(profile?.company, rows, page, search);
            setServiceData({ serviceName: "" });
          }
        )
      );
    }
  };

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              debouncedOnChange.current(profile?.company, 10, 1, value);
              setFirst(0);
            }}
            isSearch={isSearch}
            placeholder="Search Service"
          />
        </div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Service"
          onClick={() => {
            setAddService(true);
          }}
        />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Service Name",
        accessor: "name",
        value: true,
        frozen: true,
      },
    ],
    []
  );

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              setAddService(true);
              setServiceData({ serviceName: d?.name });
              setSelectedService(d?.id);
            }}
          >
            <i className="pi pi-file-edit text-dark cursor-pointer"></i>
            Edit
          </li>
          <li className="flex gap-2 text-xs font-medium mb-3">
            <i
              className="pi pi-trash cursor-pointer text-dark"
              onClick={() => {
                setSelectedService(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected handyman service from the
        list? This action cannot be undone.
      </span>
    );
  };

  const addServiceModal = () => {
    return (
      <CustomInput
        extraClassName={"w-full"}
        data={serviceData}
        name="serviceName"
        onChange={handleServiceChange}
      />
    );
  };

  const handleLandlordDelete = () => {
    dispatch(
      deleteHandymanServiceAction(
        profile?.company,
        selectedService,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          setSelectedService("");
          getServiceList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} /> */}
          {/* <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const getServiceList = (company, rows, page, search) => {
    dispatch(
      getHandymanServiceListAction(company, rows, page, search, (res) => {
        setServiceList(res?.options);
        setCount(res?.count);
        setIsSearch(false);
        navigate(`/settings/handyman-services?page=${page}&rows=${rows}`);
      })
    );
  };

  const handleSearch = (company, rows, page, search) => {
    setIsSearch(true);
    getServiceList(company, rows, page, search);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company, rows, page, "");
    }
  }, [profile?.company, rows, page, first]);

  const onPageChange = (e) => {
    navigate(`/settings/handyman-services?page=${e.page + 1}&rows=${e.rows}`);
    dispatch(setListDetailSlice({ page: e.page + 1, row: e.rows }));
    setFirst(e.first);
  };

  return (
    <div>
      <CustomCard title="Handyman Services" header={header()}>
        <CustomTableContainer
          list={serviceList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={landlord?.id}
          slice={setLandlordSlice}
          globalFilters={[]}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleLandlordDelete}
        loading={loading}
      />
      <CustomModal
        isOpen={addService}
        setIsOpen={setAddService}
        heading={selectedService? "Edit Service" : "Add Service"}
        body={addServiceModal}
        submitLabel={selectedService? "Save Changes": "Save"}
        onSubmit={handleAddService}
        loading={loading}
      />
    </div>
  );
};

export default HandymanServiceList;
