import React from "react";
import Topbar from "./Topbar";

export default function BoardingLayout({ children }) {
  return (
    <div className="layout-wrapper">
      <Topbar />
      <div className="layout-main-container">
        <div className={`layout-main-box `}>{children}</div>
      </div>
    </div>
  );
}
