import React, { useRef } from "react";
import CustomCard from "../../shared/CustomCard";
import { CustomSearch } from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";

export default function TenantsList() {
  const menuRight = useRef(null);
  const items = [
    {
      label: "Import",
      icon: "pi pi-download",
    },
    {
      label: "Export",
      icon: "pi pi-upload",
    },
  ];
  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-between flex-wrap">
        <div className="w-12 md:w-9 mr-3">
          <CustomSearch />
        </div>
        <CustomButton size="small" icon="pi pi-plus" label="Add Tenant" />
        <Button
          icon="pi pi-ellipsis-v"
          onClick={(event) => menuRight.current.toggle(event)}
          rounded
          text
          severity="secondary"
        />

        <Menu model={items} popup ref={menuRight} id="popup_menu_right" />
      </div>
    );
  };

  const products = [
    { code: "code", name: "name", category: "category", quantity: "quantity" },
  ];

  return (
    <div>
      <CustomCard title="Tenants List" header={header()}>
        <DataTable value={products} tableStyle={{ minWidth: "50rem" }}>
          <Column
            headerClassName="custom-header"
            field="code"
            header="Code"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="name"
            header="Name"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="category"
            header="Category"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="quantity"
            header="Quantity"
          ></Column>
        </DataTable>
      </CustomCard>
    </div>
  );
}
