import React, { useRef, useEffect, useState, useMemo } from "react";
import CustomCard from "../../../../shared/CustomCard";
import { Menu } from "primereact/menu";
import AllRoles from "./AllRoles";
import RolesPanel from "./RolesPanel";
import Page from "../../../../shared/Page";
import { CustomForm } from "../../../../shared/AllInputs";
import { useDispatch, useSelector } from "react-redux";
import { getRoleInfoAction } from "../../../../store/actions/rolesActions";
import { useNavigate, useParams } from "react-router-dom";
// import { Messages } from "primereact/messages";
import { getUserInfo } from "../../../../services/auth";
import { CustomViewInput } from "../../../../shared/AllViewInputs";
import { TabView, TabPanel } from "primereact/tabview";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
import EditIcon from "../../../../assets/images/icons/editicon.png";
// import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import CustomTableContainer from "../../../Tables/CustomTableContainer";
import { setUserProfile } from "../../../../store/slices/userSlice";

export default function ViewRole() {
  const navigate = useNavigate();
  const params = useParams();
  const menuRight = useRef(null);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState({
    roleName: "",
    employees: "",
  });
  const [permissions, setPermissions] = useState([]);
  // const [rows, setRows] = useState(5);
  // const [count, setCount] = useState(0);
  // const [page, setPage] = useState(0);
  // const [first, setFirst] = useState(0);
  const { profile } = useSelector((state) => state.user);

  const keys = {
    realEsate: "Real Estate",
    administrator: "Administrator",
    tenant: "Tenant",
    settings: "Settings",
  };

  useEffect(() => {
    setRoles(AllRoles);
    if(profile?.company){
      getRoleInfo(profile?.company, params?.id);
    }
  }, [profile, AllRoles]);

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  const getRoleInfo = (companyId, roleId) => {
    dispatch(
      getRoleInfoAction(companyId, roleId, dispatch, (res) => {
        setData({
          roleName: res?.name,
          employees: res?.employees,
        });
        setPermissions(res?.permission);
      })
    );
  };

  useEffect(() => {
    updateRolesWithPermissions();
  }, [permissions]);

  const updateRolesWithPermissions = () => {
    const updatedRoles = { ...AllRoles };
    permissions.forEach((permission) => {
      Object.keys(updatedRoles).forEach((roleKey) => {
        Object.keys(updatedRoles[roleKey]).forEach((sectionKey) => {
          updatedRoles[roleKey][sectionKey].forEach((role) => {
            if (role.value === permission.codename) {
              role.check = true;
            }
          });
        });
      });
    });
    setRoles(updatedRoles);
  };

  const items = [
    {
      label: "Import",
      icon: "pi pi-download",
    },
    {
      label: "Export",
      icon: "pi pi-upload",
    },
  ];

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-between flex-wrap">
        <div className="w-12 md:w-9 mr-3"></div>
        <Menu model={items} popup ref={menuRight} id="popup_menu_right" />
      </div>
    );
  };

  // const actions = (d) => {
  //   return (
  //     <div className="flex justify-content-around">
  //       <i className="pi pi-ellipsis-v cursor-pointer text-dark"></i>
  //     </div>
  //   );
  // };

  const formHeader = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() => navigate(`/administrator/roles/edit/${params?.id}`)}
        ></img>
      </div>
    );
  };

  // const onPageChange = (e) => {
  //   setRows(e.rows);
  //   setPage(e.first);
  //   // getEmployeesList(userInfo?.company, e.rows, e.page + 1);
  // };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        accessor: "name",
        value: true,
      },
      {
        name: "Email",
        accessor: "email",
        value: true,
      },
      {
        name: "Role",
        accessor: "role",
        value: true,
      },
    ],
    []
  );

  return (
    <Page title="View Role" description="Let’s View Details">
      <CustomCard title="Role Details" header={formHeader()}>
        <CustomForm>
          <CustomViewInput data={data} name="roleName" />
        </CustomForm>
      </CustomCard>
      <TabView>
        <TabPanel header="Permissions">
          <CustomCard title="Security Permission" header={header()}>
            <br />
            <div className="grid permission-list">
              <div className="col-12 md:col-4">
                <div className="bg-lightest-main py-3 px-2 border-round-lg">
                  <p className="font-semibold fs-6 px-3 mb-3">Real Estate</p>
                  <div
                    className="accordion add-role-accordion"
                    id="accordionExample"
                  >
                    {roles[keys?.realEsate] &&
                      Object.keys(roles[keys?.realEsate]).map(
                        (parent, index) => {
                          return (
                            <React.Fragment key={index}>
                              <RolesPanel
                                action="view"
                                parentKey={keys?.realEsate}
                                parent={parent}
                                roles={roles}
                                setRoles={setRoles}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="bg-lightest-main py-3 px-2 border-round-lg">
                  <p className="font-semibold fs-6 px-3 mb-3">Administrator</p>
                  <div
                    className="accordion add-role-accordion"
                    id="accordionExample"
                  >
                    {roles[keys?.administrator] &&
                      Object.keys(roles[keys?.administrator]).map(
                        (parent, index) => {
                          return (
                            <React.Fragment key={index}>
                              <RolesPanel
                                parentKey={keys?.administrator}
                                parent={parent}
                                roles={roles}
                                setRoles={setRoles}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="bg-lightest-main py-3 px-2 border-round-lg mb-3">
                  <p className="font-semibold fs-6 px-3 mb-3">Tenant</p>
                  <div
                    className="accordion add-role-accordion"
                    id="accordionExample"
                  >
                    {roles[keys?.tenant] &&
                      Object.keys(roles[keys?.tenant]).map((parent, index) => {
                        return (
                          <React.Fragment key={index}>
                            <RolesPanel
                              parentKey={keys?.tenant}
                              parent={parent}
                              roles={roles}
                              setRoles={setRoles}
                            />
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
                <div className="bg-lightest-main py-3 px-2 border-round-lg">
                  <p className="font-semibold fs-6 px-3 mb-3">Settings</p>
                  <div
                    className="accordion add-role-accordion"
                    id="accordionExample"
                  >
                    {roles[keys?.settings] &&
                      Object.keys(roles[keys?.settings]).map(
                        (parent, index) => {
                          return (
                            <React.Fragment key={index}>
                              <RolesPanel
                                parentKey={keys?.settings}
                                parent={parent}
                                roles={roles}
                                setRoles={setRoles}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </CustomCard>
        </TabPanel>
        <TabPanel header="Employees">
          <CustomTableContainer
            list={data?.employees}
            columns={columns}
            // first={first}
            // rows={rows}
            // count={count}
            activeRow={""}
            notShowSelection={true}
          />
          {/* <DataTable value={data?.employees}>
            <Column
              headerClassName="custom-header"
              field="name"
              header="Name"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              headerClassName="custom-header"
              field="email"
              header="Email"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              headerClassName="custom-header"
              field="role"
              header="Role"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column>
          </DataTable>
          <Paginator
            first={page}
            rows={rows}
            totalRecords={count}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={onPageChange}
            // lazy
          ></Paginator> */}
        </TabPanel>
      </TabView>
    </Page>
  );
}
