import React, { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { useSelector, useDispatch } from "react-redux";
import { getProfileAction } from "../store/actions/userActions";
import { getImageUrl } from "../utils/commonFunctions";
import { ReactComponent as Home } from "../assets/images/svg/HomeFilled.svg";
import { ReactComponent as Logo } from "../assets/images/svg/logo1.svg";
import { getUserInfo } from "../services/auth";
export default function Topbar({ toggleSidebar }) {
  const dispatch = useDispatch();
  const [isUserOnBoarding, setIsUserOnBoarding] = useState(true);
  const { profile } = useSelector((state) => state.user);
  const [profileInfo, setProfileInfo] = useState("");

  useEffect(() => {
    let info = "";
    profile?.firstName ? (info = profile) : (info = getUserInfo());
    setProfileInfo(info);
    setIsUserOnBoarding(info?.is_onboarding);
  }, [profile]);

  // useEffect(() => {
  //   dispatch(getProfileAction());
  // }, [dispatch]);

  let username =
    `${profileInfo?.first_name} ${profileInfo?.last_name}` || "Loading...";
  let userimage = profileInfo?.image;
  const getFirstCharacter = (name = "") => {
    let words = name.split(" ");
    let firstLetters = words.map((word) => word?.charAt(0).toUpperCase());
    firstLetters = firstLetters.slice(0, 2);
    return firstLetters;
  };
  return (
    <div className="layout-topbar px-4">
      <div className="flex">
        {isUserOnBoarding ? (
          <Logo />
        ) : (
          <>
            <div className="my-auto cursor-pointer" onClick={toggleSidebar}>
              <i className="pi pi-bars text-2xl"></i>
            </div>
            <div className="flex my-auto ml-3">
              <Home className="my-auto" />
              <div className="page-title ml-1">Real Estate</div>
            </div>
          </>
        )}
      </div>

      <div className="top-menu">
        {!isUserOnBoarding && (
          <div className="my-auto mr-4">
            <i className="pi pi-bell text-xl" />
          </div>
        )}

        <div className="flex">
          {userimage ? (
            <Avatar
              image={getImageUrl(userimage)}
              shape="circle"
              className="my-auto"
            />
          ) : (
            <Avatar
              label={getFirstCharacter(profileInfo?.first_name)}
              size="small"
              shape="circle"
              className="my-auto"
            />
          )}
          <div className="flex ml-2 my-auto ">
            <span className="username firstletter_capital">{username}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
