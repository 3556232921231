import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import {
  getPorpertyInfoAction,
  searchPropertyIdAction,
  updatePropertyAction,
} from "../../../../store/actions/propertyActions";
import formValidation from "../../../../utils/validations";
import {
  addBuildingAction,
  searchBuildingAction,
} from "../../../../store/actions/buildingActions";
import {
  checkIsValueGreater,
  handleFileConversion,
  // handleFormatDate,
  handleSetAddress,
  showFormErrors,
} from "../../../../utils/commonFunctions";
import {
  addBuildingUnitAction,
  // getBuildingUnitAction,
  updateBuildingUnitAction,
} from "../../../../store/actions/buildingUnitActions";
import {
  addLandlordAction,
  getPropertyLandlordAction,
  searchLandlordAction,
  updateLandlordAction,
} from "../../../../store/actions/landlordActions";
import {
  addContractAction,
  addRoomAndCostAction,
  getPropertyContractAction,
  searchContractAction,
  updateContractAction,
} from "../../../../store/actions/contractActions";
import { debounce } from "lodash";
// import { setPropertySlice } from "../../../../store/slices/propertySlice";
import {
  checkBankErrors,
  checkContactErrors,
  checkEnergyMeterErrors,
  checkExtraCostErrors,
  checkFurnitureErrors,
  checkOptionalMaturtiesErrors,
  //rooms
  checkRoomErrors,
  // handleBankSave,
  // handleContactSave,
  // handleCostSave,
  // handleEnergyMeterSave,
  // handleFurnitureSave,
  // handleMaturitiesSave,
} from "../../../../shared/Components/commonFunctions";
import {
  handleSetBuildingOptions,
  handleSetBuildingSturctureData,
  handleSetBuildingUnitData,
  handleSetContractData,
  handleSetLandlordData,
  handleSetPropertyData,
  handleSetRentData,
} from "../PropertyCommonFunctions";
import { setUserProfile } from "../../../../store/slices/userSlice";
import { setPropertyContractSlice, setPropertySlice } from "../../../../store/slices/propertySlice";

const ViewEditPropertyContainer = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [activeIndex, setActiveIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [tabToActive, setTabToActive] = useState("");
  const routes = [
    { index: 0, route: "building-structure" },
    { index: 1, route: "building-unit" },
    { index: 2, route: "landlord-info" },
    { index: 3, route: "contractual-info" },
    { index: 4, route: "rent-and-cost" },
    { index: 5, route: "annual-statement" },
  ];
  const [action, setAction] = useState("");
  const [route, setRoute] = useState("");
  const [user, setUser] = useState("");
  const userProfileRef = useRef(user);
  const [msgToShow, setMsgToShow] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const propertyDataRedux = useSelector((state) => state?.property?.property);
  const buildingStructureDataRedux = propertyDataRedux?.building_data;
  const buildingUnitDataRedux = propertyDataRedux?.building_unit;
  const landlordDataRedux = propertyDataRedux?.landlordData
  const ContractDataRedux = propertyDataRedux?.contractData

  const modalContent = () => {
    return (
      <span>Do you want to save changes? This action cannot be undone.</span>
    );
  };

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    if(profile?.firstName){
      info = profile
    } else {
      info = userInfo
      dispatch(setUserProfile(userInfo))
    }

    setUser(info);
    if (info?.company) {
      getPropertyInfo(info?.company);
    }
  }, []);

  useEffect(() => {
    userProfileRef.current = user; // Update the ref with the latest userProfile
  }, [user]);

  useEffect(() => {
    let path = location?.pathname.split("/");
    let lastSegment = path[path.length - 2];
    let action = path[path.length - 3];
    setAction(action);
    setRoute(lastSegment);
    routes?.map((item) => {
      if (item.route === lastSegment && activeIndex !== item?.index) {
        setActiveIndex(item?.index);
      } else if (lastSegment === "property") {
        setActiveIndex(activeIndex || 0);
      }
    });
  }, [location?.pathname]);

  const handleTabChange = (index) => {
    routes?.map((item) => {
      if (item?.index === index) {
        navigate(`/real-estate/property/view/${item?.route}/${params?.id}`);
        setActiveIndex(item?.index);
      }
    });
  };

  //Property
  const [propertyIdOptions, setPropertyIdOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  // const [propertyId, setPropertyId] = useState(params?.id);
  const [PropertyData, setPropertyData] = useState({
    propertyId: "",
    address: "",
    zipCode: "",
    city: "",
    houseNo: "",
    street: "",
    cityDivision: "",
    country: "",
  });

  const [buildingStuctureData, setBuildingStuctureData] = useState({
    building: "",
    buildingName: "",
    buildingType: "",
    buildingMaxFloor: "",
    staircaseAccess: "",
    size: "",
    longitude: "",
    latitude: "",
    address: "",
    document: [],
  });

  const [fileKey, setFileKey] = useState(0);
  const [sturcureDocuments, setStructureDocuments] = useState([]);

  const handleStructureFileChange = async (event) => {
    const file = event.target.files[0];
    setStructureDocuments([...sturcureDocuments, file]);

    const convertedFile = await handleFileConversion(file);
    let douments = [...buildingStuctureData.document];
    douments.push({ document: convertedFile });
    setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }));
  };

  const handleStructureFileDelete = (index) => {
    let arr = [...sturcureDocuments];
    arr?.splice(index, 1);
    setStructureDocuments(arr);

    let douments = [...buildingStuctureData.document];
    douments?.splice(index, 1);
    setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }));

    if (sturcureDocuments.length == 1) {
      setStructureDocuments([]);
      setFileKey((prevKey) => prevKey + 1);
    }
  };

  const [buildingOptions, setBuildingOptions] = useState([
    { name: "Add New", value: "add_new" },
  ]);

  const getPropertyInfo = (company) => {
    let options = [{ name: "Add New", value: "add_new" }];
    dispatch(
      getPorpertyInfoAction(company, params?.id, dispatch, (res) => {
        handleSetPropertyData(res, setPropertyData);
        const buildingStrucure = res?.building_data;
        options.push({
          ...buildingStrucure,
          name: buildingStrucure?.name,
          value: buildingStrucure?.id,
        });
        setBuildingOptions(options);
        handleSetBuildingSturctureData(
          "",
          buildingStrucure,
          setBuildingStuctureData,
          setStructureDocuments
        );
      })
    );
  };

  const propertyChange = ({ name, value }) => {
    // if (name === "propertyId" && value) {
    //   propertyIdChange.current(value);
    // }

    const formErrors = formValidation(name, value, PropertyData);
    setPropertyData((prev) => ({ ...prev, [name]: value, formErrors }));

    if (name === "address") {
      setSelectedAddress("");
    }
  };

  const handleBuildingSearch = (company, lng, lat) => {
    let options = [{ name: "Add New", value: "add_new" }];
    dispatch(
      searchBuildingAction(company, lng, lat, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          info?.map((item) => {
            options.push({
              ...item,
              name: item?.name,
              value: item?.id,
            });
          });
        }
        setBuildingOptions(options);
      })
    );
  };

  const buildingIdChange = useRef(debounce(handleBuildingSearch, 1000));

  useMemo(() => {
    if (selectedAddress) {
      handleSetAddress(selectedAddress, PropertyData, setPropertyData);

      if (selectedAddress?.lng && selectedAddress?.lat) {
        buildingIdChange.current(
          user?.company,
          selectedAddress?.lng,
          selectedAddress?.lat
        );
        setBuildingStuctureData((prevState) => ({
          ...prevState,
          id: "",
        }));
      }
    }
  }, [selectedAddress]);

  const handlePropertySuggestions = (value) => {
    let payload = {
      property_id: value,
    };

    dispatch(
      searchPropertyIdAction(payload, (res) => {
        if (!res?.success) {
          const formErrors = { ["propertyId"]: res?.message };
          setPropertyData((prev) => ({
            ...prev,
            ["propertyId"]: value,
            formErrors,
          }));
          if (res?.data?.results) {
            setPropertyIdOptions(res?.data?.results);
          }
        }
      })
    );
  };

  const propertyIdChange = useRef(debounce(handlePropertySuggestions, 1000));

  const propertySubmit = () => {
    if (showFormErrors(PropertyData, setPropertyData)) {
      dispatch(
        updatePropertyAction(
          user?.company,
          params?.id,
          PropertyData,
          setLoading,
          dispatch,
          (res) => {
            handleSetPropertyData(res, setPropertyData);
            navigate(`/real-estate/property/view/property/${params?.id}`);
          }
        )
      );
    }
  };

  //building structure
  const BuildingStuctureHandleChange = ({ name, value, ...rest }) => {
    let ignoreInputs = ["longitude", "latitude", "address"];
    const { files, type } = rest?.target;
    const formErrors = formValidation(
      name,
      value,
      buildingStuctureData,
      ignoreInputs
    );

    if (type === "file") {
      setBuildingStuctureData((prev) => ({
        ...prev,
        [name]: files[0],
        formErrors,
      }));
    } else if (name === "building") {
      const selectedBuilding = buildingOptions.find(
        (item) => item?.id === value
      );
      handleSetBuildingSturctureData(
        value,
        selectedBuilding,
        setBuildingStuctureData
      );
    } else {
      setBuildingStuctureData((prev) => ({
        ...prev,
        [name]: value,
        formErrors,
      }));
    }
  };

  const BuildingStuctureSubmit = async () => {
    if (
      showFormErrors(buildingStuctureData, setBuildingStuctureData, [
        "document",
        "longitude",
        "latitude",
        "address"
      ])
    ) {
      let data = {
        ...buildingStuctureData,
        longitude: selectedAddress?.lng || buildingStuctureData?.longitude,
        latitude: selectedAddress?.lat || buildingStuctureData?.latitude,
        address: selectedAddress?.address || buildingStuctureData?.address,
      };
      // if (buildingStuctureData?.building !== "add_new") {
      //   dispatch(
      //     updateBuildingAction(
      //       userProfile?.company,
      //       buildingStuctureData?.building,
      //       payload,
      //       (res) => {
      //         if (res?.success) {
      //           const info = res?.data?.results;
      //           handleSetBuildingSturctureData(
      //             "",
      //             info,
      //             setBuildingStuctureData,
      //             setStructureDocuments
      //           );
      //           setMsgToShow({ type: "success", message: res?.message });
      //           navigate(
      //             `/real-estate/property/view/building-structure/${propertyId}`
      //           );
      //         } else {
      //           setMsgToShow({ type: "error", message: res?.message });
      //         }
      //         setLoading(false);
      //       }
      //     )
      //   );
      // } else {
      dispatch(
        addBuildingAction(
          user?.company,
          data,
          setLoading,
          dispatch,
          (res) => {
            handleSetBuildingSturctureData("", res, setBuildingStuctureData, setStructureDocuments);
            handleSetBuildingOptions(res, buildingOptions, setBuildingOptions); //17-9-24
            dispatch(setPropertySlice(res))
            const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
            (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/building-structure/${params?.id}`);
          }
        )
      );
    }
  };

  //building unit
  const [buildingUnitData, setBuildingUnitdata] = useState({
    id: "",
    // name: "",
    unitType: "Residential",
    location: "",
    floor: "",
    waterHeating: "",
    size: "",
    heatingTechnology: "",
    rooms: "",
    energy_meters: "",
  });

  //rooms
  const [allRooms, setAllRooms] = useState([
    // { id: null, doors: 1, size: null, name: null, status: null },
  ]);
  // const [roomErrors, setRoomErrors] = useState([]);

  //energy meters
  const [allEnergyMeters, setAllEnergyMeters] = useState([
    // {
    //   id: null,
    //   purpose: "",
    //   type: "",
    //   meter_id: "",
    //   location: "",
    //   access: "",
    //   amount: "",
    //   status: "",
    // },
  ]);

  // const [meterErrors, setMeterErrors] = useState([]);

  // const getBuildingUnitInfo = (id) => {
  //   dispatch(
  //     getBuildingUnitAction(id, (res) => {
  //       if (res?.success) {
  //         const results = res?.data?.results;
  //         handleSetBuildingUnitData(
  //           results,
  //           setBuildingUnitdata,
  //           setAllRooms,
  //           setAllEnergyMeters
  //         );
  //       }
  //     })
  //   );
  // };

  const BuildingUnitChange = ({ name, value }) => {
    let formErrors = formValidation(name, value, buildingUnitData);
    if (name === "floor") {
      let isGreater = checkIsValueGreater(
        value,
        buildingStuctureData.buildingMaxFloor
      );
      if (isGreater) {
        formErrors = {
          ["floor"]:
            "The floor number must be lower than the maximum floor of the building.",
        };
      } else {
        formErrors = formValidation(name, value, buildingUnitData, [
          "id",
          "name",
        ]);
      }
    }

    if (name === "size") {
      let isGreater = checkIsValueGreater(value, buildingStuctureData.size);
      if (isGreater) {
        formErrors = {
          ["size"]:
            "The size must be lower than the maximum size of the building.",
        };
      } else {
        formErrors = formValidation(name, value, buildingUnitData, ["id"]);
      }
    }

    setBuildingUnitdata((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const BuildingUnitSubmit = () => {
    const rooms = checkRoomErrors(allRooms, setAllRooms, buildingUnitData?.size);
    const energyMeters = checkEnergyMeterErrors(allEnergyMeters, setAllEnergyMeters);

    if (showFormErrors(buildingUnitData, setBuildingUnitdata) && !rooms.hasErrors && !energyMeters?.hasErrors) {
      let data = {
        ...buildingUnitData,
        propertyId:params?.id,
        rooms: rooms?.rooms,
        energy_meters: energyMeters?.energyMeters,
      };

      if (buildingUnitData?.id) {
        dispatch(
          updateBuildingUnitAction(buildingUnitData?.id, data, setLoading, dispatch, (res) => {
              handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters);
              const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
              (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/building-unit/${params?.id}`);
            }
          )
        );
      } else {
        dispatch(
          addBuildingUnitAction(data, setLoading, dispatch, (res) => {
            handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters);
            const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
            (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/building-unit/${params?.id}`);
          })
        );
      }
    }
  };

  //bankaccounts
  const [allBankAccounts, setAllBankAccounts] = useState([
    // {
    //   id: null,
    //   purpose: "All Combined",
    //   owner_name: "",
    //   iban: "",
    //   bic: "",
    //   status: "",
    // },
  ]);

  // const [bankErrors, setBankErrors] = useState([]);

  //contacts
  const [landlordOptions, setLandlordOptions] = useState([]);

  const [allContacts, setAllContacts] = useState([
    // {
    //   type: "",
    //   name: "",
    //   role: "",
    //   mobile: "",
    //   office_phone: "",
    //   email: "",
    // },
  ]);
  // const [contactErrors, setContactErrors] = useState([]);

  //furniture
  const [allFurniture, setAllFurniture] = useState([]);
  // const [furnitureErrors, setFurnitureErrors] = useState([]);

  //landlord
  const [landlordAddress, setLandlordAddress] = useState();
  const [landLordData, setLandLordData] = useState({
    id: "",
    existingLandlord: "",
    landlordName: "",
    landlordType: "",
    address: "",
    street: "",
    zipCode: "",
    city: "",
    cityDivision: "",
    country: "",
    email: "",
    phoneNumber: "",
    directDebit: "",
    furnitureProvided: "",
    landlord_furniture: [],
  });

  // const [disableLandloard, setDisableLandlord] = useState(false);

  const getLandlordInfo = (id) => {
    dispatch(
      getPropertyLandlordAction(id, dispatch, (res) => {
        handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture);
        // setDisableLandlord(true);        
      })
    );
  };

  const LandlordChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, landLordData);
    setLandLordData((prev) => ({ ...prev, [name]: value, formErrors }));

    // if (name === "existingLandlord" && value) {
    //   landlordIdChange.current(value);
    //   setLandLordData((prevState) => ({
    //     ...prevState,
    //     id: "",
    //   }));
    // }
  };

  useMemo(() => {
    if (landlordAddress) {
      handleSetAddress(landlordAddress, landLordData, setLandLordData, [
        "longitude",
        "latitude",
      ]);
    }
  }, [landlordAddress]);

  useEffect(() => {
    if (!landLordData?.landlord_furniture.length) {
      if (
        landLordData?.furnitureProvided === "yes" &&
        allFurniture.length === 0
      ) {
        setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "" }]);
      } else if (landLordData?.furnitureProvided === "no") {
        setAllFurniture([]);
      }
    }
  }, [landLordData]);

  const handleLandlordSearch = (company, value) => {
    dispatch(
      searchLandlordAction(company, value, (res) => {
          setLandlordOptions(res);
      })
    );
  };

  const landlordIdChange = useRef(
    debounce((value) => {
      handleLandlordSearch(userProfileRef.current?.company, value);
    }, 1000)
  );

  const LandlordSubmit = () => {
    const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts);
    const furniture = checkFurnitureErrors(allFurniture, setAllFurniture);
    const contacts = checkContactErrors(allContacts, setAllContacts, ["office_phone", "email"]);
    if (showFormErrors(landLordData, setLandLordData) && !bankAccounts?.hasErrors && !contacts?.hasErrors && !furniture?.hasErrors) {
      let data = {
        ...landLordData,
        propertyId: params?.id,
        bank_accounts: bankAccounts?.bankAccounts,
        contacts: contacts?.contacts,
        landlord_furniture: furniture?.furniture,
    }
      setLoading(true);
      if (landLordData?.id) {
        dispatch(
          updateLandlordAction(
            user?.company,
            landLordData?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture);
              const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
              (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/landlord-info/${params?.id}`);
            }
          )
        );
      } else {
        dispatch(
          addLandlordAction(
              user?.company,
              data,
              setLoading,
              dispatch,
              (res) => {
                handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
                const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/landlord-info/${params?.id}`);
              }
          )
      )
      }
    }
  };

  useEffect(() => {
    if (landLordData?.existingLandlord?.landlord_id) {
      const landlord = landLordData.existingLandlord;
      handleSetLandlordData(landlord, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture);
    }
  }, [landLordData?.existingLandlord]);

  //contractual
  const [allMaturities, setAllMaturities] = useState([]);
  // const [maturityErrors, setMaturityErrors] = useState([]);

  // const [disableContract, setDisableContract] = useState(false);
  const [ContractOptions, setContractOptions] = useState([]);
  const [contractualData, setContractualData] = useState({
    id: "",
    existingContract: "",
    movingDate: "",
    estateCompany: "",
    contractualSize: "",
    contractualNo: 0,
    maturityType: "",
    maturityDate: "",
    maturityExtension: "",
    noticePeriod: "",
    depositType: "",
    amount: "",
  });

  const [rentAndCostData, setRentAndCostData] = useState({
    amount: "",
    amountFirstMonth: "",
    rentRiseType: "",
    intervalType: "",
    riseInterval: "",
    startDate: "",
    endDate: "",
    riseAmount: "",
    propertyRent: "Size",
    landlord: "",
  });
  const [isContactExist, setIsContactExist] = useState("");
  const [allExtraCost, setAllExtraCost] = useState([]);
  // const [costErrors, setCostErrors] = useState([]);

  const getContractualInfo = (id) => {
    dispatch(
      getPropertyContractAction(id, dispatch, (res) => {
          // setDisableContract(true);
          handleSetContractData(res, setContractualData, setAllMaturities);
          handleSetRentData(res, setRentAndCostData, setAllExtraCost);
      })
    );
  };

  const ContractualChange = ({ name, value }) => {
    let ignoreInputs = [];
    if (name === "maturityType" && (value === "None" || !value)) {
      ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod");
    }

    if (contractualData?.maturityType === "Automatic Renewal") {
      ignoreInputs.push("noticePeriod");
    }

    if (contractualData.depositType === "None") {
      ignoreInputs.push("amount");
      setContractualData((prev) => ({ ...prev, ["amount"]: null, formErrors }));
    }
    if (name === "maturityType" && value === "Automatic Renewal") {
      setContractualData((prev) => ({
        ...prev,
        ["maturityExtension"]: 12,
        formErrors,
      }));
    }

    const formErrors = formValidation(
      name,
      value,
      contractualData,
      ignoreInputs
    );
    setContractualData((prev) => ({ ...prev, [name]: value, formErrors }));

    // if (name === "existingContract" && value) {
    //   // contractIdChange.current(value);
    //   setContractualData((prevState) => ({
    //     ...prevState,
    //     id: "",
    //   }));
    // }
  };

  const handleContractSearch = (company, value) => {
    dispatch(
      searchContractAction(company, value, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          if (!info.length) {
            setIsContactExist(false);
          } else {
            setIsContactExist(true);
          }
          setContractOptions(info);
        }
      })
    );
  };

  const contractIdChange = useRef(
    debounce((value) => {
      handleContractSearch(userProfileRef.current?.company, value);
    }, 1000)
  );

  const ContractualSubmit = () => {
    const maturities = checkOptionalMaturtiesErrors(allMaturities, setAllMaturities);
    let ignoreInputs = [];
    if(contractualData?.maturityType === "None" || (!contractualData?.maturityType && contractualData?.formErrors)) {
      ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod");
      delete contractualData?.formErrors?.maturityDate;
      delete contractualData?.formErrors?.maturityExtension;
      delete contractualData?.formErrors?.noticePeriod;
    }
    if(contractualData?.maturityType === "Optional Renewal") {
      ignoreInputs.push("noticePeriod", "maturityExtension");
      delete contractualData?.formErrors?.noticePeriod;
    }

    if (contractualData?.depositType === "None") {
      ignoreInputs.push("amount");
    }

    if (showFormErrors(contractualData, setContractualData, ignoreInputs) && !maturities.hasErrors) {
      let data = {
        ...contractualData,
        landlordId: landLordData?.id,
        property: params?.id,
        optional_maturities: maturities?.optionalMaturities,
      }

      if (contractualData?.id) {
        dispatch(
          updateContractAction(
            user?.company,
            contractualData?.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              handleSetContractData(res, setContractualData, setAllMaturities);
              dispatch(setPropertyContractSlice(res))
              const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
              (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/contractual-info/${params?.id}`);
            }
          )
        );
      } else {
        dispatch(
          addContractAction(user?.company, data, setLoading, dispatch, (res) => {
            handleSetContractData(res, setContractualData, setAllMaturities);
            dispatch(setPropertyContractSlice(res))
            const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
            (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/contractual-info/${params?.id}`);
          })
        );
      }
    }
  };

  useEffect(() => {
    if (contractualData?.existingContract?.contract_id) {
      const contract = contractualData.existingContract;
      handleSetContractData(contract, setContractualData, setAllMaturities);
    }
  }, [contractualData?.existingContract]);

  //rent and cost
  const RentAndCostChange = ({ name, value }) => {
    let ignoreInputs = [];
    if (name === "rentRiseType" && (value === "None" || !value)) {
      ignoreInputs.push(
        "intervalType",
        "riseInterval",
        "startDate",
        "riseAmount",
        "endDate"
      );
    }
    const formErrors = formValidation(
      name,
      value,
      rentAndCostData,
      ignoreInputs
    );
    setRentAndCostData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const RentAndCostSubmit = () => {
    const extraCosts = checkExtraCostErrors(allExtraCost, setAllExtraCost);
    let ignoreInputs = [];

    if (rentAndCostData?.rentRiseType === "None" || (!rentAndCostData?.rentRiseType && rentAndCostData?.formErrors)) {
      ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate");
      delete rentAndCostData?.formErrors?.intervalType;
      delete rentAndCostData?.formErrors?.riseInterval;
      delete rentAndCostData?.formErrors?.startDate;
      delete rentAndCostData?.formErrors?.riseAmount;
      delete rentAndCostData?.formErrors?.endDate;
    }

    if (showFormErrors(rentAndCostData, setRentAndCostData, ignoreInputs) && !extraCosts?.hasErrors) {
      // let payload = {
      //   rent_amount: rentAndCostData?.amount,
      //   rent_amount_first_month: rentAndCostData?.amountFirstMonth,
      //   rent_rise_type: rentAndCostData?.rentRiseType,
      //   property_rent: rentAndCostData?.propertyRent,
      //   landlord: rentAndCostData?.landlord,
      // };

      // if (rentAndCostData?.rentRiseType !== "None") {
      //   payload.rent_rise_interval = rentAndCostData?.riseInterval;
      //   payload.rent_rise_interval_type = rentAndCostData?.intervalType;
      //   payload.date_of_first_rise = startDate;
      //   payload.date_of_last_rise = endDate;
      //   payload.rent_rise_amount = rentAndCostData?.riseAmount;
      // }
      // // let extraCost = allExtraCost?.map((cost) => {
      // //   if (cost?.applicable_tenant_rent === "yes") {
      // //     return { ...cost, applicable_tenant_rent: true };
      // //   } else if (cost?.applicable_tenant_rent === "no") {
      // //     return { ...cost, applicable_tenant_rent: false };
      // //   }
      // // });

      // if (!extraCosts?.hasErrors && extraCosts?.extraCosts?.length) {
      //   let extraCost = extraCosts?.extraCosts?.map((cost) => {
      //     if (cost?.applicable_tenant_rent === "yes") {
      //       return { ...cost, applicable_tenant_rent: true };
      //     } else if (cost?.applicable_tenant_rent === "no") {
      //       return { ...cost, applicable_tenant_rent: false };
      //     }
      //   });
      //   payload.extra_costs = extraCost;
      // }

      let data = {
        ...rentAndCostData,
        extra_costs: extraCosts?.extraCosts
      }

      dispatch(
        addRoomAndCostAction(
          user.company,
          contractualData?.id,
          data,
          setLoading,
          dispatch,
          (res) => {
            handleSetRentData(res, setRentAndCostData, setAllExtraCost);
            const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
            (isActive)? handleTabChange(tabToActive) : navigate( `/real-estate/property/view/rent-and-cost/${params?.id}`);
          }
        )
      );
    }
  };

  useMemo(() => {
    if (activeIndex === 1) {
        buildingUnitDataRedux && handleSetBuildingUnitData(buildingUnitDataRedux, setBuildingUnitdata, setAllRooms, setAllEnergyMeters);
    } else if (activeIndex === 2) {
        landlordDataRedux? handleSetLandlordData(landlordDataRedux, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture) : getLandlordInfo(params?.id);
    } else if (activeIndex === 3 || activeIndex === 4) {
        !landlordDataRedux && getLandlordInfo(params?.id)
        ContractDataRedux? handleSetContractData(ContractDataRedux, setContractualData, setAllMaturities) : getContractualInfo(params?.id);
        ContractDataRedux && handleSetRentData(ContractDataRedux, setRentAndCostData, setAllExtraCost);
    }
  }, [
      location?.pathname, 
      // activeIndex, 
      buildingUnitDataRedux, 
      landlordDataRedux,
      ContractDataRedux
  ]);
  
  const onSubmit = (route) => {
    setIsOpen(false);
    if (route === "property") {
      propertySubmit();
    } else if (route === "building-structure") {
      BuildingStuctureSubmit();
    } else if (route === "building-unit") {
      BuildingUnitSubmit();
    } else if (route === "landlord-info") {
      LandlordSubmit();
    } else if (route === "contractual-info") {
      ContractualSubmit();
    } else if (route === "rent-and-cost") {
      RentAndCostSubmit();
    }
  };
  
  const handleCancel = () => {
    if (route === "property" || route === "building-structure") {
      propertyDataRedux? handleSetPropertyData(propertyDataRedux, setPropertyData) : getPropertyInfo(user?.company);
      buildingStructureDataRedux && handleSetBuildingSturctureData("", buildingStructureDataRedux || buildingStuctureData, setBuildingStuctureData, setStructureDocuments);
    } else if (route === "building-unit") {
      // getBuildingUnitInfo(params?.id);
      handleSetBuildingUnitData(buildingUnitDataRedux || buildingUnitData, setBuildingUnitdata, setAllRooms, setAllEnergyMeters);
    } else if (route === "landlord-info") {
      // getLandlordInfo(params?.id);
      handleSetLandlordData(landlordDataRedux || landLordData, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
    } else if (route === "contractual-info" || route === "rent-and-cost") {
      handleSetContractData(ContractDataRedux || contractualData, setContractualData, setAllMaturities)
      handleSetRentData(ContractDataRedux || rentAndCostData, setRentAndCostData, setAllExtraCost) 
      // getLandlordInfo(params?.id);
      // getContractualInfo(params?.id);
    }
    const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
    (isActive)? handleTabChange(tabToActive) : navigate(`/real-estate/property/view/${route}/${params?.id}`);
  };

  return {
    action,
    route,
    activeIndex,
    handleTabChange,
    onSubmit,
    propertyIdOptions,
    setTabToActive,

    loading,
    msgToShow,
    handleCancel,
    isOpen,
    setIsOpen,
    modalContent,

    //Property
    PropertyData,
    // propertyId,
    propertyChange,
    propertySubmit,
    setSelectedAddress,
    propertyIdChange,

    //Building Structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    fileKey,
    sturcureDocuments,
    handleStructureFileChange,
    handleStructureFileDelete,

    //Building Unit
    buildingUnitData,
    BuildingUnitChange,
    allRooms,
    setAllRooms,
    // roomErrors,
    // setRoomErrors,

    allEnergyMeters,
    setAllEnergyMeters,
    // meterErrors,
    // setMeterErrors,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    // disableLandloard,
    setLandlordAddress,
    landlordIdChange,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,
    // bankErrors,
    // setBankErrors,

    //contacts
    allContacts,
    setAllContacts,

    // contactErrors,
    // setContactErrors,
    // disableContract,

    //furniture
    allFurniture,
    setAllFurniture,
    // furnitureErrors,
    // setFurnitureErrors,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    // maturityErrors,
    // setMaturityErrors,
    contractIdChange,
    isContactExist,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,
    // RentAndCostSubmit,

    //extra cost
    allExtraCost,
    setAllExtraCost,
    // costErrors,
    // setCostErrors,
  };
};

export default ViewEditPropertyContainer;
