import React, { useEffect, useMemo, useRef, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import EditIcon from "../../assets/images/icons/editicon.png";
import CommonViewTable from "../Tables/CommonViewTable";
// import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";

export default function AnnualStatement() {
  const msgs = useRef(null);
  // const navigate = useNavigate();
  // const params = useParams();
  // const dispatch = useDispatch();
  // const [data, setData] = useState();

  const costColumns = useMemo(
    () => [
      {
        name: "Year",
        accessor: "option",
      },
      {
        name: "In-status",
        accessor: "deadline",
      },
      {
        name: "Out-status",
        accessor: "option",
      },
      {
        name: "Printing date",
        accessor: "option",
      },
      {
        name: "Property ID",
        accessor: "option",
      },
    ],
    []
  );

  const actions = () => {
    return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  };

  // useEffect(() => {
  //   getContractualInfo(params?.id);
  // }, [params?.id]);

  // const header = () => {
  //   return (
  //     <div className="flex justify-content-around cursor-pointer">
  //       <img
  //         src={EditIcon}
  //         alt=""
  //         onClick={() => navigate(`/administrator/office/edit/${params?.id}`)}
  //       ></img>
  //     </div>
  //   );
  // };



  return (
    <>
      {/* <Messages className="w-full" ref={msgs} /> */}
      <Toast ref={msgs} />
      <CommonViewTable
        title={"Annual Statement"}
        list={[]}
        columns={costColumns}
        actions={actions}
      />
    </>
  );
}
