import React, { useEffect, useRef, useState } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
} from "../../shared/AllInputs";
import CustomButton from "../../shared/CustomButton";

import {
  // HandleAdd,
  HandleAddNew,
  // handleCancel,
  handleCancelNew,
  handleChangeNew,
  // handleDelete,
  handleDeleteNew,
  handlEdit,
  // handleObjChange,
  // handleSave,
  handleSaveNew,
} from "./commonFunctions";
import {
  ExtraCostPayemntType,
  ExtraCostType,
  YesNoOptions,
} from "../AllDropDownOptions";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import {
  deleteExtraCostAction,
  getExtraCostAction,
} from "../../store/actions/contractActions";
import { useParams } from "react-router-dom";
const AddExtraCost = ({
  // type,
  allExtraCost,
  setAllExtraCost,
  // errors,
  // setErrors,
}) => {
  // const params = useParams()
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const InputObj = {
    id: "",
    type: "",
    payment_type: "",
    amount: "",
    amount_first_month: "",
    amount_last_month: "",
    applicable_tenant_rent: "",
    status: "",
  };

  // const errorObj = {
  //   type: "",
  //   payment_type: "",
  //   amount: "",
  //   amount_first_month: "",
  //   amount_last_month: "",
  //   applicable_tenant_rent: "",
  // };

  const ignoreInputs = ["status", "id"];

  // useEffect(() => {
  //   if (allExtraCost?.length) {
  //     let allErrors = [...errors];
  //     let myErrors = allExtraCost?.map((meter, roomIndex) => {
  //       let error = allErrors.find(
  //         (err, errorIndex) => errorIndex === roomIndex
  //       );
  //       if (error) {
  //         return error;
  //       } else {
  //         return errorObj;
  //       }
  //     });
  //     setErrors(myErrors);
  //   }
  // }, [allExtraCost]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={
          () => HandleAddNew(InputObj, allExtraCost, setAllExtraCost)
          // HandleAdd(
          //   InputObj,
          //   allExtraCost,
          //   setAllExtraCost,
          //   errorObj,
          //   errors,
          //   setErrors
          // )
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected extra cost from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleDeleteExtraCost = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteExtraCostAction(idToDelete?.id, setLoading, dispatch, (res) => {
            handleDeleteNew(allExtraCost, setAllExtraCost, idToDelete?.index);
            // handleDelete(
            //   allExtraCost,
            //   setAllExtraCost,
            //   errors,
            //   setErrors,
            //   idToDelete?.index
            // );
            setIdToDelete({ id: "", index: "" });
            setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allExtraCost, setAllExtraCost, idToDelete?.index);
      // handleDelete(
      //   allExtraCost,
      //   setAllExtraCost,
      //   errors,
      //   setErrors,
      //   idToDelete?.index
      // );
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const getCostInfo = (id, index) => {
    dispatch(
      getExtraCostAction(id, (res) => {
        if (res?.success) {
          const info = res?.data?.results;
          handleCancelNew(info, index, allExtraCost, setAllExtraCost);
          // handleCancel(
          //   info,
          //   index,
          //   allExtraCost,
          //   setAllExtraCost,
          //   errorObj,
          //   errors,
          //   setErrors
          // );
        }
      })
    );
  };

  return (
    <>
      <CustomForm title={"Add Extra Cost"} header={header}>
        <Toast ref={msgs} />
        <table className="financial-table w-full mt-3">
          <thead>
            <tr>
              <th>Type</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Amount First Month</th>
              <th>Amount Last Month</th>
              <th>Applicable for tenant Rent</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="financial-table-form">
            {allExtraCost?.map((cost, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <CustomDropDown
                        value={cost?.type}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="type"
                        options={ExtraCostType}
                        disabled={cost?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={cost?.formErrors?.type}
                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={cost?.payment_type}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="payment_type"
                        col={12}
                        options={ExtraCostPayemntType}
                        disabled={cost?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={cost?.formErrors?.payment_type}
                      />
                    </td>
                    <td>
                      <CustomAmountInput
                        value={cost?.amount}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              "amount",
                              e?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   "amount",
                          //   e?.value,
                          //   index
                          // )
                        }
                        name="amount"
                        ignoreLabel
                        disabled={cost?.status === "save" ? true : false}
                        errorMessage={cost?.formErrors?.amount}
                      />
                    </td>
                    <td>
                      <CustomAmountInput
                        value={cost?.amount_first_month}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              "amount_first_month",
                              e?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   "amount_first_month",
                          //   e?.value,
                          //   index
                          // )
                        }
                        name="amount_first_month"
                        ignoreLabel
                        disabled={cost?.status === "save" ? true : false}
                        errorMessage={cost?.formErrors?.amount_first_month}

                      />
                    </td>
                    <td>
                      <CustomAmountInput
                        value={cost?.amount_last_month}
                        col={12}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              "amount_last_month",
                              e.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   "amount_last_month",
                          //   e.value,
                          //   index
                          // )
                        }
                        name="amount_last_month"
                        ignoreLabel
                        disabled={cost?.status === "save" ? true : false}
                        errorMessage={cost?.formErrors?.amount_last_month}

                      />
                    </td>
                    <td>
                      <CustomDropDown
                        value={cost?.applicable_tenant_rent}
                        onChange={
                          (e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allExtraCost,
                              setAllExtraCost
                            )
                          // handleObjChange(
                          //   allExtraCost,
                          //   setAllExtraCost,
                          //   errors,
                          //   setErrors,
                          //   ignoreInputs,
                          //   e?.target?.name,
                          //   e?.target?.value,
                          //   index
                          // )
                        }
                        name="applicable_tenant_rent"
                        col={12}
                        options={YesNoOptions}
                        disabled={cost?.status === "save" ? true : false}
                        ignoreLabel
                        errorMessage={cost?.formErrors?.applicable_tenant_rent}

                      />
                    </td>
                    {cost?.status === "save" ? (
                      <td>
                        <CustomOverlay ref={openAction}>
                          <ul className="no-style p-0">
                            {/* {type == "Edit" && ( */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() =>
                                handlEdit(allExtraCost, setAllExtraCost, index)
                              }
                            >
                              <i className="pi pi-file-edit text-dark"></i>
                              Edit
                            </li>
                            {/* )} */}
                            <li
                              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                              onClick={() => {
                                setIdToDelete({ id: cost?.id, index: index });
                                setDeleteModal(true);
                              }}
                            >
                              <i className="pi pi-trash cursor-pointer text-dark"></i>
                              Delete
                            </li>
                          </ul>
                        </CustomOverlay>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex gap-3">
                          <a
                            className="cursor-pointer"
                            onClick={
                              () =>
                                handleSaveNew(
                                  index,
                                  allExtraCost,
                                  setAllExtraCost,
                                  ignoreInputs
                                )
                              // handleSave(
                              //   allExtraCost,
                              //   setAllExtraCost,
                              //   errors,
                              //   setErrors,
                              //   ignoreInputs,
                              //   index
                              // )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                                fill="#787878"
                              ></path>
                            </svg>
                          </a>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              if (cost.id) {
                                getCostInfo(cost?.id, index);
                              } else {
                                handleDeleteNew(
                                  allExtraCost,
                                  setAllExtraCost,
                                  index
                                );
                                // handleDelete(
                                //   allExtraCost,
                                //   setAllExtraCost,
                                //   errors,
                                //   setErrors,
                                //   index
                                // );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1.5em"
                              height="1.5em"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#787878"
                                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>

                  {/* {(errors[index]?.type ||
                    errors[index]?.payment_type ||
                    errors[index]?.amount ||
                    errors[index]?.amount_first_month ||
                    errors[index]?.amount_last_month ||
                    errors[index]?.applicable_tenant_rent) && (
                    <tr>
                      <td>
                        <small className="p-error">{errors[index]?.type}</small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.payment_type}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.amount}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.amount_first_month}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.amount_last_month}
                        </small>
                      </td>
                      <td>
                        <small className="p-error">
                          {errors[index]?.applicable_tenant_rent}
                        </small>
                      </td>
                    </tr>
                  )} */}
                  <CustomModal
                    isOpen={deleteModal}
                    setIsOpen={setDeleteModal}
                    heading={"Delete"}
                    body={modalContent}
                    submitLabel={"Confirm"}
                    onSubmit={handleDeleteExtraCost}
                    loading={loading}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <br />
      </CustomForm>
    </>
  );
};

export default AddExtraCost;
