import React, { useEffect, useState } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import { CustomForm } from "../../../../shared/AllInputs";
import {
  CustomViewInput,
} from "../../../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditIcon from "../../../../assets/images/icons/editicon.png";
import {
  deleteOfficeBankAccountAction,
  getOfficeInfoAction,
  updateOfficeBankAccountAction,
} from "../../../../store/actions/officeActions";
import InviteEmployees from "../../../../shared/Components/InviteEmployees";
import CustomBankAccountTable from "../../../Tables/CustomBankAccountTable";
import { setUserProfile } from "../../../../store/slices/userSlice";
export default function ViewOffice() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [allBankAccounts, setAllBankAccounts] = useState([]);

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.company) {
      getOfficeInfo(profile?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() => navigate(`/administrator/office/edit/${params?.id}`)}
        ></img>
      </div>
    );
  };

  const getOfficeInfo = (company) => {
    dispatch(
      getOfficeInfoAction(company, params?.id, dispatch, (res) => {
        let employees = res?.employees?.map((emp) => {
          return {
            name: emp?.employee?.name,
            role: emp?.employee?.role,
            position: emp?.position,
          };
        });
        let info = {
          officeId: res?.office_id,
          officeName: res?.name,
          officeNickName: res?.nick_name,
          officeEmail: res?.office_email,
          phoneNumber: res?.phone_number,
          unit: res?.office_unit?.name,
          officePurpose: res?.purpose,
          officeRepresentative: res?.office_rep?.name,

          address: res?.address,
          street: res?.street,
          zipCode: res?.zipcode,
          city: res?.city,
          cityDivision: res?.city_division,
          country: res?.country,

          costCenter: res?.cost_center,
          banks: res?.bank_accounts,
          employees: employees,
          invitedEmployees: res?.invite_employee,
        };
        setData(info);
        setAllBankAccounts(res?.bank_accounts);
      })
    );
  };

  const actions = () => {
    return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  };

  const handleBankAccountUpdate = (id, data, onRes) => {
    dispatch(
      updateOfficeBankAccountAction(id, data, dispatch, (res) => {
        onRes(res);
      })
    );
  };

  const handleBankAccountDelete = (id, onRes) => {
    dispatch(
      deleteOfficeBankAccountAction(id, dispatch, (res) => {
        onRes(res);
      })
    );
  };

  return (
    <Page title="View Details" description={`Let’s view your Office Details`}>
      <CustomCard>
        <CustomForm title="General" header={header()}>
          <CustomViewInput name="officeId" data={data} label={"Office ID"} />
          <CustomViewInput name="officeName" data={data} />
          <CustomViewInput name="officeNickName" data={data} />
          <CustomViewInput name="officeEmail" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
          <CustomViewInput name="unit" data={data} />
          <CustomViewInput name="officePurpose" data={data} />
          <CustomViewInput name="officeRepresentative" data={data} />
        </CustomForm>
        <CustomForm title="Address">
          <CustomViewInput name="address" data={data} />
          <CustomViewInput name="street" data={data} />
          <CustomViewInput name="zipCode" data={data} />
          <CustomViewInput name="city" data={data} />
          <CustomViewInput name="cityDivision" data={data} />
          <CustomViewInput name="country" data={data} />
        </CustomForm>
        <CustomForm title="Financial Details">
          <CustomViewInput name="costCenter" data={data} />
        </CustomForm>
        <CustomBankAccountTable
          title={"Bank Accounts"}
          allBankAccounts={allBankAccounts}
          setAllBankAccounts={setAllBankAccounts}
          onUpdate={handleBankAccountUpdate}
          onDelete={handleBankAccountDelete}
        />
        <CustomForm title="Existing Office Employee">
          <DataTable value={data?.employees} tableStyle={{ minWidth: "98rem" }}>
            <Column
              headerClassName="custom-header"
              field="role"
              header="Select Role"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="position"
              header="Select Position"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="name"
              header="Select Employee"
            ></Column>
            <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column>
          </DataTable>
        </CustomForm>
        <InviteEmployees
          type={"ViewProject"}
          title="Invite New Office Employees"
          allInvites={data?.invitedEmployees}
          disabled
        />
      </CustomCard>
    </Page>
  );
}
