import React from "react";
import { Dialog } from "primereact/dialog";
import CustomButton, { CustomButtonOutlined } from "../CustomButton";
const DetailsPreviweModal = ({ visible, setVisible, info }) => {
  const footerContent = (
    <div className="modal-footer border-0 gap-4 flex justify-content-center pb-4">
      <CustomButtonOutlined
        onClick={() => setVisible(false)}
        type="button"
        label="Back"
        className="btn m-0 fs-6 p-2 px-5 primary-button-outlined bg-transparent "
      />
      <CustomButton
        type="button"
        label="Send"
        className="btn primary-button fs-6 p-2 text-white px-5"
      />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      className="invitation-modal"
      header="Preview"
      footer={footerContent}
      onHide={() => setVisible(false)}
    >
      <div className="grid mb-3">
        <div className="md:col-6">
          <small className="preview-heading fw-semibold">Office Name</small>
        </div>
        <div className="md:col-6">
          <small className="preview-data">{info?.officeName}</small>
        </div>
      </div>
      <div className="grid mb-3">
        <div className="md:col-6">
          <small className="preview-heading fw-semibold">Office Purpose</small>
        </div>
        <div className="md:col-6">
          <small className="preview-data">{info?.purpose}</small>
        </div>
      </div>
      <div className="grid mb-3">
        <div className="md:col-6">
          <small className="preview-heading fw-semibold">Street</small>
        </div>
        <div className="md:col-6">
          <small className="preview-data">{info?.street}</small>
        </div>
      </div>
      <div className="grid mb-3">
        <div className="md:col-6">
          <small className="preview-heading fw-semibold">City</small>
        </div>
        <div className="md:col-6">
          <small className="preview-data">{info?.city}</small>
        </div>
      </div>
      <div className="grid mb-3">
        <div className="md:col-6">
          <small className="preview-heading fw-semibold">ZIP-code</small>
        </div>
        <div className="md:col-6">
          <small className="preview-data">{info?.zipCode}</small>
        </div>
      </div>
    </Dialog>
  );
};

export default DetailsPreviweModal;
