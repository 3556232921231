import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHandymanAction, updateHandymanAction } from "../../../../../store/actions/HandymanActions";
import { setUserProfile } from "../../../../../store/slices/userSlice";
import { getUserInfo } from "../../../../../services/auth";
import { useNavigate, useParams } from "react-router-dom";
import formValidation from "../../../../../utils/validations";
import { showFormErrors } from "../../../../../utils/commonFunctions";

const ViewHandymanContainer = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const [data, setData] = useState({
        name: "",
        address: "",
        feedback: false,
    })
    const navigate = useNavigate()
    const [selectedAddress, setSelectedAddress] = useState()
    const [damageHistory, setDamageHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [servicesList, setServicesList] = useState([]);
    const { profile } = useSelector((state) => state.user);
    const { handyman } = useSelector((state) => state?.handyman);
    const { updateHandyman } = useSelector((state) => state?.handyman);

    const damageColumns = [
        {
            name: "Property ID",
            accessor: "",
        },
        {
            name: "Landlord Name",
            accessor: "",
        },
        {
            name: "Damage Cause",
            accessor: "",
        },
        {
            name: "Reported Date",
            accessor: "",
        },
        {
            name: "Status",
            accessor: "",
        },
    ]

    useEffect(() => {
        let info = ""
        if (!profile.company) {
            info = getUserInfo()
            dispatch(setUserProfile(info))
        }
    }, [profile])

    useEffect(() => {
        if (params?.id && profile?.company) {
            handleGetHandyman(profile?.company, params?.id);
        }
    }, [params?.id, profile]);

    useEffect(() => {
        if (params?.id && profile?.company && updateHandyman) {
            handleGetHandyman(profile?.company, params?.id);
        }
    }, [params?.id, profile, updateHandyman]);

    const handleGetHandyman = (company, id) => {
        dispatch(
            getHandymanAction(company, id, dispatch, (res) => {
                setData({
                    name: res?.name,
                    address: res?.address,
                    feedback: res?.feedback
                })
                setServicesList(res?.handyman_services)
            }
            )
        );
    }

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "address") {
            setSelectedAddress("")
        }
    }

    useMemo(() => {
        if (selectedAddress) {
            const formErrors = formValidation("address", selectedAddress?.address, data);
            setData((prev) => ({ ...prev, ["address"]: selectedAddress?.address, formErrors }));
        }
    }, [selectedAddress])

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                updateHandymanAction(
                    profile?.company,
                    params?.id,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        navigate(`/real-estate/maintenance/handyman/view/${params?.id}`)
                    }
                )
            )
        }
    }

    return {
        data,
        servicesList,
        loading,
        handleChange,
        onSubmit,
        damageColumns
    }
}

export default ViewHandymanContainer