import React, { useEffect, useRef } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import { TabPanel, TabView } from "primereact/tabview";
import ViewBuildingStructure from "../../../CommonViewComponents/BuildingStructure";
import ViewBuildingUnit from "../../../CommonViewComponents/BuildingUnit";
import ViewLandlordInfo from "../../../CommonViewComponents/LandlordInfo";
import ViewContractualInfo from "../../../CommonViewComponents/ContractualInfo";
import ViewRentAndCost from "../../../CommonViewComponents/RentAndCost";
import ViewAnnualStatement from "../../../CommonViewComponents/AnnualStatement";
import ViewPropertyComponent from "../../../CommonViewComponents/ViewPropertyComponent";
import AddPropertyForm from "../../../CommonComponents/AddPropertyForm";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../shared/CustomButton";
// import { Messages } from "primereact/messages";
import ViewEditPropertyContainer from "./ViewPropertyContainer";
import BuildingStucture from "../../../CommonComponents/BuildingStucture";
import BuildingUnit from "../../../CommonComponents/BuildingUnit";
import AddRooms from "../../../../shared/Components/AddRooms";
import AddEnergyMeters from "../../../../shared/Components/AddEnergyMeters";
import LandlordInfo from "../../../CommonComponents/LandlordInfo";
import ContractualInfoForm from "../../../CommonComponents/ContractualInfoForm";
import RentAndCost from "../../../CommonComponents/RentAndCost";
import CustomModal from "../../../../shared/Modals/CustomModal";
import { Toast } from "primereact/toast";
import BankingDetailForm from "../../../CommonComponents/BankingDetailForm";
import FurnitureForm from "../../../CommonComponents/FurnitureForm";
import AddContacts from "../../../../shared/Components/AddContacts";
import ViewBankDetails from "../../../CommonViewComponents/ViewBankDetails";
import ViewFurniture from "../../../CommonViewComponents/ViewFurniture";
import CustomLandlordContactTable from "../../../Tables/CustomLandlordContactTable";

const ViewPropertyForm = () => {
  const msgs = useRef(null);
  const {
    action,
    route,
    activeIndex,
    handleTabChange,
    onSubmit,
    loading,
    msgToShow,
    handleCancel,
    setTabToActive,

    //Property
    // propertyId,
    PropertyData,
    propertyChange,
    propertyIdOptions,
    setSelectedAddress,
    propertyIdChange,

    //Building Structure
    buildingStuctureData,
    BuildingStuctureHandleChange,
    buildingOptions,
    fileKey,
    sturcureDocuments,
    handleStructureFileChange,
    handleStructureFileDelete,

    //Building Unit
    buildingUnitData,
    BuildingUnitChange,

    allRooms,
    setAllRooms,
    // roomErrors,
    // setRoomErrors,

    allEnergyMeters,
    setAllEnergyMeters,
    // meterErrors,
    // setMeterErrors,

    //landlord
    landlordOptions,
    LandlordChange,
    landLordData,
    // disableLandloard,
    setLandlordAddress,
    landlordIdChange,

    //bank accounts
    allBankAccounts,
    setAllBankAccounts,
    // bankErrors,
    // setBankErrors,

    //contacts
    allContacts,
    setAllContacts,
    // contactErrors,
    // setContactErrors,

    //furniture
    allFurniture,
    setAllFurniture,
    // furnitureErrors,
    // setFurnitureErrors,

    //contractual
    ContractOptions,
    contractualData,
    ContractualChange,
    allMaturities,
    setAllMaturities,
    // maturityErrors,
    // setMaturityErrors,
    // disableContract,
    contractIdChange,
    isContactExist,

    //rent and cost
    rentAndCostData,
    RentAndCostChange,
    // RentAndCostSubmit,

    //extra cost
    allExtraCost,
    setAllExtraCost,
    // costErrors,
    // setCostErrors,

    isOpen,
    setIsOpen,
    modalContent,
  } = ViewEditPropertyContainer();

  useEffect(() => {
    if (msgToShow?.message) {
      msgs?.current?.show({
        severity: msgToShow?.type,
        detail: msgToShow?.message,
      });
    }
  }, [msgToShow]);

  return (
    <Page
      title="View Details"
      description={`Let’s View your Property Details`}
      navigateLink={"/real-estate/property/list"}
    >
      <CustomCard>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />

        {action === "edit" && route === "property" ? (
          <AddPropertyForm
            type={"Edit"}
            data={PropertyData}
            handleChange={propertyChange}
            // propertyId={propertyId}
            propertyIdOptions={propertyIdOptions}
            activeIndex={activeIndex}
            setSelectedAddress={setSelectedAddress}
            propertyIdChange={propertyIdChange}
          />
        ) : (
          <ViewPropertyComponent data={PropertyData} />
        )}
        <TabView
          className="tabs-design"
          activeIndex={activeIndex}
          onTabChange={(e) => {
            // handleTabChange(e?.index);
            // onSubmit(route);
            setTabToActive(e?.index);
            action === "edit" ? setIsOpen(true) : handleTabChange(e?.index);
          }}
        >
          <TabPanel header="Building Structure">
            {action === "edit" && route === "building-structure" ? (
              <BuildingStucture
                data={buildingStuctureData}
                handleChange={BuildingStuctureHandleChange}
                buildingOptions={buildingOptions}
                fileKey={fileKey}
                sturcureDocuments={sturcureDocuments}
                fileChange={handleStructureFileChange}
                fileDelete={handleStructureFileDelete}
              />
            ) : (
              <ViewBuildingStructure data={buildingStuctureData} />
            )}
          </TabPanel>

          <TabPanel header="Building Unit">
            {action === "edit" && route === "building-unit" ? (
              <>
                <BuildingUnit
                  // type={"Edit"}
                  data={buildingUnitData}
                  handleChange={BuildingUnitChange}
                  //   msgToShow={msgToShow}
                />
                <AddRooms
                  // type={"Edit"}
                  maxSize={buildingUnitData?.size}
                  allRooms={allRooms}
                  setAllRooms={setAllRooms}
                  // errors={roomErrors}
                  // setErrors={setRoomErrors}
                />
                <AddEnergyMeters
                  // type={"Edit"}
                  unitId={buildingUnitData?.id}
                  // energyMeters={buildingUnitData?.energy_meters}
                  allEnergyMeters={allEnergyMeters}
                  setAllEnergyMeters={setAllEnergyMeters}
                  // errors={meterErrors}
                  // setErrors={setMeterErrors}
                />
              </>
            ) : (
              <ViewBuildingUnit
                data={buildingUnitData}
                allRooms={allRooms}
                setAllRooms={setAllRooms}
                allEnergyMeters={allEnergyMeters}
                setAllEnergyMeters={setAllEnergyMeters}
              />
            )}
          </TabPanel>

          <TabPanel header="Landlord Info">
            {action === "edit" && route === "landlord-info" ? (
              <>
                {" "}
                <LandlordInfo
                  // type={"Edit"}
                  // isDisabled={disableLandloard}
                  data={landLordData}
                  handleChange={LandlordChange}
                  landlordOptions={landlordOptions}
                  landlordIdChange={landlordIdChange}
                  setLandlordAddress={setLandlordAddress}
                  //bank
                  // allBankAccounts={allBankAccounts}
                  // setAllBankAccounts={setAllBankAccounts}
                  // bankErrors={bankErrors}
                  // setBankErrors={setBankErrors}

                  //contacts
                  // allContacts={allContacts}
                  // setAllContacts={setAllContacts}
                  // contactErrors={contactErrors}
                  // setContactErrors={setContactErrors}

                  //furniture
                  // allFurniture={allFurniture}
                  // setAllFurniture={setAllFurniture}
                  // furnitureErrors={furnitureErrors}
                  // setFurnitureErrors={setFurnitureErrors}
                />
                <BankingDetailForm
                  title="Banking Details"
                  data={landLordData}
                  handleChange={LandlordChange}
                  allBankAccounts={allBankAccounts}
                  setAllBankAccounts={setAllBankAccounts}
                />
                <FurnitureForm
                  title="Landlord Furniture"
                  data={landLordData}
                  handleChange={LandlordChange}
                  allFurniture={allFurniture}
                  setAllFurniture={setAllFurniture}
                />
                <AddContacts
                  title="Add New Contact"
                  allContacts={allContacts}
                  setAllContacts={setAllContacts}
                  // errors={contactErrors}
                  // setErrors={setContactErrors}
                />
              </>
            ) : (
              <>
                <ViewLandlordInfo
                  type="property"
                  data={landLordData}
                  // allFurniture={allFurniture}
                  // setAllFurniture={setAllFurniture}
                  // allBankAccounts={allBankAccounts}
                  // setAllBankAccounts={setAllBankAccounts}
                  // allContacts={allContacts}
                  // setAllContacts={setAllContacts}
                />
                <ViewBankDetails
                  type="property"
                  data={landLordData}
                  allBankAccounts={allBankAccounts}
                  setAllBankAccounts={setAllBankAccounts}
                />
                <ViewFurniture
                  type="property"
                  data={landLordData}
                  allFurniture={allFurniture}
                  setAllFurniture={setAllFurniture}
                />
                <CustomLandlordContactTable
                  action="property"
                  id={landLordData?.id}
                  title={"Contacts"}
                  allContacts={allContacts}
                  setAllContacts={setAllContacts}
                />
              </>
            )}
          </TabPanel>

          <TabPanel header="Contractual Info">
            {action === "edit" && route === "contractual-info" ? (
              <ContractualInfoForm
                // type={"Edit"}
                // isDisabled={disableContract}
                ContractOptions={ContractOptions}
                data={contractualData}
                handleChange={ContractualChange}
                allMaturities={allMaturities}
                setAllMaturities={setAllMaturities}
                // maturityErrors={maturityErrors}
                // setMaturityErrors={setMaturityErrors}
                contractIdChange={contractIdChange}
                isContactExist={isContactExist}
              />
            ) : (
              <ViewContractualInfo
                type="property"
                data={contractualData}
                // propertyId={propertyId}
                allMaturities={allMaturities}
                setAllMaturities={setAllMaturities}
              />
            )}
          </TabPanel>

          <TabPanel header="Rent & Cost">
            {action === "edit" && route === "rent-and-cost" ? (
              <RentAndCost
                // type={"Edit"}
                data={rentAndCostData}
                handleChange={RentAndCostChange}
                allExtraCost={allExtraCost}
                setAllExtraCost={setAllExtraCost}
                // costErrors={costErrors}
                // setCostErrors={setCostErrors}
              />
            ) : (
              <ViewRentAndCost
                type="property"
                isEditable={true}
                contractId={contractualData?.id}
                data={rentAndCostData}
                // propertyId={propertyId}
                allExtraCost={allExtraCost}
                setAllExtraCost={setAllExtraCost}
              />
            )}
          </TabPanel>
          <TabPanel header="Annual Statement">
            <ViewAnnualStatement />
          </TabPanel>
        </TabView>
      </CustomCard>
      {action === "edit" && (
        <Buttonlayout>
          <CustomButton
            onClick={() => onSubmit(route)}
            label="Save Changes"
            loading={loading}
          />
          <CustomButtonOutlined label="Cancel" onClick={() => handleCancel()} />
        </Buttonlayout>
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={"Alert!"}
        body={modalContent}
        submitLabel={"Save Changes"}
        onSubmit={() => onSubmit(route)}
        cancelLabel={"Discard"}
        onCancel={() => handleCancel()}
        loading={loading}
      />
    </Page>
  );
};

export default React.memo(ViewPropertyForm);
