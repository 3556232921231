export const ElectricityuPurposeOptions = [
    { name: "Normal", value: "Normal" },
    { name: "Heating Only", value: "Heating Only" },
    { name: "Later", value: "Later" }
]

export const GasPurposeOptions = [
    { name: "Cooking Only", value: "Cooking Only" },
    { name: "Heating Only", value: "Heating Only" },
    { name: "Combined", value: "Combined" },
    { name: "Later", value: "Later" }
]

export const MeterTypeOpt = [
    { name: "Electricity", value: "Electricity" },
    { name: "Gas", value: "Gas" }
];

export const BankPurposeOpt = [
    { name: "All Combined", value: "All Combined" },
    { name: "Rent", value: "Rent" },
    { name: "Deposit", value: "Deposit" },
    { name: "Annual Statement", value: "Annual Statement" },
]

export const MeterLocationOptions = [
    { name: "In unit", value: "In unit" },
    { name: "In Floor", value: "In Floor" },
    { name: "Basement", value: "Basement" },
];

export const AccessOptions = [
    { name: "Locked", value: "Locked" },
    { name: "Accessible", value: "Accessible" },
    { name: "Later", value: "Later" }
];

export const HeatingTechOptions = [
    { name: "Gas", value: "Gas" },
    { name: "Electricity", value: "Electricity" },
    { name: "Later", value: "Later" }
];

export const LocationOptions = [
    { name: "In unit", value: "In unit" },
    { name: "In Floor", value: "In Floor" },
    { name: "Basement", value: "Basement" },
    { name: "Later", value: "Later" }
]

export const UnitLocationOptions = [
    { name: "Left", value: "Left" },
    { name: "Middle Left", value: "Middle Left" },
    { name: "Middle", value: "Middle" },
    { name: "Middle Right", value: "Middle Right" },
    { name: "Right", value: "Right" },
    { name: "Not Practical", value: "Not Practical" },
];

export const UnitTypeOptions = [
    { name: "Office", value: "Office" },
    { name: "Retail Space", value: "Retail Space" },
    { name: "Residential", value: "Residential" },
];

export const OutStatusOptions = [
    { name: "Partially", value: "Partially" },
    { name: "Pending", value: "Pending" },
    { name: "Fully", value: "Fully" },
];

export const WaterHeatingOptions = [
    { name: "Centralized", value: "Centralized" },
    { name: "Decentralized", value: "Decentralized" },
];

export const ContactOptions = [
    { name: "Employee", value: "Employee" },
    { name: "Company", value: "Company" },
]

export const FurnitureType = [
    { name: "Kitchen", value: "Kitchen" },
    { name: "Bath", value: "Bath" },
    { name: "Other", value: "Other" },
]

export const FurnitureKitchenOpt = [
    { name: "Nothing", value: "Nothing" },
    { name: "Stove", value: "Stove" },
    { name: "Sink", value: "Sink" },
    { name: "EBK", value: "EBK" },
]

export const damageCauseopt = [
    { name: "Tenant", value: "Tenant" },
    { name: "Landlord", value: "Landlord" },
]

export const FurnitureBathopt = [
    { name: "Shower", value: "Shower" },
    { name: "Bathtub", value: "Bathtub" },
]

export const FurnitureOtheropt = [
    {name: "Not supported in this version", value: "Not Supported"}
]

export const ContactRoleOptions = [
    { name: "Technical", value: "Technical" },
    { name: "Accident", value: "Accident" },
    { name: "Administrator", value: "Administrator" },
]

export const StairAccessOptions = [
    { name: "Direct Access", value: "direct" },
    { name: "Access to Common Floor", value: "common" },
    { name: "Combined Access", value: "combined" },
];

export const BuildingStatus = [
    { name: "Vacant", value: "Vacant" },
    { name: "Expiring", value: "Expiring" },
    { name: "Occupied", value: "Occupied" },
    { name: "Moving Out", value: "Moving Out" },
]

export const BuildingTypeOptions = [
    { name: "Front House", value: "Front House" },
    { name: "Back House", value: "Back House" },
    { name: "Side Wing", value: "Side Wing" },
    { name: "Out Building", value: "Out Building" },
    { name: "Corner Building", value: "Corner Building" },
    { name: "Multi-Family House", value: "Multi-Family House" },
    { name: "Detached House", value: "Detached House" },
    { name: "Commercial Building", value: "Commercial Building" },
    { name: "Industrial Building", value: "Industrial Building" },
];

export const YesNoOptions = [
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
]

export const LandoardTypes = [
    { name: "Real Estate Management", value: "Real Estate Management" },
    { name: "Real Estate Owner", value: "Real Estate Owner" }
]

export const MaturityType = [
    { name: 'None', value: 'None' },
    { name: 'Automatic Renewal', value: 'Automatic Renewal' },
    { name: 'Optional Renewal', value: 'Optional Renewal' }
]

export const DepositType = [
    { name: 'None', value: 'None' },
    { name: 'Bank Transfer', value: 'Bank Transfer' },
    { name: 'Bank Deposit', value: 'Bank Deposit' },
    { name: 'Later', value: 'Later' }
]

export const RentType = [
    { name: 'None', value: 'None' },
    { name: 'Percentage', value: 'Percentage' },
    { name: 'Amount', value: 'Amount' }
]

export const IntervalType = [
    { name: 'Month', value: 'Month' },
    { name: 'Year', value: 'Year' }
]

export const PropertyRent = [
    { name: 'Size', value: 'Size' },
    { name: 'Room', value: 'Room' },
    { name: 'Manually', value: 'Manually' }
]

export const ExtraCostType = [
    { name: 'Maintenance Costs', value: 'Maintenance Costs' },
    { name: 'Heating Costs', value: 'Heating Costs' },
    { name: 'Electricity Costs', value: 'Electricity Costs' },
    { name: 'Internet Costs', value: 'Internet Costs' },
    { name: 'Furniture Costs', value: 'Furniture Costs' },
    { name: 'Security Costs', value: 'Security Costs' },
    { name: 'Cleaning Costs', value: 'Cleaning Costs' },
    { name: 'Other Costs', value: 'Other Costs' },
]

export const ExtraCostPayemntType = [
    { name: 'Lump sum', value: 'Lump sum' },
    { name: 'Prepayment', value: 'Prepayment' }
]

export const PositionOptions = [
    { name: "Manager", value: "manager" },
    { name: "Executive", value: "executive" },
    { name: "Team Lead", value: "teamLead" },
];

export const ContractualRoomOpt = [
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7+", value: "7+" },
    { name: "Not Given", value: "Not Given" },
    { name: "Later", value: "Later" },
];

export const damageReportType = [
    { name: 'New', value: 'New' },
    { name: 'Back Office', value: 'Back Office' },
    { name: 'Social Worker', value: 'Social Worker' },
    { name: 'Pending', value: 'Pending' }
]

export const maintenanceReqType = [
    { name: 'Initiated', value: 'Initiated' },
    { name: 'Back Office', value: 'Back Office' },
    { name: 'Social Worker', value: 'Social Worker' },
    { name: 'Handyman', value: 'Handyman' },
    { name: 'Follow-up', value: 'Follow-up' },
    { name: 'Fixed', value: 'Fixed' },
]