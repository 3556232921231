import React from "react";
import { Panel } from "primereact/panel";
export default function RolesPanel({
  action,
  parentKey,
  parent,
  roles,
  setRoles,
}) {
  const handleParentChange = (value, parent) => {
    let updatedChildren = roles[parentKey][parent].map((child) => ({
      ...child,
      check: value,
    }));

    setRoles({
      ...roles,
      [parentKey]: {
        ...roles[parentKey],
        [parent]: updatedChildren,
      },
    });
  };

  const handleChildChange = (value, index, parent) => {
    let child = roles[parentKey][parent].map((item, i) => {
      if (index === i) {
        return { ...item, check: value };
      } else {
        return item;
      }
    });
    setRoles({
      ...roles,
      [parentKey]: {
        ...roles[parentKey],
        [parent]: child,
      },
    });
  };

  const isChecked = (parent) => {
    let isAllChecked = roles[parentKey][parent].every((item) => {
      return item?.check === true;
    });

    return isAllChecked;
  };

  return (
    <div className="accordion-item">
      <Panel
        header={
          <>
            <input
            //   disabled={action === "view"}
              className="form-check-input mt-0 me-2"
              type="checkbox"
              name={parent}
              onChange={() => {}}
              checked={isChecked(parent)}
              onClick={(e) => handleParentChange(e?.target?.checked, parent)}
            />
            {parent}
          </>
        }
        toggleable
        collapseIcon={
          <i className="pi pi-chevron-up" style={{ fontSize: "1rem" }}></i>
        }
        expandIcon={
          <i className="pi pi-chevron-down" style={{ fontSize: "1rem" }}></i>
        }
      >
        <div className="accordion-body">
          <div className="table-responsive mt-3">
            {roles[parentKey][parent]?.map((child, index) => {
              return (
                <div className="mb-3" key={index}>
                  <label className="form-check-label">
                    <input
                    //   disabled={action === "view"}
                      className="form-check-input mt-0 me-2"
                      type="checkbox"
                      value={child?.value}
                      onChange={() => {}}
                      checked={child.check ? true : false}
                      onClick={(e) => {
                        handleChildChange(e?.target?.checked, index, parent);
                      }}
                    />
                    {child?.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </Panel>
    </div>
  );
}

// export default RolesPanel;
